import React, { Component, Fragment } from "react";
import DraggableModal from "./DraggableModal";
import Datagrid from "./Datagrid";
import { Tree, Row, Col, Icon, Empty } from 'antd';
import './DatagridModal.css';
const { TreeNode } = Tree;

class DatagridModal extends Component {
  constructor(props) {
    super(props);
    this.state = { status: [] };
  }

  componentDidUpdate(prevProps) {
    if (this.props.dataSource !== prevProps.dataSource) {
      if (Object.keys(this.props.dataSource).length > 0) {
        this.setState({ status: Object.keys(this.props.dataSource).map(i => ({ status: "unchecked" })) })
      }
    }
  }

  renderTreeNodes = (dataSource, renderPermission, childrenCheckable) => (
    dataSource.map((item, index) => {
      if (item.children) {
        return (
          <TreeNode key={item.id} title={<Fragment>{item.name} {(typeof (renderPermission) == 'function') ? renderPermission(item) : ''}</Fragment>} >
            {this.renderTreeNodes(item.children, renderPermission, childrenCheckable)}
          </TreeNode>
        );
      }
      return <TreeNode checkable={childrenCheckable} key={item.id} title={<Fragment>{item.name} {(typeof (renderPermission) == 'function') ? renderPermission(item) : ''}</Fragment>} />
    })
  )

  render() {
    const { cancelText, onSave, onCancel, saveText, title, titlePrefix, visible, type, width, height, footer } = this.props

    let content = {}
    if (visible) {
      if (['apps', 'browsers'].includes(type)) {
        const { id, dataSource, originalDataSource, datagridOptions, filters, appsOptions: { onAppsChange, appsActive, controls, selectOptions }, loading, readOnly } = this.props
        let originalData = originalDataSource
        originalData  = originalData ? originalData : dataSource
        let itemSource = []
        if (selectOptions == 'selected') itemSource = itemSource.filter(n => datagridOptions.rowSelection.selectedRowKeys.includes(n.id))
        if (readOnly) itemSource = itemSource.filter(n => datagridOptions.rowSelection.selectedRowKeys.includes(n.id))

        if (typeof appsActive == "string") {
          Object.keys(dataSource).map(i =>
            dataSource[i].item.filter(i => (i.name.toLowerCase().includes(filters.toLowerCase()))).map(y => itemSource.push(y))
          )
        } else {
          if (dataSource != null && Object.keys(dataSource).length > 0 && typeof appsActive == "number") {
            itemSource = dataSource[Object.keys(dataSource)[appsActive]].item.filter(i => (i.name.toLowerCase().includes(filters.toLowerCase())))
          }
        }
        content = (
          <Row gutter={16} style={{ height: 490 }}>
            <Col span={14}>
              <Row className='ui-datagrid-modal' gutter={8}>
                {Object.keys(dataSource).map((category, i) => {
                  const appTotal = dataSource[category].item.filter(n => datagridOptions.rowSelection.selectedRowKeys.includes(n.id)).length
                  const appValue = originalData[category].item.length
                  return (
                    <Col key={i} span={4} className={`app-icon ${type} ${typeof appsActive == "number" && appsActive == i ? 'app-active' : ''}`} onClick={
                      () => {
                        if (this.state.status[i].status == "checked") {
                          onAppsChange("")
                          this.setState({ status: { ...this.state.status, [i]: { status: "unchecked" } } })
                        } else {
                          onAppsChange(i)
                          this.setState({ status: { ...this.state.status, [i]: { status: "checked" } } })
                        }
                      }
                    }>
                      <span className='app-badge'>
                        <span className='app-badge-total' style={{ background: appTotal == appValue ? '#008000' : appTotal > 0 ? '#ffa500' : undefined }}>{appTotal == appValue ? <Icon type="check" /> : appTotal}</span>
                        <span className='app-badge-value' style={{ background: appTotal == appValue ? '#015f01' : appTotal > 0 ? '#b57500' : undefined }}>{appValue}</span>
                      </span>
                      <i className={`app-icon-symbol fa ${dataSource[category].icon || 'fa-laptop'}`} />
                      {dataSource[category].name}
                    </Col>
                  )
                })}
              </Row>
            </Col>
            <Col span={10}>
              <Datagrid
                id={`datagridmodal_${id}`}
                {...datagridOptions}
                rowSelection={readOnly ? undefined : datagridOptions.rowSelection}
                controls={controls}
                dataSource={itemSource}
                loading={loading}
              />
            </Col>
          </Row>
        )
      }

      if (type == 'appsControl') {
        const { id, dataSource, dataSourceOriginal, datagridOptions, filters, appsOptions: { onAppsChange, appsActive, controls, selectPermition }, height, loading } = this.props
        let itemSource = [];
        if (typeof appsActive == "string") {
          Object.keys(dataSource).map(i =>
            dataSource[i].item.filter(i => (i.name.toLowerCase().includes(filters.toLowerCase()))).map(y => itemSource.push(y))
          )
        } else {
          if (dataSource != null && Object.keys(dataSource).length > 0 && typeof appsActive == "number") {
            itemSource = dataSource[Object.keys(dataSource)[appsActive]].item.filter(i => (i.name.toLowerCase().includes(filters.toLowerCase())))
          }
        }

        content = (
          <Row gutter={16} style={{ height }}>
            <Col span={14}>
              <Row className='ui-datagrid-modal' gutter={8}>
                {Object.keys(dataSource).map((category, i) => {
                  let itemCategory = []
                  // recupera os itens da categoria específica
                  Object.keys(dataSourceOriginal).filter(i => itemCategory = (dataSourceOriginal[category].item))
                  // formata igual selectPermition 
                  itemCategory = itemCategory.map(i => ({ id: i.id }))
                  // recupera apenas os IDS das categorias especificas para verificação no selectPermition
                  let IdCategories = itemCategory.map(i => i.id)
                  // recupera os controls dos selectPermitions das categorias especificas
                  let controlCategory = selectPermition.filter(i => IdCategories.includes(i.id))
                  // contagem por controle
                  let allowTotal = controlCategory.filter(i => i.action == "Allow").length
                  let denyTotal = controlCategory.filter(i => i.action == "Deny").length
                  let controlTotal = controlCategory.filter(i => i).length
                  return (
                    <Col key={i} span={4} className={`app-icon appsControl ${typeof appsActive == "number" && appsActive == i ? 'app-active' : ''}`}
                      onClick={() => {
                        if (this.state.status[i].status == "checked") {
                          onAppsChange("", category)
                          this.setState({ status: { ...this.state.status, [i]: { status: "unchecked" } } })
                        } else {
                          onAppsChange(i, category)
                          this.setState({ status: { ...this.state.status, [i]: { status: "checked" } } })
                        }

                      }}>
                      <span className='app-badge'>
                        <span className='app-badge-total' style={{ background: denyTotal == controlTotal ? undefined : denyTotal > 0 ? '#c93e3e' : undefined }}>{denyTotal == controlTotal ? denyTotal : allowTotal == controlTotal ? allowTotal : denyTotal}</span>
                        <span className='app-badge-value' style={{ background: allowTotal == controlTotal ? '#008000' : denyTotal == controlTotal ? "#db1103" : allowTotal > 0 ? '#06a106' : undefined }}>{allowTotal == controlTotal ? <Icon type="check" /> : denyTotal == controlTotal ? <Icon type="close" /> : denyTotal == controlTotal ? denyTotal : allowTotal}</span>
                      </span>
                      <i className={`app-icon-symbol fa ${dataSource[category].icon || 'fa-laptop'}`} />
                      {dataSource[category].name}
                    </Col>
                  )
                })}
              </Row>
            </Col>
            <Col span={10}>
              <Datagrid
                id={`datagridmodal_${id}`}
                {...datagridOptions}
                controls={controls}
                dataSource={itemSource}
                loading={loading}
              />
            </Col>
          </Row>
        )
      }

      if (type == 'tree') {
        const { dataSource, treeOptions: { options, controls, renderPermission, childrenCheckable }, height } = this.props;
        content = (
          <div className="ui-datagrid-modal">
            <div className="ui-datagrid-controls">
              {controls}
            </div>
            <div style={{ height, overflowY: 'auto', overflowX: 'hidden', border: '1px solid #ddd', borderRadius: 4 }}>
              {dataSource.length > 0 && <Tree checkable {...options}>{this.renderTreeNodes(dataSource, renderPermission, childrenCheckable)}</Tree>}
              {dataSource.length == 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </div>
          </div>
        )
      }
      if (type == 'datagrid') {
        const { id, dataSource, loading, datagridOptions } = this.props;
        content = (
          <div className='ui-datagrid-modal' style={{ height }}>
            <Datagrid
              id={`datagridmodal_${id}`}
              {...datagridOptions}
              dataSource={dataSource}
              loading={loading}
            />
          </div>
        )
      }
    }
    return (
      <DraggableModal
        footer={footer}
        visible={visible}
        title={< Fragment > {titlePrefix} {title}</Fragment>}
        onOk={onSave}
        onCancel={onCancel}
        okText={saveText}
        width={width}
        cancelText={cancelText} >
        {visible && content}
      </DraggableModal >
    )
  }
}

DatagridModal.defaultProps = {
  id: null,
  cancelText: "Cancel",
  dataSource: [],
  loading: false,
  type: 'datagrid',
  onSave: null,
  onCancel: null,
  saveText: "Save",
  title: "new registry",
  titlePrefix: "Add",
  visible: false,
  datagridOptions: {},
  treeOptions: {
    childrenCheckable: true
  },
  appsOptions: {},
  controls: {},
  width: 520,
  height: 505,
  footer: undefined,
};

export default DatagridModal;