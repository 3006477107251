import React, { Component, Fragment } from "react";
import Axios from "axios";
import AxiosFormData from "/helpers/AxiosFormData";
import { DraggableModal } from "/bbui/components/";
import { Form, Input, Select, Button, message, DatePicker } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import { ajaxUrl, datepicker_locale } from "/bbui/config/constants";
import { product } from "/config/constants";
import "../Objects.css"
const lang = window.localStorage.getItem('language');
var _ = require('lodash');

const package_version = 'app';

const convertDate = (date, format) => {
	let obj = date.trim().split(" ");

	if (format == "BR")
		var newdate = obj[0].split("-").reverse().join("/");
	else
		var newdate = obj[0].split("/").reverse().join("-");

	return `${newdate} ${obj[1]}`;
}

class SchedulesModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lockForm: false,
			message: null,
			itemMove: {
				"obj_date-date_ini": "",
				"obj_date-date_end": ""
			},
			schedules: props.schedules,
			editTitlePrefix: ''
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.id !== prevProps.id) {
			const { id } = this.props;
			if (id) {
				this.setState({ editTitlePrefix: this.props.intl.formatMessage({ id: "common.edit" }) });
				Axios.post(ajaxUrl[package_version].objects, AxiosFormData({ act: "getObjects", type: "date", id })).then((res) => {
					if (res.data) {
						let data = JSON.parse(res.data[0].data)
						this.setState({
							schedules: {
								id,
								name: data['obj_date-name'],
								description: data['obj_date-description'],
								dates: data['obj_date-dates'],
								gsmid: data['obj_date-gsmid']
							}
						})
					}
				})
			}
		}
		if (this.props.schedules !== prevProps.schedules) {
			this.setState({
				schedules: this.props.schedules,
			})
		}
	}

	handleSave = () => {
		this.setState({ lockForm: true });
		const { intl, form } = this.props;
		let fields = ["obj_date-name", "obj_date-desc"]
		let invalid = false;

		var list = document.getElementById("obj_date-list");
		if (list.options.length == 0)
			invalid = true

		form.validateFields(fields, (err, values) => {
			if (invalid === true) {
				this.setState({ lockForm: false });
				message.error(intl.formatMessage({ id: "objects.times.modal.form.invalid" }));
				list.classList.add("ui-has-error");
				return
			} else {
				if (!err) {
					this.saveObj(form, values);
				} else {
					this.setState({ lockForm: false });
				}
			}
		});
	}

	handleCancel = () => {
		const { onCancel } = this.props;
		this.props.form.resetFields();
		let field = document.getElementById("obj_date-list");
		if (field) field.classList.remove("ui-has-error");
		onCancel();
	}

	addValueList = (value) => {
		let { dates: list } = this.state.schedules;

		var object = list.some(p =>
			p["obj_date-date_ini"] == value["obj_date-date_ini"] &&
			p["obj_date-date_end"] == value["obj_date-date_end"]
		)

		if (object == false) {
			list.push(_.cloneDeep(value));
		}
		this.setState({
			schedules: {
				...this.state.schedules,
				dates: list
			}
		});

		var element = document.getElementById("obj_date-list");

		element.classList.remove("ui-has-error")
	}

	delValueList = (pos) => {
		let { dates: list } = this.state.schedules;
		pos.forEach(function (index) {
			delete list[index];
		})
		this.setState({
			schedules: {
				...this.state.schedules,
				dates: list
			}
		});
	}

	moveValueList = (value) => {
		const { validateFields, resetFields } = this.props.form;
		let fields = ["obj_range_datetime"]

		validateFields(fields, (errors, values) => {
			if (!errors) {
				resetFields(["obj_range_datetime"]);
				this.addValueList(value);
			}
		});
	}

	removeValueList = () => {
		let selectElement = document.getElementById('obj_date-list');
		let pos = Array.from(selectElement.selectedOptions).map(option => option.index)
		this.delValueList(pos);
	}

	renderList(row) {
		let value = row['obj_date-date_ini'] + " - " + row['obj_date-date_end'];
		return (<option key={value.toString()}>{value}</option>)
	}

	setDate = (value, dateString) => {
		let editObject = this.state.itemMove;
		editObject["obj_date-date_ini"] = dateString[0];
		editObject["obj_date-date_end"] = dateString[1];
		this.setState({ itemMove: editObject });
	}

	convertDate = (date, format) => {

		let obj = date.trim().split(" ");

		if (format == "BR")
			var newdate = obj[0].split("-").reverse().join("/");
		else
			var newdate = obj[0].split("/").reverse().join("-");

		return `${newdate} ${obj[1]}`;

	}

	saveObj = (form, values) => {
		const { intl } = this.props;
		const { schedules } = this.state;
		const itens = [];
		var element = document.getElementById("obj_date-list");

		for (var i = 0; i < element.options.length; i++) {
			let val = element.options[i].value.split("-");

			let data = {
				"obj_date-date_ini": convertDate(val[0]),
				"obj_date-date_end": convertDate(val[1])
			}
			itens.push(data);
		}

		const bodyData = new FormData();
		bodyData.set("act", "saveDate");
		bodyData.set("obj_date-name", values["obj_date-name"]);
		bodyData.set("obj_date-desc", values["obj_date-desc"]);
		bodyData.set("obj_date-list", JSON.stringify(itens));

		if (schedules.id !== '') {
			bodyData.set("obj_date-id", schedules.id);
		}

		Axios.post(ajaxUrl[package_version].objects, bodyData).then((res) => {
			this.setState({ lockForm: false });

			if (product == "GSM" && Array.isArray(res.data) && res.data.length == 2)
				if (res.data[1]['errorcode'] == '001') {
					form.resetFields();
					element.classList.remove("ui-has-error")
					this.setState({ schedules: {} })
					this.props.onCancel();
					return message.error(this.props.intl.formatMessage({ id: "common.dont.permission" }))
				}

			if (res.data === "Seu login não permite acesso a esta area." || res.data === "Your login does not allow access to this area.") {
				form.resetFields();
				element.classList.remove("ui-has-error")
				this.setState({ schedules: {} })
				this.props.onCancel();
				return message.error(this.props.intl.formatMessage({ id: "common.dont.permission.edit" }));
			}

			if (res.data[0].success == 1) {
				message.success(intl.formatMessage({ id: "objects.actions.create.success" }));
				form.resetFields();
				element.classList.remove("ui-has-error")
				this.setState({ schedules: {} })
				this.props.onOk();
			} else {
				res.data.forEach(erro => {
					form.setFields({
						[erro.field]: {
							value: values[erro.field],
							errors: [new Error(intl.formatMessage({ id: "common.invalid.value" }))],
						},
					});
					if (erro.field == "obj_date-list") {
						element.classList.add("ui-has-error")
					}

					if (res.data[0]['exists']) {
						form.setFields({
							'obj_date-name': {
								value: form.getFieldValue('obj_date-name'),
								errors: [new Error(this.props.intl.formatMessage({ id: "common.alreadyExists" }))]
							}
						})
					}
					message.error(intl.formatMessage({ id: "objects.actions.create.error" }));
				});
			}
		});
	}

	render() {
		let { intl, visible, titlePrefix } = this.props;
		const { schedules, lockForm, editTitlePrefix } = this.state;
		const { getFieldDecorator } = this.props.form;
		const { TextArea } = Input;
		const formLayout = { colon: false, style: { paddingBottom: 0 } };
		const formLayoutLast = { colon: false, style: { paddingBottom: 0, marginBottom: 0 } };
		const { RangePicker } = DatePicker;
		let dataSource = [];

		let { dates: rows = [] } = this.state.schedules;

		if (titlePrefix == '' && editTitlePrefix == '') {
			titlePrefix = intl.formatMessage({ id: "common.create" });
		}

		rows.forEach(function (key, idx) {
			dataSource[idx] = {
				"obj_date-date_end": convertDate(key["obj_date-date_end"], "BR"),
				"obj_date-date_ini": convertDate(key["obj_date-date_ini"], "BR"),
			};
		});

		let controleLoadingButton = lockForm
		let lockFormGsmUtm = false

		//Object GSM disabled
		if(this.props.schedules.gsmid || this.state.schedules.gsmid){
			lockFormGsmUtm = true;
			controleLoadingButton = true;
		}

		const footerActions = [
				<Button key="cancel" onClick={this.handleCancel}>
					<FormattedMessage id="common.cancel" />
				</Button>,
				<Button loading={controleLoadingButton} disabled={lockFormGsmUtm} key="save" type="primary" onClick={this.handleSave}>
					<FormattedMessage id="common.save" />
				</Button>
		]

		return <DraggableModal
			onOk={this.handleSave}
			onCancel={this.handleCancel}
			confirmLoading={lockFormGsmUtm}
			visible={visible}
			title={<Fragment>{titlePrefix || editTitlePrefix}<FormattedMessage id="objects.modal.title" values={{ object: "Schedules" }} /></Fragment>}
			okText={intl.formatMessage({ id: "common.save" })}
			cancelText={intl.formatMessage({ id: "common.cancel" })}
			footer={footerActions}>
			<Form layout="vertical">
				<Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.schedules.modal.form.name" })} >
					{getFieldDecorator('obj_date-name', {
						initialValue: schedules.name,
						rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.schedules.modal.form.name" }) }) }]
					})(
						<Input disabled={lockFormGsmUtm} />
					)}
				</Form.Item>

				<Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.schedules.modal.form.item" })} style={{ marginBottom: 8, paddingBottom: 0 }}>
					<div style={{ display: 'flex' }}>
						{getFieldDecorator('obj_range_datetime', {
							initialValue: [],
							rules: [
								{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.times.modal.form.item" }) }) },
								{
									validator: (rule, value, cb) => {
										if (typeof value[0] != "undefined" || typeof value[1] != "undefined") {
											if (value[0].format('YYYY-MM-DD HH:mm') == value[1].format('YYYY-MM-DD HH:mm')) {
												return cb(intl.formatMessage({ id: "objects.times.modal.form.error.equal.period" }));
											}
										}
										return cb()
									}
								}
							]
						})(
							<RangePicker disabled={lockFormGsmUtm} showTime={{ format: 'HH:mm' }}
								format="DD/MM/YYYY HH:mm"
								locale={datepicker_locale[lang]}
								placeholder={[intl.formatMessage({ id: "objects.times.modal.form.start.datetime" }), intl.formatMessage({ id: "objects.times.modal.form.end.datetime" })]}
								onChange={this.setDate}
								style={{ flex: 1, marginRight: 8 }} />
						)}
						<button disabled={lockFormGsmUtm} onClick={() => this.moveValueList(this.state.itemMove)} className="btn btn-sm" title={intl.formatMessage({ id: "common.add.to.list" })}><i className="fa fa-plus" /></button>
					</div>
				</Form.Item>

				<Form.Item {...formLayout} label="">
					<div style={{ display: 'flex' }}>
						<select disabled={lockFormGsmUtm} id="obj_date-list" style={{ marginRight: 8 }} className="ant-input" multiple={true}>
							{dataSource.map(this.renderList)}
						</select>
						<button disabled={lockFormGsmUtm} onClick={() => this.removeValueList()} style={{ height: 32, marginTop: 20 }} className="btn btn-sm" title={intl.formatMessage({ id: "common.del.to.list" })}><i className="fa fa-minus" /></button>
					</div>
				</Form.Item>

				<Form.Item {...formLayoutLast} label={intl.formatMessage({ id: "objects.schedules.modal.form.description" })} >
					{getFieldDecorator('obj_date-desc', { initialValue: schedules.description })(
						<TextArea disabled={lockFormGsmUtm} />
					)}
				</Form.Item>
			</Form >
		</DraggableModal>
	}
}

SchedulesModal.defaultProps = {
	onCancel: null,
	visible: false,
	schedules: {
		id: '',
		name: '',
		description: '',
		dates: [],
	}
}

export default injectIntl(Form.create({})(SchedulesModal));