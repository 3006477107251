import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Menu } from "antd";

const { SubMenu } = Menu;

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedKeys: []
    }
    this.menuItems = [
      {
        name: "sidebar.management",
        children: [
          { icon: "server", name: "sidebar.management.devices", link: "/devices" },
          { icon: "tags", name: "sidebar.management.policies", link: "/policies" },
          { icon: "list-alt", name: "sidebar.management.profiles", link: "/profiles" },
          { icon: "archive", name: "sidebar.management.objects", link: "/objects" },
          { icon: "users", name: "sidebar.management.users", link: "/users" }
        ]
      }, {
        name: "sidebar.analytics",
        children: [
          {
            icon: "line-chart",
            name: "sidebar.analytics.analyzer",
            link: "/analyzer",
            children: [
              { icon: "angle-double-right", name: "sidebar.analytics.firewall", link: "/analyzer/network" },
             // { icon: "angle-double-right", name: "sidebar.analytics.policyUsage", link: "/analyzer/policy" },
              { icon: "angle-double-right", name: "sidebar.analytics.webFilter", link: "/analyzer/webfilter" },
              { icon: "angle-double-right", name: "sidebar.analytics.applicationControl", link: "/analyzer/application" },
              { icon: "angle-double-right", name: "sidebar.analytics.intrusionPrevention", link: "/analyzer/ips" },
              { icon: "angle-double-right", name: "sidebar.analytics.threatProtection", link: "/analyzer/threat" },
              { icon: "angle-double-right", name: "sidebar.analytics.userBehavior", link: "/analyzer/user"},
            ]
          },
          { icon: "pie-chart", name: "sidebar.analytics.reports", link: "/reports" },
          { icon: "search", name: "sidebar.analytics.events", link: "/events" },
          // { icon: "warning", name: "sidebar.analytics.alerts", link: "/alerts" },
          { icon: "database", name: "sidebar.analytics.loggers", link: "/loggers" },
        ]
      }, {
        name: "sidebar.settings",
        children: [
          { icon: "sliders", name: "sidebar.settings.system", link: "/system" },
          { icon: "globe", name: "sidebar.settings.network", link: "/network" },
          { icon: "user-secret", name: "sidebar.settings.administration", link: "/administration" },
        ]
      }
    ];
    let selected = this.menuItems.map(m=>(m.children)).flat().filter(m=>location.pathname.startsWith(m.link))
    if (selected.length>0) {
      if (selected[0].children) {
        let child = selected[0].children.filter(c=>c.link==location.pathname)
        this.state.selectedKeys=[child[0].name]
      } else {
        this.state.selectedKeys=[selected[0].name]
      }
    } else if (location.pathname=="/") {
      this.state.selectedKeys=[this.menuItems[0].children[0].name]
    }
  }
  renderMenus(menu) {
    const { intl } = this.props;

    if (menu) {
      return menu.map((item) => (
        item.children ?
          <SubMenu popupClassName="sidebar-menu-popup" key={item.name} title={<Fragment><i className={`fa fa-${item.icon}`}></i>{intl.formatMessage({ id: item.name })}</Fragment>}>
            {this.renderMenus(item.children)}
          </SubMenu>
          :
          <Menu.Item className="sidebar-menu-item" key={item.name}>
            <Link to={item.link}>
              <i className={`fa fa-${item.icon}`}></i>{intl.formatMessage({ id: item.name })}
            </Link>
          </Menu.Item>
      ))
    }
  }
  render() {
    const { hidden, intl } = this.props;
    const { selectedKeys } = this.state;
    if (hidden)
      return <div />

    return <aside className="left-side sidebar-offcanvas">
      <section className="sidebar">
        <Menu className="sidebar-menu-sections" defaultSelectedKeys={selectedKeys}>
          {this.menuItems.map((item, idx) => (
            <Menu.ItemGroup key={item.name} title={intl.formatMessage({ id: item.name })}>
              {item.children && this.renderMenus(item.children)}
            </Menu.ItemGroup>
          ))}
        </Menu>
      </section>
    </aside>;
  }
}

export default injectIntl(SideBar);
