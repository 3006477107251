/**
 * Action Button
 * @version 1.0
 */

import React, {PureComponent} from 'react'
import { Button, Tooltip } from 'antd'

class ActionButton extends PureComponent {
  render() {
    const { icon, tooltip, onClick, tooltipOptions, style } = this.props;
    return (
      <Tooltip title={tooltip} {...tooltipOptions}>
        <Button className='bbui-action-button' onClick={onClick} style={style}>
          {icon}
        </Button>
      </Tooltip>
    )
  }
}

export default ActionButton

ActionButton.defaultProps = {
  tooltipOptions: {
    placement: 'top'
  }
};