import React, { Component } from "react";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import * as ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "/bbui/public/css/font-awesome.min.css";
import "/bbui/public/css/AdminLTE.css";
import "/bbui/public/css/bootstrap.min.css";
import "/bbui/public/css/colors.css";
import "/bbui/public/css/ui.css";
import { IntlProvider, addLocaleData } from "react-intl";
import AppLayout from "/layout/appLayout";
import Login from "/modules/login";
import ResetPassword from "/modules/resetPassword";
import NoMatch from "/layout/noMatch";
import enMessages from "/i18n/en";
import ptBRMessages from "/i18n/pt_BR";
import ptLocaleDate from "react-intl/locale-data/pt";
import Axios from "axios";
import { Spin, Icon, Row, message } from 'antd';
import Wizard from "/modules/wizard";
import params from "./public/branding/params.json";
import AxiosFormData from "/helpers/AxiosFormData";
import { ajaxUrl } from "/config/constants";
import { getCookie } from "/bbui/helpers/utils";
import { relativeTimeThreshold } from "moment";

addLocaleData(ptLocaleDate);
document.title = params.title;

const package_version = 'gsm';
let language = navigator.language.startsWith('pt') ? "pt-BR" : "en-US";
let loginlock = false;
const lang = window.localStorage.getItem('language');
if (lang == null) {
  window.localStorage.setItem('language', language);
}
function checkSession() {
  const data = Axios.get("/ajax/login.php?act=checkSessionLogin");
  return data;
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedSession: false,
      sso_enabled: false,
      language: window.localStorage.getItem('language'),
      loggedIn: false,
      install: false,
      disclaimerObj: {
        valid: false,
        enabled: false,
        type: '',
        lang: window.localStorage.getItem('language') || window.navigator.language,
        message: {
          'pt-BR': '',
          'en-US': ''
        },
      },
      languageSystem:''
    };
  }
  checkIntervalFunc = () => checkSession().then((res) => {
    
    if (res.data === "") {
      this.setState({ checkedSession: true, loggedIn: false, install: true });
    } else {
      if (res.data == "-2"){
    
        const { languageSystem } = this.state;

        let msg = '';
        languageSystem == 'en_US' ? msg = 'Please wait while system update is running!' : msg = 'Aguarde enquanto a atualização do sistema está em execução!';
    
        loginlock = true;
        this.setState({ loginlocked: true});
        message.config({duration: 14, maxCount: 1});
        message.warning(msg);
      } else {
        if (loginlock == true){
          window.location.reload(true);
        }
      }
      let { checkedSession, loggedIn, install } = this.state;
      let loggedInTest = (res.data == 1);
      if (!(checkedSession === true && loggedIn === loggedInTest && install === false)) {
        this.setState({ checkedSession: true, loggedIn: loggedInTest, install: false });
      }
    }
  });

  handleDisclaimer = () => {
    const { disclaimerObj } = this.state
    let langStandardDisclaimer
    if(lang != null)
      langStandardDisclaimer = lang
    else
      langStandardDisclaimer = window.localStorage.getItem('language');
  
    document.cookie = "disclaimer=true;"
    this.setState({
      disclaimerObj: {
        enabled: disclaimerObj.enabled,
        type: '',
        valid: true,
        lang: langStandardDisclaimer,
        message: {
          'pt-BR': '',
          'en-US': '',
        }
      }
    })
  }


  checkSaml = () => {
    // Check if saml is enable to render link sso
    Axios.post(ajaxUrl[package_version].login, AxiosFormData({ act: "checkSSO" }))
    .then(res=>{
      if(res.data != null){
        let sso_enabled = !(res.data == "false")
        this.setState({ sso_enabled })
      }
    })
  }

  checkDisclaimer = () => {
    const { disclaimerObj } = this.state;
    Axios.post(ajaxUrl[package_version].login, AxiosFormData({ act: "getDisclaimer" }))
      .then(res => {
        let valid = false;
        let langStandardDisclaimer = window.localStorage.getItem('language')
        let configDisclaimer = {}
        if (res.data != null) {
          configDisclaimer = res.data
          let message_pt;
          let message_en;
          try {
            message_pt = JSON.parse(configDisclaimer.message).pt_BR
            message_en = JSON.parse(configDisclaimer.message).en_US
          } catch (err) {
            message_pt = ''
            message_en = ''
          }
          // modal cookie confirmed ?
          valid = (getCookie("disclaimer") == 'true')

          this.setState({
            ...disclaimerObj,
            disclaimerObj: {
              valid,
              enabled: (configDisclaimer.enabled == "true"),
              type: configDisclaimer.type,
              lang: langStandardDisclaimer,
              message: {
                'pt-BR': message_pt,
                'en-US': message_en
              }
            }
          })
        }
      })
  }

  componentDidMount() {
    this.checkIntervalFunc();
    this.checkDisclaimer();
    this.checkSaml();
    this.handleLang();
    this.interval = setInterval(this.checkIntervalFunc, 15000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  handleChangeLang = (val) => {
    const { disclaimerObj } = this.state;
    if (val) {
      val = val.replace('_', '-');
      window.localStorage.setItem('language', val);
      disclaimerObj.lang = val
      this.setState({
        language: val,
        disclaimerObj: {
          ...disclaimerObj,
        }
      })
    }
  }

  handleLang = () => {
    Axios.post(ajaxUrl[package_version].settings_system, AxiosFormData({ 'tab': 'general', act: "getEditSettings" }))
      .then(res => this.setState({ languageSystem : res.data[0].language }))
  }

  handleLogout = () => {
    this.setState({ loggedIn: false });
  }
  render() {
    const { checkedSession, install, loggedIn, disclaimerObj, sso_enabled } = this.state;
    const { language } = this.state;
    const messages = language && language.startsWith("pt") ? ptBRMessages : enMessages;
    const { loginlocked } = this.state;

    if (!checkedSession && !loginlocked) {
      return <div className="void"><Row type="flex"
        justify="space-around"
        align="middle"
        style={{ height: "100%", textAlign: "center" }}>
        <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
      </Row></div>;
    }
  
    if (install)
      return <IntlProvider locale={language} messages={messages}>
        <BrowserRouter>
          <Switch>
            <Redirect from="/" exact to="/wizard" />
            <Redirect from="/login" exact to="/wizard" />
            <Route path="/wizard" render={() => <Wizard onChangeLang={this.handleChangeLang} />} />
          </Switch>
        </BrowserRouter>
      </IntlProvider>

    return <IntlProvider locale={language} messages={messages}>
      <BrowserRouter>
        <Switch>
          {loggedIn && <Redirect from="/login" to="/devices" />}
          <Route path="/resetPassword/:token" component={ResetPassword} />
          {!loginlocked && <Route path="/login" render={() => 
             <Login 
             checkSaml={this.checkSaml}
             sso_enabled={sso_enabled}
             handleDisclaimer={this.handleDisclaimer} 
             checkDisclaimer={this.checkDisclaimer}
             disclaimerObj={disclaimerObj} 
             checkIntervalFunc={this.checkIntervalFunc} 
             handleChangeLang={this.handleChangeLang} 
             language={language} />
             }/>
          }
          {!loggedIn && <Redirect from="/*" exact to="/login" />}
          <Route path="/*" render={() => <AppLayout handleDisclaimer={this.handleDisclaimer} disclaimerObj={disclaimerObj} onLogout={this.handleLogout} />} />
          {!loginlocked && <Route component={NoMatch} />}
        </Switch>
      </BrowserRouter>
    </IntlProvider>;
  }
}

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
