import React, { Component } from "react";
import { Modal, Button } from "antd";

import { FormattedMessage, injectIntl } from "react-intl";


const Disclaimer = props => {

  const { disclaimerObj, intl } = props;

  let language = disclaimerObj.lang.startsWith('pt') ? 'pt-BR' : disclaimerObj.lang;

  if (disclaimerObj.type == 'pos') {
    language = window.localStorage.getItem('language');
  }

  const cancelDisclaimer = () => {
    location.reload(true);
  }


  const renderLinkUrl = (text) => {
    var regex = /(https?:\/\/[^\s]+)/g;
    let parts = text.split(regex) 
    for (let i = 1; i < parts.length; i += 2) {
      parts[i] = <a key={'link' + i} target="blank" href={parts[i]}>{parts[i]}</a>
    }
    return parts
  }

  return <div className="login-disclaimer">
    <Modal
      maskClosable={false}
      closeIcon={<div></div>}
      style={{ marginTop: '-250px', textAlign: 'center' }}
      bodyStyle={{ height: '300px', overflow: 'auto', textAlign: 'left' }}
      title={intl.formatMessage({ id: "common.disclaimer.login.title" })}
      centered
      visible={disclaimerObj.enabled && !disclaimerObj.valid && disclaimerObj.type == props.type}
      onOk={() => props.handleDisclaimer()}
      onCancel={() => cancelDisclaimer()}
      footer={[
        <Button key="back" onClick={() => cancelDisclaimer()}>
          {intl.formatMessage({ id: "common.decline" })}
        </Button>,
        <Button key="submit" type="primary" onClick={() => props.handleDisclaimer()}>
          {intl.formatMessage({ id: "common.accept" })}
        </Button>,
      ]}
    >
      <p>{renderLinkUrl(disclaimerObj.message[language])}</p>
    </Modal>
  </div>

}

Disclaimer.defaultProps = {
  disclaimerObj: {
    valid: false,
    enabled: false,
    type: '',
    lang: window.navigator.userLanguage || window.navigator.language,
    message: {
      'pt-BR': '',
      'en-US': ''
    },
  }
}

export default injectIntl(Disclaimer);
