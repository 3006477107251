import React from "react";
import { Button, Popover, Select, DatePicker } from "antd";
const { RangePicker } = DatePicker;
import { injectIntl } from "react-intl";
import moment from 'moment';
import { datepicker_locale } from "/bbui/config/constants"

const lang = window.localStorage.getItem('language');
class Datepicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: "",
      pageSize: 10,
      filter_range: { dtinit: "", dtend: "" },
      date_filter_type: this.props.initDateRange, 
      visible: false,
      showTime: this.props.showTime, 
      allowed_date_type: !!this.props.allowed_date_type ? this.props.allowed_date_type : 
        ["last_10m", "today", "yesterday", "last_7", "last_30", "this_month", "last_month", "date", "range"],
    }
  }
  search = (date_filter_type) => {
    this.handleSelectDateFilter(date_filter_type);

    this.setState({
      date_filter_type
    }, () => {
      this.props.dateChange(this.state.filter_range, this.state.date_filter_type)
      this.hide();
    })
  }

  setPeriod = (date_filter_type) => {
    this.handleSelectDateFilter(date_filter_type);

    this.setState({
      date_filter_type
    }, () => {
      if (this.props.active) this.props.dateChange(this.state.filter_range, this.state.date_filter_type)
    })
  }

  hide = () => {
    this.setState({ visible: false });
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  handleOptionSelectDateFilter = (val) => {
    return <Select.Option value={`${val}`}>{this.props.intl.formatMessage({ id: `events.${val}` })}</Select.Option>
  }

  renderFilterContentDate() {
    const { date_filter_type, showTime } = this.state;

    return <div style={{ width: 290 }}>
      {this.props.intl.formatMessage({ id: "analytics.reports.period" })} &nbsp; <Select
        //onChange={this.handleSelectDateFilter}
        onChange={this.setPeriod}
        style={{ width: "100%" }}
        value={date_filter_type}
        defaultValue="today"
      >
        {/* <Select.Option value={"last_10m"}>{this.props.intl.formatMessage({ id: "events.last_10m" })}</Select.Option>
        <Select.Option value={"today"}>{this.props.intl.formatMessage({ id: "events.today" })}</Select.Option>
        <Select.Option value={"yesterday"}>{this.props.intl.formatMessage({ id: "events.yesterday" })}</Select.Option>
        <Select.Option value={"last_7"}>{this.props.intl.formatMessage({ id: "events.last_7" })}</Select.Option>
        <Select.Option value={"last_30"}>{this.props.intl.formatMessage({ id: "events.last_30" })}</Select.Option>
        <Select.Option value={"this_month"}>{this.props.intl.formatMessage({ id: "events.this_month" })}</Select.Option>
        <Select.Option value={"last_month"}>{this.props.intl.formatMessage({ id: "events.last_month" })}</Select.Option>
        <Select.Option value={"date"}>{this.props.intl.formatMessage({ id: "events.date" })}</Select.Option>
        <Select.Option value={"range"}>{this.props.intl.formatMessage({ id: "events.range" })}</Select.Option> */}

        {/* ["last_10m", "last_6h", "last_12h", "last_18h", "today", "yesterday", "date"], */}

        { !!this.state.allowed_date_type.find(val => val == "last_10m")   ?  this.handleOptionSelectDateFilter("last_10m") : "" }
        
        { !!this.state.allowed_date_type.find(val => val == "last_6h")    ?  this.handleOptionSelectDateFilter("last_6h") : "" }
        { !!this.state.allowed_date_type.find(val => val == "last_12h")   ?  this.handleOptionSelectDateFilter("last_12h") : "" }
        { !!this.state.allowed_date_type.find(val => val == "last_18h")   ?  this.handleOptionSelectDateFilter("last_18h") : "" }
        
        
        { !!this.state.allowed_date_type.find(val => val == "today")      ?  this.handleOptionSelectDateFilter("today") : "" }
        { !!this.state.allowed_date_type.find(val => val == "yesterday")  ?  this.handleOptionSelectDateFilter("yesterday") : "" }
        { !!this.state.allowed_date_type.find(val => val == "last_7")     ?  this.handleOptionSelectDateFilter("last_7") : "" }
        { !!this.state.allowed_date_type.find(val => val == "last_30")    ?  this.handleOptionSelectDateFilter("last_30") : "" }
        { !!this.state.allowed_date_type.find(val => val == "this_month") ?  this.handleOptionSelectDateFilter("this_month") : "" }
        { !!this.state.allowed_date_type.find(val => val == "last_month") ?  this.handleOptionSelectDateFilter("last_month") : "" }
        { !!this.state.allowed_date_type.find(val => val == "date")       ?  this.handleOptionSelectDateFilter("date") : "" }
        { !!this.state.allowed_date_type.find(val => val == "range")      ?  this.handleOptionSelectDateFilter("range") : "" }

      </Select>
      {["date", "range"].includes(date_filter_type) && <span><br /><br />
        {date_filter_type === "date"
          && <DatePicker
            size={"default"}
            locale={datepicker_locale[lang]}
            onChange={this.handleMonthFilter}
            format={"DD/MM/YYYY"}
            style={{ width: "100%" }} />}
        {date_filter_type === "range"
          && <RangePicker
            size={"default"}
            locale={datepicker_locale[lang]}
            onChange={this.handleRangeFilter}
            onCalendarChange={this.handleRangeCalChange}
            format={this.props.format}
            showTime={{ showTime }}
            style={{ width: "100%" }}
            disabledDate={this.handleDisableRange}
          />}
      </span>}
      <div style={{ "marginTop": "10px", "display": "flex", "justifyContent": "flex-end" }}>
        <Button size="small" onClick={this.hide}>{this.props.intl.formatMessage({ id: "common.close" })}</Button>
        <Button size="small" onClick={() => this.search(date_filter_type)} style={{ "marginLeft": "5px" }} >OK</Button>
      </div>
    </div>
  }

  handleDisableRange = date => {
    const { dtinit } = this.state.filter_range;
    const { maxDays } = this.props;

    if ((dtinit == "") || (maxDays == undefined)) return false;

    if (date) {
      if (date.isAfter(moment(dtinit).add(maxDays, 'days'))) return true;
      if (date.isBefore(moment(dtinit).subtract(maxDays - 1, 'days'))) return true;
    } else {
      return false;
    }
  }

  handleRangeCalChange = dates => {
    this.setState({
      filter_range: {
        dtinit: dates[0].format("YYYY-MM-DD HH:mm:ss")
      }
    })
  }

  handleSelectDateFilter = (date_filter_type) => {
    let filter_range = this.state.filter_range;

    if (["last_10m", "last_6h", "last_12h", "last_18h", "today", "yesterday", "last_7", "last_30", "this_month", "last_month"].includes(date_filter_type)) {

      const dateFormat = "DD/MM/YYYY";
      let ranges = {
        "today": [
          moment().format(dateFormat + ' 00:00'),
          moment().format(dateFormat + ' 23:59')
        ],
        "yesterday": [
          moment().subtract(1, 'days').format(dateFormat + ' 00:00'),
          moment().subtract(1, 'days').format(dateFormat + ' 23:59')
        ],
        "last_10m": [
          moment().subtract(10, 'minutes').format(dateFormat + ' H:mm'),
          moment().format('DD/MM/YYYY H:mm')
        ],
        "last_6h": [
          moment().subtract(6, 'hours').format(dateFormat + ' H:mm'),
          moment().format('DD/MM/YYYY H:mm')
        ],
        "last_12h": [
          moment().subtract(12, 'hours').format(dateFormat + ' H:mm'),
          moment().format('DD/MM/YYYY H:mm')
        ],
        "last_18h": [
          moment().subtract(18, 'hours').format(dateFormat + ' H:mm'),
          moment().format('DD/MM/YYYY H:mm')
        ],
        "last_7": [
          moment().subtract(6, 'days').format(dateFormat + ' 00:00'),
          moment().format(dateFormat + ' 23:59')
        ],
        "last_30": [
          moment().subtract(29, 'days').format(dateFormat + ' 00:00'),
          moment().format(dateFormat + ' 23:59')
        ],
        "this_month": [
          moment().startOf('month').format(dateFormat + ' 00:00'),
          moment().endOf('month').format(dateFormat + ' 23:59')
        ],
        "last_month": [
          moment().subtract(1, 'month').startOf('month').format(dateFormat + ' 00:00'),
          moment().subtract(1, 'month').endOf('month').format(dateFormat + ' 23:59')
        ]
      };

      filter_range = { dtinit: ranges[date_filter_type][0], dtend: ranges[date_filter_type][1] };
    }else if(date_filter_type == "range"){
      filter_range = { dtinit: this.state.filter_range.dtinit, dtend: this.state.filter_range.dtend };
    }else if (date_filter_type !== "date") {
      filter_range = { dtinit: "", dtend: "" };
    }


    this.setState({ filter_range })

    /*this.setState({
      filter_range: filter_range, date_filter_type
    }, () => {
      this.props.dateChange(this.state.filter_range)
    })*/
  };

  handleChange = (sorter) => {
    const { filters, filter_range } = this.state;
    if (typeof (this.props.onChange) == 'function') this.props.onChange(filters, sorter, filter_range);
  };

  handleFilter = (value) => {
    this.setState({ filters: value });
  };

  handleMonthFilter = (dateObject, dateString) => {
    let range = this.state.date_filter_type === "none"
      ? { dtinit: "", dtend: "" }
      : { dtinit: dateString, dtend: dateString };

    this.setState({ filter_range: range });
  };

  handleRangeFilter = (dateObject, dateString) => {
    if (dateString.filter(x=>x).length == 2) {
      const dateDiff = dateObject[1].diff(dateObject[0], 'seconds');
      if (dateDiff < 60) {
        dateString[1] = dateObject[0].add(1, 'minute').format("YYYY-MM-DD HH:mm:ss");
      }
      this.setState({
        filter_range: { dtinit: dateString[0], dtend: dateString[1] }
      }, () => {
        this.props.dateChange(this.state.filter_range)
      })
    }
  };

  render() {
    const { date_filter_type, filter_range } = this.state;
    const { intl } = this.props;

    let label;
    let date = date_filter_type.split("-")
    if (date.length > 1) {
      label = date_filter_type || filter_range
    } else {
      label = intl.formatMessage({ id: "events." + `${date_filter_type}` });
    }
    return (
      <Popover placement="bottomRight" content={this.renderFilterContentDate()} visible={this.state.visible} onVisibleChange={this.handleVisibleChange} trigger="click">
        <Button style={{ width: "100%" }}>{label}<i style={{ "marginLeft": "10px" }} className="fa fa-calendar"></i></Button>
      </Popover >
    )
  }
}

Datepicker.defaultProps = {
  onChange: null,
  initDateRange: 'today',
};

export default injectIntl(Datepicker);