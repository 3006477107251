import React, { Component, Fragment } from "react";
import { Form, Button, Tooltip, message } from "antd";
import { injectIntl } from "react-intl";
import { Loading, FormHandler, Denied } from "/bbui/components/";
import AxiosFormData from "/helpers/AxiosFormData";
import Axios from "axios";
import { ajaxUrl } from "/config/constants";

class CertificateForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: null,
      dataSource: [],
      loading: false,
      lockForm: false,
      denied: false,
    }
  }

  saveSettings = () => {
    const { intl } = this.props;
    const { form } = this.state;

    this.setState({ loading: true, lockForm: true });

    form.validateFields((err, values) => {
      if (!err) {
        const bodyData = new AxiosFormData({
          "act": "saveSettings",
          "form-type": "certificate",
          "form-action": "identity-provider",
          "settings_system-certificate-country": values["settings_system-certificate-country"],
          "settings_system-certificate-state": values["settings_system-certificate-state"],
          "settings_system-certificate-city": values["settings_system-certificate-city"],
          "settings_system-certificate-organization": values["settings_system-certificate-organization"],
          "settings_system-certificate-email": values["settings_system-certificate-email"],
          "settings_system-certificate-unit": values["settings_system-certificate-unit"],
          "settings_system-certificate-expires": parseInt(values["settings_system-certificate-expires"]) * 365,
          "settings_system-certificate-hostname": values["settings_system-certificate-hostname"],
          "settings_system-certificate-keysize": values["settings_system-certificate-keysize"],
        });

        Axios.post(ajaxUrl["gsm"].settings_system, bodyData).then((res) => {

          if (Array.isArray(res.data) && res.data.length == 2)
            if (res.data[1]['errorcode'] == '001') {
              this.setState({ loading: false, lockForm: false });
              return message.error(this.props.intl.formatMessage({ id: "common.dont.permission" }))
            }

          if (res.data[0].success == "1") {
            // message.success(intl.formatMessage({ id: "system.certificate.save.success" }));
            this.setState({ lockForm: false, loading: false });

          } else if ("field" in res.data[0]) {
            res.data.forEach(erro => {
              form.setFields({
                [erro.field]: {
                  value: values[erro.field],
                  errors: [new Error(intl.formatMessage({ id: "common.invalid.value" }))],
                },
              })
            })
            message.error(intl.formatMessage({ id: "system.certificate.save.error" }));
          } else {
            if ("errorinfo" in res.data[0]) {
              message.error(intl.formatMessage({ id: "system.certificate.save.error" }));
            }
          }
        }).finally(() => {
          this.setState({ loading: false, lockForm: false });
        });
      } else {
        this.setState({ loading: false, lockForm: false });
      }
    })
  }

  componentDidMount() {
    const formData = new FormData();
    formData.set("act", "getEditSettings");
    formData.set("tab", "certificate");
    formData.set("form-action", "identity-provider");

    this.props.getMethod(this.saveSettings);

    this.setState({ loading: true, lockForm: true });

    Axios.post(ajaxUrl["gsm"].settings_system, formData).then((res) => {
      if (Array.isArray(res.data) && res.data.length == 2)
        if (res.data[1]['errorcode'] == '001')
          return this.setState({ denied: true, loading: false, lockForm: false })

      if (res.data.length > 0) {
        res.data[0]['settings_system-certificate-expires'] = parseInt(res.data[0]['settings_system-certificate-expires'])/365
        this.setState({ dataSource: res.data[0] });
      }
      this.setState({ loading: false, lockForm: false });
    });
  }

  render() {
    const { intl } = this.props;
    const { dataSource, lockForm, loading, denied } = this.state;
    const formLayout = { colon: false, layout: 'vertical', style: { paddingBottom: 0 } };

    return <Denied denied={denied}><Loading loading={loading} /><Fragment>
      <FormHandler
        wrappedComponentRef={(inst) => inst && (this.state.form = inst.props.form)}
        lockForm={lockForm}
        colExtra={{ xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }, xl: { span: 24 } }}
        dataSource={dataSource}
        formConfig={{
          layout: formLayout,
          fields: [
            {
              type: "row",
              gutter: !!this.props.gutter ? this.props.gutter : 12,
              children: [
                {
                  type: "col",
                  span: !!this.props.span ? this.props.span : 12,
                  // children: [
                  //   {
                  //     type: "card",
                  //     options: {
                  //       style: {},
                  //       extra: <Tooltip title={intl.formatMessage({ id: "system.certificate.save.settings" })} >
                  //         <Button onClick={this.saveSettings} size="small" className="ant-btn-icon-only"><i className="fa fa-save" /></Button>
                  //       </Tooltip>
                  //     },
                  //     title: intl.formatMessage({ id: "common.certificates" }),
                      children: [
                        {
                          type: "row",
                          children: [
                            {
                              label: intl.formatMessage({ id: "common.country" }),
                              name: 'settings_system-certificate-country',
                              type: "text",
                              span: 12,
                              maxLength: 100,
                              rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "common.country" }) }) }],
                              placeholder: intl.formatMessage({ id: "certificate.country" }),
                            }, {
                              label: intl.formatMessage({ id: "common.state" }),
                              name: 'settings_system-certificate-state',
                              type: "text",
                              span: 12,
                              maxLength: 100,
                              rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "common.state" }) }) }],
                              placeholder: intl.formatMessage({ id: "certificate.state" }),
                            },
                          ]
                        }, {
                          type: "row",
                          children: [
                            {
                              label: intl.formatMessage({ id: "common.city" }),
                              name: 'settings_system-certificate-city',
                              type: "text",
                              span: 12,
                              maxLength: 100,
                              rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "common.city" }) }) }],
                              placeholder: intl.formatMessage({ id: "certificate.state" }),
                            }, {
                              label: intl.formatMessage({ id: "common.organization" }),
                              name: "settings_system-certificate-organization",
                              type: "text",
                              span: 12,
                              maxLength: 100,
                              rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "common.organization" }) }) }],
                              placeholder: intl.formatMessage({ id: "certificate.organization" }),
                            },
                          ]
                        }, {
                          type: "row",
                          children: [
                            {
                              label: intl.formatMessage({ id: "common.email" }),
                              name: 'settings_system-certificate-email',
                              span: 12,
                              maxLength: 100,
                              type: "text",
                              allowClear: true,
                              rules: [
                                { required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "common.email" }) }) },
                                { type: "email", message: intl.formatMessage({ id: "common.error.invalid" }, { name: intl.formatMessage({ id: "common.email" }) }) }
                              ],
                              placeholder: intl.formatMessage({ id: "certificate.email" }),
                            }, {
                              label: intl.formatMessage({ id: "common.unit" }),
                              name: 'settings_system-certificate-unit',
                              span: 12,
                              maxLength: 100,
                              type: "text",
                              allowClear: true,
                              rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "common.unit" }) }) }],
                              placeholder: intl.formatMessage({ id: "certificate.unit" }),
                            }
                          ]
                        }, {
                          type: "row",
                          children: [
                            {
                              label: intl.formatMessage({ id: "common.expires" }),
                              name: 'settings_system-certificate-expires',
                              span: 12,
                              min: 1,
                              max: 36500,
                              type: "number",
                              extra: { style: { width: "100%" }, precision: 0, step: 1 },
                              allowClear: true,
                              rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "common.expires" }) }) }],
                              placeholder: intl.formatMessage({ id: "common.years" }),
                            }, {
                              label: intl.formatMessage({ id: "common.hostname" }),
                              name: 'settings_system-certificate-hostname',
                              span: 12,
                              maxLength: 100,
                              type: "text",
                              allowClear: true,
                              rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "common.hostname" }) }) }],
                              placeholder: intl.formatMessage({ id: "common.hostname" }),
                            }
                          ]
                        },
                        {
                          type: "row",
                          children: [
                            {
                              label: intl.formatMessage({ id: "certificate.keysize" }),
                              name: 'settings_system-certificate-keysize',
                              span: 12,
                              min: 1,
                              max: 100000000,
                              type: "number",
                              extra: { style: { width: "100%" }, precision: 0, step: 1 },
                              allowClear: true,
                              rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "certificate.keysize" }) }) }],
                              placeholder: intl.formatMessage({ id: "certificate.keysize" }),
                            }
                          ]
                        }
                      ]
                    // },
                  // ]
                },
              ]
            }
          ]
        }
        }
      />
    </Fragment>    </Denied>


  }

}

export default injectIntl(Form.create()(CertificateForm));