export const product = "GSM"

export const ajaxUrl = {
  '1.5': {
    rules: '/ajax/policy-manager-rules.php',
    templatesBase: '/ajax/policy-templates.php',
    templates: '/ajax/policy-templates-v1.5.php',
    add: '/ajax/policy-manager-rules-add-v1.5.php',
    devices_templates: '/ajax/device-templates-v1.5.php',
  },
  '2.0': {
    add: '/ajax/policy-manager-rules-add-v1.5.php',
    profileWebfilter: '/ajax/profile-webfilter-v2.0.php',
    objects: '/ajax/objects.php',
    threatProtection: '/ajax/profile-Threat-Protection-v2.0.php',
    devices_templates: '/backend/2.x/device-templates.php',
  },
  '2.1': {
    add: '/ajax/policy-manager-rules-add-v1.5.php',
    profileWebfilter: '/ajax/profile-webfilter-v2.0.php',
    objects: '/ajax/objects.php',
    threatProtection: '/ajax/profile-Threat-Protection-v2.0.php',
    devices_templates: '/backend/2.1.x/device-templates.php',
    devices_backups: '/backend/2.1.x/device-backups.php',
  },
  'gsm': {
    loggers: '/ajax/analyzer-loggers.php',
    devices: '/ajax/devices.php',
    devices_templates: '/ajax/device-templates-v1.5.php',
    policy_manager: '/ajax/policy-manager.php',
    policy_templates: '/ajax/policy-templates.php',
    settings_admin: '/ajax/settings-admin.php',
    settings_system: '/ajax/settings-system.php',
    settings_network: '/ajax/settings-network.php',
    login: '/ajax/login.php'
  },
  'analyzer': {
    analyzerIps: '/ajax/analyzer-ips.php',
    analyzerNetwork: '/ajax/analyzer-network.php',
    analyzerEvents: '/ajax/analyzer-events.php',
    analyzerWeb: '/ajax/analyzer-web.php',
    analyzerApps: '/ajax/analyzer-apps.php',
    analyzerAtp: '/ajax/analyzer-atp.php',
    analyzerPolicy: '/ajax/analyzer-policy.php',
    analyzerUser: '/ajax/analyzer-user.php',
    analyzerReports: '/ajax/analyzer-reports.php',
  },

}

export const utm_versions = (minVersion, maxVersion) => {
  let versions = [
    { value: '1.4', text: '1.4' },
    { value: '1.5', text: '1.5' },
    { value: '2.0', text: '2.0' },
    { value: '2.1', text: '2.1' },
  ]
  if (maxVersion) {
    return versions.filter(i => ((i.value >= minVersion) && (i.value <= maxVersion)));
  } else {
    return versions.filter(i => i.value >= minVersion);
  }
}

export const ip_version = [
  { value: 'ipv4', text: 'IPv4' },
  { value: 'ipv6', text: 'IPv6' },
]

export const ddns_options = [
  { value: 'NoIP.org', text: 'NoIP.org' },
  { value: 'DynDNS.com', text: 'DynDNS.com' },
]

export const status_provisioning = {
  'expire': {
    text: 'Expired license',
    percent: '0'
  },
  'init': {
    text: 'Registering',
    percent: '5'
  },
  'batch': {
    text: 'Checking batch',
    percent: '5',
  },
  'error': {
    text: 'Error',
    percent: '0',
  },
  'errorbatch': {
    text: 'Error batch',
    percent: '0',
  },
  'waitapproval': {
    text: 'Waiting for approval',
    percent: '10'
  },
  'approval': {
    text: 'Applying initial settings',
    percent: '15'
  },
  'provisioning': {
    text: 'Configuring device',
    percent: '20'
  },
  'gen_key': {
    text: 'Waiting for key exchange',
    percent: '30'
  },
  'connect_server': {
    text: 'Connecting device',
    percent: '40'
  },
  'deploy': {
    text: 'Sending deploy',
    percent: '50',
  },
  'apply': {
    text: 'Apply deploy',
    percent: '70',
  },
  'success': {
    text: 'Device configured',
    percent: '100',
  }
}

export const deploy_msg = {
  'sync_info': {
    text: 'Device not sync',
  },
  'version': {
    text: 'Task version is not compatible with this device',
  },
  'tun': {
    text: 'Connection error',
  },
  'auth': {
    text: 'Authentication failure',
  }
}

export const appliance_models = {
  hardware_appliance: {
    value: 'hardware_appliances',
    text: 'Hardware Appliances',
    children: [
      { value: 'BB-1', text: 'BB-1' },
      { value: 'BB-2', text: 'BB-2' },
      { value: 'BB-5', text: 'BB-5' },
      { value: 'BB-10', text: 'BB-10' },
      { value: 'BB-30', text: 'BB-30' },
      { value: 'BB-50', text: 'BB-50' },
      { value: 'BB-100', text: 'BB-100' },
      { value: 'BB-300', text: 'BB-300' },
      { value: 'BB-500', text: 'BB-500' },
      { value: 'BB-1000', text: 'BB-1000' },
      { value: 'BB-2000', text: 'BB-2000' },
      { value: 'BB-10000', text: 'BB-10000' },
      { value: 'BB-20000', text: 'BB-20000' },
    ]
  },
  virtual_appliance: {
    value: 'virtual_appliances',
    text: 'Virtual Appliances',
    children: [
      { value: 'BBv-2', text: 'BBv-2' },
      { value: 'BBv-10', text: 'BBv-10' },
      { value: 'BBv-30', text: 'BBv-30' },
      { value: 'BBv-100', text: 'BBv-100' },
      { value: 'BBv-300', text: 'BBv-300' },
      { value: 'BBv-1000', text: 'BBv-1000' },
      { value: 'BBv-2000', text: 'BBv-2000' },
      { value: 'BBv-10000', text: 'BBv-10000' },
      { value: 'BBv-20000', text: 'BBv-20000' },
    ]
  }
}

export const listServicesFirewall = [{
  key: 'auth',
  name: 'Authentication',
}, {
  key: 'adm',
  name: 'Administration',
}, {
  key: 'dns',
  name: 'DNS',
}, {
  key: 'dhcp',
  name: 'DHCP',
}, {
  key: 'vpn',
  name: 'VPN',
}, {
  key: 'vpnssl',
  name: 'VPN SSL',
}, {
  key: 'smartweb',
  name: 'Web Proxy',
}];

export const optionsFirewall = {
  "auth": [
    { port: "9803", proto: "6", title: "Portal", val: "auth-portal", notnull: "1", default: true },
    { port: "1813", proto: "6", title: "Radius", val: "auth-rsso", notnull: "0", default: true }
  ],
  'adm': [
    { port: "98", proto: "6", title: "Web Port", val: "adm-web", notnull: "1", default: true },
    { port: "22", proto: "6", title: "SSH Port", val: "adm-ssh", notnull: "0", default: true },
    { port: "161", proto: "17", title: "SNMP Port", val: "adm-snmp", notnull: "0", default: false }],
  'dns': [
    { port: "53", proto: "6", title: "TCP Port", val: "dns-tcp", notnull: "0", default: true },
    { port: "53", proto: "17", title: "UDP Port", val: "dns-udp", notnull: "0", default: true }],
  'dhcp': [
    { port: "67", proto: "17", title: "IPv4 Port", val: "dhcp-v4", notnull: "0", default: false },
    { port: "547", proto: "17", title: "IPv6 Port", val: "dhcp-v6", notnull: "0", default: false }],
  'vpn': [
    { port: "4500", proto: "17", title: "NAT-T", val: "vpn-natt", notnull: "0", default: true },
    { port: "500", proto: "17", title: "ISAKMP", val: "vpn-isakmp", notnull: "0", default: true },
    { port: "", proto: "50", title: "ESP", val: "vpn-esp", notnull: "0", default: true },
    { port: "", proto: "47", title: "GRE", val: "vpn-gre", notnull: "0", default: true }],
  'vpnssl': [
    //{ port: "922", proto: "6", title: "Portal", val: "vpnssl-desktop", notnull: "0", default: true },
    { port: "9443", proto: "6", title: "Server", val: "vpnssl-server", notnull: "0", default: true }],
  'smartweb': [{ port: "128", proto: "6", title: "Proxy", val: "smartweb-proxy", notnull: "0", default: true }]
}

export const ntpServersDefault = [
  { value: "pool.ntp.org", text: 'pool.ntp.org' },
  { value: "asia.pool.ntp.org", text: 'asia.pool.ntp.org' },
  { value: "europe.pool.ntp.org", text: 'europe.pool.ntp.org' },
  { value: "north-america.pool.ntp.org", text: 'north-america.pool.ntp.org' },
  { value: "oceania.pool.ntp.org", text: 'oceania.pool.ntp.org' },
]

export const zoneProtectionDefault = [{
  "key": 0,
  "firewall_input-priority": "1",
  "firewall_input-auth": false,
  "firewall_input-action": "allow",
  "firewall_input-enabled": true,
  "firewall_input-description": "Proxy",
  "firewall_input_time-time_id": [],
  "firewall_input_zone-zone_id": "LAN",
  "firewall_input_srv-service_id": "6",
  "firewall_input_time-on-time_id": false,
  "firewall_input_addr_dst-addr_id": [],
  "firewall_input_addr_src-addr_id": [],
  "firewall_input_addr_dst-addr6_id": [],
  "firewall_input_addr_src-addr6_id": [],
  "firewall_input_addr_dst-on-addr_id": false,
  "firewall_input_addr_src-on-addr_id": false,
  "firewall_input_addr_dst-on-addr6_id": false,
  "firewall_input_addr_src-on-addr6_id": false
}, {
  "key": 1,
  "firewall_input-priority": "2",
  "firewall_input-auth": false,
  "firewall_input-action": "allow",
  "firewall_input-enabled": true,
  "firewall_input-description": "VPNSSL",
  "firewall_input_time-time_id": [],
  "firewall_input_zone-zone_id": "LAN",
  "firewall_input_srv-service_id": "7",
  "firewall_input_time-on-time_id": false,
  "firewall_input_addr_dst-addr_id": [],
  "firewall_input_addr_src-addr_id": [],
  "firewall_input_addr_dst-addr6_id": [],
  "firewall_input_addr_src-addr6_id": [],
  "firewall_input_addr_dst-on-addr_id": false,
  "firewall_input_addr_src-on-addr_id": false,
  "firewall_input_addr_dst-on-addr6_id": false,
  "firewall_input_addr_src-on-addr6_id": false
}, {
  "key": 2,
  "firewall_input-priority": "3",
  "firewall_input-auth": false,
  "firewall_input-action": "allow",
  "firewall_input-enabled": true,
  "firewall_input-description": "IPsec",
  "firewall_input_time-time_id": [],
  "firewall_input_zone-zone_id": "LAN",
  "firewall_input_srv-service_id": "54",
  "firewall_input_time-on-time_id": false,
  "firewall_input_addr_dst-addr_id": [],
  "firewall_input_addr_src-addr_id": [],
  "firewall_input_addr_dst-addr6_id": [],
  "firewall_input_addr_src-addr6_id": [],
  "firewall_input_addr_dst-on-addr_id": false,
  "firewall_input_addr_src-on-addr_id": false,
  "firewall_input_addr_dst-on-addr6_id": false,
  "firewall_input_addr_src-on-addr6_id": false
}, 
/*{
  "key": 3,
  "firewall_input-priority": "2",
  "firewall_input-auth": false,
  "firewall_input-action": "allow",
  "firewall_input-enabled": true,
  "firewall_input-description": "DHCP",
  "firewall_input_time-time_id": [],
  "firewall_input_zone-zone_id": "LAN",
  "firewall_input_srv-service_id": "8",
  "firewall_input_time-on-time_id": false,
  "firewall_input_addr_dst-addr_id": [],
  "firewall_input_addr_src-addr_id": [],
  "firewall_input_addr_dst-addr6_id": [],
  "firewall_input_addr_src-addr6_id": [],
  "firewall_input_addr_dst-on-addr_id": false,
  "firewall_input_addr_src-on-addr_id": false,
  "firewall_input_addr_dst-on-addr6_id": false,
  "firewall_input_addr_src-on-addr6_id": false
}, */
{
  "key": 3,
  "firewall_input-priority": "4",
  "firewall_input-auth": false,
  "firewall_input-action": "allow",
  "firewall_input-enabled": true,
  "firewall_input-description": "DNS",
  "firewall_input_time-time_id": [],
  "firewall_input_zone-zone_id": "LAN",
  "firewall_input_srv-service_id": "9",
  "firewall_input_time-on-time_id": false,
  "firewall_input_addr_dst-addr_id": [],
  "firewall_input_addr_src-addr_id": [],
  "firewall_input_addr_dst-addr6_id": [],
  "firewall_input_addr_src-addr6_id": [],
  "firewall_input_addr_dst-on-addr_id": false,
  "firewall_input_addr_src-on-addr_id": false,
  "firewall_input_addr_dst-on-addr6_id": false,
  "firewall_input_addr_src-on-addr6_id": false
}, {
  "key": 4,
  "firewall_input-priority": "5",
  "firewall_input-auth": false,
  "firewall_input-action": "allow",
  "firewall_input-enabled": true,
  "firewall_input-description": "Administration",
  "firewall_input_time-time_id": [],
  "firewall_input_zone-zone_id": "LAN",
  "firewall_input_srv-service_id": "56",
  "firewall_input_time-on-time_id": false,
  "firewall_input_addr_dst-addr_id": [],
  "firewall_input_addr_src-addr_id": [],
  "firewall_input_addr_dst-addr6_id": [],
  "firewall_input_addr_src-addr6_id": [],
  "firewall_input_addr_dst-on-addr_id": false,
  "firewall_input_addr_src-on-addr_id": false,
  "firewall_input_addr_dst-on-addr6_id": false,
  "firewall_input_addr_src-on-addr6_id": false
}, {
  "key": 5,
  "firewall_input-priority": "6",
  "firewall_input-auth": false,
  "firewall_input-action": "allow",
  "firewall_input-enabled": true,
  "firewall_input-description": "Authentication",
  "firewall_input_time-time_id": [],
  "firewall_input_zone-zone_id": "LAN",
  "firewall_input_srv-service_id": "55",
  "firewall_input_time-on-time_id": false,
  "firewall_input_addr_dst-addr_id": [],
  "firewall_input_addr_src-addr_id": [],
  "firewall_input_addr_dst-addr6_id": [],
  "firewall_input_addr_src-addr6_id": [],
  "firewall_input_addr_dst-on-addr_id": false,
  "firewall_input_addr_src-on-addr_id": false,
  "firewall_input_addr_dst-on-addr6_id": false,
  "firewall_input_addr_src-on-addr6_id": false
}, {
  "key": 6,
  "firewall_input-priority": "7",
  "firewall_input-auth": false,
  "firewall_input-action": "allow",
  "firewall_input-enabled": false,
  "firewall_input-description": "Active SNMP",
  "firewall_input_time-time_id": [],
  "firewall_input_zone-zone_id": "LAN",
  "firewall_input_srv-service_id": "42",
  "firewall_input_time-on-time_id": false,
  "firewall_input_addr_dst-addr_id": [],
  "firewall_input_addr_src-addr_id": [],
  "firewall_input_addr_dst-addr6_id": [],
  "firewall_input_addr_src-addr6_id": [],
  "firewall_input_addr_dst-on-addr_id": false,
  "firewall_input_addr_src-on-addr_id": false,
  "firewall_input_addr_dst-on-addr6_id": false,
  "firewall_input_addr_src-on-addr6_id": false
}]