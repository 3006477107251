import React, { Component, Fragment } from "react";
import Axios from "axios";
import AxiosFormData from "/helpers/AxiosFormData";
import { DraggableModal } from "/bbui/components/";
import { Form, Input, Select, message, Button, TimePicker } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import { ajaxUrl } from "/bbui/config/constants";
import { product } from "/config/constants";
import "../Objects.css"
var _ = require('lodash');
import moment from 'moment';

const package_version = 'app';

class TimesModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lockForm: false,
			message: null,
			itemMove: {
				"obj_time-hour_ini": "",
				"obj_time-minute_ini": "",
				"obj_time-hour_end": "",
				"obj_time-minute_end": ""
			},
			times: props.times,
			editTitlePrefix: ''
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.id !== prevProps.id) {
			const { id } = this.props;
			if (id) {
				this.setState({ editTitlePrefix: this.props.intl.formatMessage({ id: "common.edit" }) });
				Axios.post(ajaxUrl[package_version].objects, AxiosFormData({ act: "getObjects", type: "time", id })).then((res) => {
					if (res.data) {
						let data = JSON.parse(res.data[0].data)
						this.setState({
							times: {
								id,
								name: data['obj_time-name'],
								description: data['obj_time-description'],
								week_day: data['obj_time-week_day'],
								week_time: data['obj_time-week_time'],
								gsmid: data['obj_time-gsmid']
							}
						})
					}
				})
			}
		}
		if (this.props.times !== prevProps.times) {
			this.setState({
				times: this.props.times
			})
		}
	}

	handleSave = () => {
		this.setState({ lockForm: true });

		const { intl, form } = this.props;
		let fields = ["obj_time-name", "obj_time-day", "obj_time-desc"]
		let invalid = false;

		var list = document.getElementById("obj_time-list");
		if (list.options.length == 0)
			invalid = true

		form.validateFields(fields, (err, values) => {
			if (invalid === true) {
				this.setState({ lockForm: false });
				message.error(intl.formatMessage({ id: "objects.address.modal.form.invalid" }));
				list.classList.add("ui-has-error");
				return
			} else {
				if (!err) {
					this.saveObj(form, values);
				} else {
					this.setState({ lockForm: false });
				}
			}
		});
	}

	handleCancel = () => {
		const { onCancel } = this.props;
		this.props.form.resetFields();
		let field = document.getElementById("obj_time-list");
		if (field) field.classList.remove("ui-has-error");
		onCancel();
	}

	addValueList = (value) => {
		let { week_time: list } = this.state.times;

		var object = list.some(p =>
			p["obj_time-hour_end"] == value["obj_time-hour_end"] &&
			p["obj_time-hour_ini"] == value["obj_time-hour_ini"] &&
			p["obj_time-minute_end"] == value["obj_time-minute_end"] &&
			p["obj_time-minute_ini"] == value["obj_time-minute_ini"]
		)

		if (object == false) {
			list.push(_.cloneDeep(value));
			this.setState({
				times: {
					...this.state.times,
					week_time: list
				}
			});
		}

		var element = document.getElementById("obj_time-list");
		element.classList.remove("ui-has-error")
	}

	delValueList = (value) => {
		let { week_time: list } = this.state.times;
		for (var i = 0; i < list.length; i++) {
			let remove = list[i]['obj_time-hour_ini'] + ":" + list[i]['obj_time-minute_ini'] + "^" + list[i]['obj_time-hour_end'] + ":" + list[i]['obj_time-minute_end'];

			if (remove === value) {
				list.splice(i, 1);
			}
		}
		this.setState({
			times: {
				...this.state.times,
				week_time: list
			}
		});
	}

	moveValueList = (value) => {
		const { validateFields, resetFields } = this.props.form;
		let fields = ["obj_time_ini", "obj_time_end"]

		validateFields(fields, (errors) => {

			if (!errors) {
				resetFields(["obj_time_ini", "obj_time_end"]);
				this.addValueList(value);
			}
		});
	}

	removeValueList = () => {
		let selectElement = document.getElementById('obj_time-list')
		let values = Array.from(selectElement.selectedOptions).map(option => option.value)
		values.forEach(function (key, ind) {
			this.delValueList(key);
		}, this);
	}

	renderListDay(row) {
		return (<option key={row.toString()}>{row}</option>)
	}

	renderListTime(row) {
		let itime = row['obj_time-hour_ini'] + ":" + row['obj_time-minute_ini'];
		let etime = row['obj_time-hour_end'] + ":" + row['obj_time-minute_end'];
		let value = itime + "^" + etime;

		return (<option key={value.toString()} value={value}>{itime + "-" + etime}</option>)
	}

	setHourIni = (time, timeString) => {
		let hour = timeString.split(':');

		let editObject = this.state.itemMove;
		editObject["obj_time-hour_ini"] = hour[0];
		editObject["obj_time-minute_ini"] = hour[1];
		this.setState({ itemMove: editObject });
	};

	setHourEnd = (time, timeString) => {
		let hour = timeString.split(':');

		let editObject = this.state.itemMove;
		editObject["obj_time-hour_end"] = hour[0];
		editObject["obj_time-minute_end"] = hour[1];
		this.setState({ itemMove: editObject });
	};

	saveObj = (form, values) => {
		const { intl } = this.props;
		const { times } = this.state;
		const itens = [];
		var element = document.getElementById("obj_time-list");
		for (var i = 0; i < element.options.length; i++) {
			itens.push(element.options[i].value);
		}

		let bodyData = AxiosFormData({
			"act": "saveSchedule",
			"obj_time-name": values["obj_time-name"],
			"obj_time-desc": values["obj_time-desc"] || '',
			"obj_time-day": values["obj_time-day"],
			"obj_time-list": itens,
		})

		if (times.id !== '') {
			bodyData.set("obj_time-id", times.id);
		}

		Axios.post(ajaxUrl[package_version].objects, bodyData).then((res) => {
			this.setState({ lockForm: false });

			if (product == "GSM" && Array.isArray(res.data) && res.data.length == 2)
				if (res.data[1]['errorcode'] == '001') {
					form.resetFields();
					element.classList.remove("ui-has-error")
					this.props.onCancel();
					return message.error(this.props.intl.formatMessage({ id: "common.dont.permission" }))
				}

			if (res.data === "Seu login não permite acesso a esta area." || res.data === "Your login does not allow access to this area.") {
				form.resetFields();
				element.classList.remove("ui-has-error")
				this.props.onCancel();
				return message.error(this.props.intl.formatMessage({ id: "common.dont.permission.edit" }));
			}

			if (res.data[0].success == 1) {
				message.success(intl.formatMessage({ id: "objects.actions.create.success" }));
				form.resetFields();
				element.classList.remove("ui-has-error")
				this.props.onOk();
			} else {
				res.data.forEach(erro => {
					form.setFields({
						[erro.field]: {
							value: values[erro.field],
							errors: [new Error(intl.formatMessage({ id: "common.invalid.value" }))],
						},
					});
					if (erro.field == "obj_time-list[]") {
						element.classList.add("ui-has-error")
					}

					if (res.data[0]['exists']) {
						form.setFields({
							'obj_time-name': {
								value: form.getFieldValue('obj_time-name'),
								errors: [new Error(this.props.intl.formatMessage({ id: "common.alreadyExists" }))]
							}
						})
					}
					message.error(intl.formatMessage({ id: "objects.actions.create.error" }));
				});
			}
		});
	}

	render() {
		let { intl, visible, titlePrefix } = this.props;
		let { times, lockForm, editTitlePrefix } = this.state;
		const { getFieldDecorator, getFieldValue } = this.props.form;
		const { TextArea } = Input;
		const Option = Select.Option;
		const formLayout = { colon: false, style: { paddingBottom: 0 } };
		const formLayoutLast = { colon: false, style: { paddingBottom: 0, marginBottom: 0 } };

		let controleLoadingButton = lockForm
		let lockFormGsmUtm = false

		//Object GSM disabled
		if(this.props.times.gsmid || this.state.times.gsmid){
			lockFormGsmUtm = true;
			controleLoadingButton = true;
		}

		let rows = [];
		if (titlePrefix == '' && editTitlePrefix == '') {
			titlePrefix = intl.formatMessage({ id: "common.create" });
		}

		let { week_time: rows2 } = this.state.times;
		if (!Array.isArray(rows2)) rows2 = [];

		if (times.id == '') {
			times = {};
		} else {
			rows = times.week_day;
		}

		const footerActions = [
			<Button key="cancel" onClick={this.handleCancel}>
				<FormattedMessage id="common.cancel" />
			</Button>,
			<Button loading={controleLoadingButton} disabled={lockFormGsmUtm} key="save" type="primary" onClick={this.handleSave}>
				<FormattedMessage id="common.save" />
			</Button>
		]

		return <DraggableModal
			onOk={this.handleSave}
			onCancel={this.handleCancel}
			confirmLoading={lockFormGsmUtm}
			visible={visible}
			title={<Fragment>{titlePrefix || editTitlePrefix}<FormattedMessage id="objects.modal.title" values={{ object: "Times" }} /></Fragment>}
			okText={intl.formatMessage({ id: "common.save" })}
			cancelText={intl.formatMessage({ id: "common.cancel" })}
			footer={footerActions}>
			<Form id="form-object-times" layout="vertical">
				<Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.times.modal.form.name" })} >
					{getFieldDecorator('obj_time-name', {
						initialValue: times.name,
						rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.times.modal.form.name" }) }) }]
					})(
						<Input disabled={lockFormGsmUtm} />
					)}
				</Form.Item>

				<Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.times.modal.form.type" })}>
					{getFieldDecorator('obj_time-day', {
						initialValue: rows,
						rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.times.modal.form.type" }) }) }]
					})(
						<Select
							disabled={lockFormGsmUtm}
							showSearch
							placeholder="Select"
							optionFilterProp="children"
							mode="multiple"
							filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						>
							<Option value="0">{intl.formatMessage({ id: "objects.times.modal.form.sunday" })}</Option>
							<Option value="1">{intl.formatMessage({ id: "objects.times.modal.form.monday" })}</Option>
							<Option value="2">{intl.formatMessage({ id: "objects.times.modal.form.tuesday" })}</Option>
							<Option value="3">{intl.formatMessage({ id: "objects.times.modal.form.wednesday" })}</Option>
							<Option value="4">{intl.formatMessage({ id: "objects.times.modal.form.thursday" })}</Option>
							<Option value="5">{intl.formatMessage({ id: "objects.times.modal.form.friday" })}</Option>
							<Option value="6">{intl.formatMessage({ id: "objects.times.modal.form.saturday" })}</Option>
						</Select>
					)}
				</Form.Item>

				<Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.times.modal.form.item" })} style={{ marginBottom: 8, paddingBottom: 0 }}>
					<div style={{ display: 'flex' }}>
						<Form.Item {...formLayout}>
							{getFieldDecorator('obj_time_ini', {
								rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.times.modal.form.start.time" }) }) }]
							})(
								<TimePicker placeholder={intl.formatMessage({ id: "common.select.time.ini" })} disabled={lockFormGsmUtm} inputReadOnly={true} format="HH:mm" onChange={this.setHourIni} style={{ flex: 1, marginRight: 8 }} />
							)}
						</Form.Item>
						<Form.Item {...formLayout}>
							{getFieldDecorator('obj_time_end', {
								rules: [
									{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.times.modal.form.end.time" }) }) },
									{
										validator: (rule, value, cb) => {
											if (moment(getFieldValue('obj_time_ini'), "HH:mm").format("LT") == moment(getFieldValue('obj_time_end'), "HH:mm").format("LT")) {
												cb(intl.formatMessage({ id: "common.error.invalid" }, { name: intl.formatMessage({ id: "objects.times.modal.form.end.time" }) }));
											} else {
												cb()
											}
										}
									}
								]
							})(
								<TimePicker placeholder={intl.formatMessage({ id: "common.select.time.end" })} disabled={lockFormGsmUtm} inputReadOnly={true} format="HH:mm" onChange={this.setHourEnd} style={{ flex: 1, marginRight: 8 }} />
							)}
						</Form.Item>
						<button disabled={lockFormGsmUtm} style={{ height: 32 }} onClick={() => this.moveValueList(this.state.itemMove)} className="btn btn-sm" title={intl.formatMessage({ id: "common.add.to.list" })}><i className="fa fa-plus" /></button>
					</div>
				</Form.Item>

				<Form.Item {...formLayout} label="">
					<div style={{ display: 'flex' }}>
						<select disabled={lockFormGsmUtm} id="obj_time-list" style={{ marginRight: 8 }} className="ant-input" multiple={true}>
							{rows2.map(this.renderListTime)}
						</select>

						<button disabled={lockFormGsmUtm} onClick={() => this.removeValueList()} style={{ height: 32, marginTop: 20 }} className="btn btn-sm" title={intl.formatMessage({ id: "common.del.to.list" })}><i className="fa fa-minus" /></button>
					</div>
				</Form.Item>

				<Form.Item {...formLayoutLast} label={intl.formatMessage({ id: "objects.times.modal.form.description" })} >
					{getFieldDecorator('obj_time-desc', { initialValue: times.description })(
						<TextArea disabled={lockFormGsmUtm} />
					)}
				</Form.Item>
			</Form >
		</DraggableModal>
	}
}

TimesModal.defaultProps = {
	onCancel: null,
	visible: false,
	titlePrefix: '',
	times: {
		id: '',
		name: '',
		description: '',
		type: '',
		week_day: [],
		week_time: [],
	}
}

export default injectIntl(Form.create({})(TimesModal));