import React, { Component, Fragment } from "react";
import Axios from "axios";
import { DraggableModal } from "/bbui/components/";
import { Form, Input, Select, Button, message } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import { mime } from "/bbui/config/mime";
import { ajaxUrl } from "/bbui/config/constants";
import { product } from "/config/constants";
import "../Objects.css"
var _ = require('lodash');

const package_version = 'app';

class ContentsModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			list: {
				content: props.contents.content,
				extension: props.contents.extension,
			},
			lockForm: false,
			options: [],
			mimeSearch: [],
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.contents !== prevProps.contents) {
			this.setState({
				list: {
					content: this.props.contents.content,
					extension: this.props.contents.extension,
				}
			})
		}
	}

	handleSave = () => {
		this.setState({ lockForm: true });
		const { intl, form } = this.props;
		let fields = ["obj_mime-name", "obj_mime-desc"]

		var mimeList = document.getElementById("obj_mime-list");
		var mimeExtList = document.getElementById("obj_mime-ext-list");
		mimeList.classList.remove("ui-has-error");
		mimeExtList.classList.remove("ui-has-error");

		form.validateFields(fields, (err, values) => {
			if (mimeList.options.length == 0 && mimeExtList.options.length == 0) {
				this.setState({ lockForm: false });

				mimeList.classList.add("ui-has-error");
				mimeExtList.classList.add("ui-has-error");

				message.error(intl.formatMessage({ id: "objects.contents.modal.form.invalid" }));
				return
			} else {
				if (!err) {
					this.setState({ mime: '', ext: '', mimeSearch: [] });
					this.saveObj(form, values);
				} else {
					this.setState({ lockForm: false });
				}
			}


		});
	}

	handleCancel = () => {
		const { onCancel, form } = this.props;
		form.resetFields()
		this.setState({ mime: '', ext: '', mimeSearch: [] });
		let field_list = document.getElementById("obj_mime-list");
		if (field_list) field_list.classList.remove("ui-has-error");
		let field_ext = document.getElementById("obj_mime-ext-list");
		if (field_ext) field_ext.classList.remove("ui-has-error");
		onCancel();
	}

	addValueList = (value, idx) => {
		let { list } = this.state;
		var object = list[idx].some(p => p == value)

		if (object == false) {
			list[idx].push(_.cloneDeep(value));
		}

		this.setState({ list });

		let elementId = ""
		if (idx == "content")
			elementId = "obj_mime-list";

		if (idx == "extension")
			elementId = "obj_mime-ext-list";

		var element = document.getElementById(elementId);
		element.classList.remove("ui-has-error")
	}

	delValueList = (value, idx) => {
		let { list } = this.state;

		for (var i = 0; i < list[idx].length; i++) {
			if (list[idx][i] === value) {
				list[idx].splice(i, 1);
			}
		}

		this.setState({ list });
	}

	moveValueList = (value, idx) => {
		const { validateFields, resetFields } = this.props.form;
		let fields = []

		if (idx == "content")
			fields = ["obj_mime-list-mimetypes"]

		if (idx == "extension")
			fields = ["obj_mime-list-extensions"]

		validateFields(fields, (errors) => {
			if (!errors) {
				resetFields(fields);

				this.addValueList(value, idx);
				// this.setState({ mime: '', ext: '' });
			}
		});
	}

	removeValueList = (idx, k) => {
		let selectElement = document.getElementById(k)
		let values = Array.from(selectElement.selectedOptions).map(option => option.value)
		values.forEach(function (key, ind) {
			this.delValueList(key, idx);
		}, this);
	}

	renderListMime(row, idx) {
		return (<option key={idx}>{row}</option>)
	}

	renderListExt(row, idx) {
		return (<option key={idx}>{row}</option>)
	}

	handleSearch = value => {
		if (value) {
			let mimeSearch = mime.filter(m => m.includes(value)).slice(0, 50);
			if (mimeSearch.length == 0) {
				mimeSearch.push(value);
			}
			this.setState({ mimeSearch });
		} else {
			this.setState({ mimeSearch: [] });
		}
	}

	saveObj = (form, values) => {
		const { intl, contents } = this.props;

		const mime = [];
		var elementMime = document.getElementById("obj_mime-list");
		for (var i = 0; i < elementMime.options.length; i++) {
			mime.push(elementMime.options[i].value);
		}

		const ext = [];
		var elementExt = document.getElementById("obj_mime-ext-list");
		for (var i = 0; i < elementExt.options.length; i++) {
			ext.push(elementExt.options[i].value);
		}

		const bodyData = new FormData();
		bodyData.set("act", "saveMime");
		bodyData.set("obj_mime-name", values["obj_mime-name"]);
		bodyData.set("obj_mime-desc", values["obj_mime-desc"]);
		bodyData.set("obj_mime-list", JSON.stringify(mime));
		bodyData.set("obj_mime-ext-list", JSON.stringify(ext));

		if (contents.id !== '') {
			bodyData.set("obj_mime-id", contents.id);
		}

		Axios.post(ajaxUrl[package_version].objects, bodyData).then((res) => {
			this.setState({ lockForm: false });

			if (product == "GSM" && Array.isArray(res.data) && res.data.length == 2)
				if (res.data[1]['errorcode'] == '001') {
					form.resetFields();
					this.setState({ list: { content: [], extension: [] } })
					this.props.onCancel();
					elementMime.classList.remove("ui-has-error")
					elementExt.classList.remove("ui-has-error")
					return message.error(this.props.intl.formatMessage({ id: "common.dont.permission" }))
				}

			if (res.data === "Seu login não permite acesso a esta area." || res.data === "Your login does not allow access to this area.") {
				form.resetFields();
				this.setState({ list: { content: [], extension: [] } })
				this.props.onCancel();
				elementMime.classList.remove("ui-has-error")
				elementExt.classList.remove("ui-has-error")
				return message.error(this.props.intl.formatMessage({ id: "common.dont.permission.edit" }));
			}

			if (res.data[0].success == 1) {
				message.success(intl.formatMessage({ id: "objects.actions.create.success" }));
				form.resetFields();
				elementMime.classList.remove("ui-has-error")
				elementExt.classList.remove("ui-has-error")
				this.setState({ list: { content: [], extension: [] } })
				this.props.onOk();
			} else {
				res.data.forEach(erro => {
					form.setFields({
						[erro.field]: {
							value: values[erro.field],
							errors: [new Error(intl.formatMessage({ id: "common.invalid.value" }))],
						},
					});

					if (erro.field == "obj_mime-list[]") {
						elementMime.classList.add("ui-has-error")
					}
					if (erro.field == "obj_mime-ext-list[]") {
						elementExt.classList.add("ui-has-error")
					}

					if (res.data[0]['exists']) {
						form.setFields({
							'obj_mime-name': {
								value: form.getFieldValue('obj_mime-name'),
								errors: [new Error(this.props.intl.formatMessage({ id: "common.alreadyExists" }))]
							}
						})
					}
					message.error(intl.formatMessage({ id: "objects.actions.create.error" }));
				})
			}

		})
	}

	render() {
		let { intl, contents, visible, titlePrefix } = this.props;
		const { getFieldDecorator, getFieldValue } = this.props.form;
		const { mimeSearch, lockForm } = this.state;
		const { TextArea } = Input;
		const Option = Select.Option;
		const formLayout = { colon: false, style: { paddingBottom: 0 } };
		const formLayoutLast = { colon: false, style: { paddingBottom: 0, marginBottom: 0 } };
		const mimeOptions = mimeSearch.map((m, idx) => <Option key={m}>{m}</Option>);

		titlePrefix = titlePrefix ? titlePrefix : intl.formatMessage({id: 'common.create'});

		let { list: { content: rows, extension: rows2 } } = this.state;

		//Object GSM disabled
		const lockFormGsmUtm = this.props.contents.gsmid ? true : lockForm;
		const controleLoadingButton = this.props.contents.gsmid ? false : lockForm;

		const footerActions = [
				<Button key="cancel" onClick={this.handleCancel}>
					<FormattedMessage id="common.cancel" />
				</Button>,
				<Button loading={controleLoadingButton} disabled={lockFormGsmUtm} key="save" type="primary" onClick={this.handleSave}>
					<FormattedMessage id="common.save" />
				</Button>
		]

		return <DraggableModal
			onOk={this.handleSave}
			onCancel={this.handleCancel}
			confirmLoading={lockFormGsmUtm}
			visible={visible}
			title={<Fragment>{titlePrefix}<FormattedMessage id="objects.modal.title" values={{ object: "Contents" }} /></Fragment>}
			okText={intl.formatMessage({ id: "common.save" })}
			cancelText={intl.formatMessage({ id: "common.cancel" })}
			footer={footerActions}>
			<Form layout="vertical">
				<Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.contents.modal.form.name" })} >
					{getFieldDecorator('obj_mime-name', {
						initialValue: contents.name,
						rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.contents.modal.form.name" }) }) }]
					})(
						<Input disabled={lockFormGsmUtm} />
					)}
				</Form.Item>

				<Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.contents.modal.form.listMime" })} style={{ marginBottom: 8, paddingBottom: 0 }}>
					<div style={{ display: 'flex' }}>
						{getFieldDecorator('obj_mime-list-mimetypes', {
							initialValue: '',
							value: mime,
							rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.contents.modal.form.listMime" }) }) }]
						})(
							<Select disabled={lockFormGsmUtm} showSearch notFoundContent={null} onSearch={this.handleSearch} onChange={value => this.setState({ mime: value })} style={{ marginRight: 8 }}>
								{mimeOptions}
							</Select>
						)}
						<button disabled={lockFormGsmUtm} onClick={() => this.moveValueList(getFieldValue('obj_mime-list-mimetypes'), 'content')} className="btn btn-sm" title={intl.formatMessage({ id: "common.add.to.list" })}><i className="fa fa-plus" /></button>
					</div>
				</Form.Item>

				<Form.Item {...formLayout} label="">
					<div style={{ display: 'flex' }}>
						<select disabled={lockFormGsmUtm} id="obj_mime-list" style={{ marginRight: 8 }} className="ant-input" multiple>
							{rows.map((row, idx) => this.renderListMime(row, idx))}
						</select>
						<button disabled={lockFormGsmUtm} onClick={() => this.removeValueList('content', 'obj_mime-list')} style={{ height: 32, marginTop: 20 }} className="btn btn-sm" title={intl.formatMessage({ id: "common.del.to.list" })}><i className="fa fa-minus" /></button>
					</div>
				</Form.Item>

				<Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.contents.modal.form.listExt" })} style={{ marginBottom: 8, paddingBottom: 0 }}>
					<div style={{ display: 'flex' }}>
						{getFieldDecorator('obj_mime-list-extensions', {
							initialValue: '',
							rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.contents.modal.form.listExt" }) }) }]
						})(
							<Input disabled={lockFormGsmUtm} style={{ marginRight: 8 }} />
						)}
						<button disabled={lockFormGsmUtm} onClick={() => this.moveValueList(getFieldValue('obj_mime-list-extensions'), 'extension')} className="btn btn-sm" title={intl.formatMessage({ id: "common.add.to.list" })}><i className="fa fa-plus" /></button>
					</div>
				</Form.Item>

				<Form.Item {...formLayout} label="">
					<div style={{ display: 'flex' }}>
						<select disabled={lockFormGsmUtm} id="obj_mime-ext-list" style={{ marginRight: 8 }} className="ant-input" multiple>
							{rows2.map((row, idx) => this.renderListExt(row, idx))}
						</select>
						<button disabled={lockFormGsmUtm} onClick={() => this.removeValueList('extension', 'obj_mime-ext-list')} style={{ height: 32, marginTop: 20 }} className="btn btn-sm" title={intl.formatMessage({ id: "common.del.to.list" })}><i className="fa fa-minus" /></button>
					</div>
				</Form.Item>

				<Form.Item {...formLayoutLast} label={intl.formatMessage({ id: "objects.contents.modal.form.description" })} >
					{getFieldDecorator('obj_mime-desc', { initialValue: contents.description })(
						<TextArea disabled={lockFormGsmUtm} />
					)}
				</Form.Item>
			</Form >
		</DraggableModal>
	}
}

ContentsModal.defaultProps = {
	onCancel: null,
	visible: false,
	titlePrefix: '',
	contents: {
		id: '',
		name: '',
		description: '',
		extension: [],
		content: [],
		type: '',
		used: 0
	}
}

export default injectIntl(Form.create({})(ContentsModal));