/**
 * THIS COMPONENT IS NOT INTENDED TO BE IMPORTED
 * 
 * FormCore is used by other components such as FormModal and FormHandler, it's responsible
 * for rendering all the fields of the form and the form element. The callback will call the
 * parent component to handle the submit action
 * 
 * @param { object } form The form object used by the AntDesign to validate and gather the values of the form
 * @param { object } layout The fields layout, it's applied to all of them
 * @param { array } fields Array of fields that will be rendered, it must provide the field name, rules and other specifications
 * @param { boolean } noFormWrap If the form must be warped in a Form tag 
 * 
 */

import React, { Component, Fragment } from "react";
import { Card, Button, Checkbox, Col, DatePicker, Form, Icon, Input, InputNumber, Row, Select, TimePicker, Upload, Tooltip } from "antd";
import AddList from "./AddList";
import MaskedInput from "react-maskedinput";
import FieldModal from "./FieldModal";

class FormCore extends Component {
  componentDidMount() {
    // Focus on the first not hidden field
    const { form } = this.props;
    let fields = Object.keys(form.getFieldsValue());
    let instances = fields.map(f => form.getFieldInstance(f)).filter(i => i)
    if (instances.length > 0) instances[0].focus();
  }
  onSubmit = (e) => {
    if (e) e.preventDefault();
    this.props.onSubmit();
  }
  renderCheckboxField = (field, lockForm) => {
    if (field.type == "row") {
      return this.renderFields({ dependsOn: field.name, ...field.children }, lockForm);
    }

    return this.renderField({ dependsOn: field.name, ...field.child }, lockForm)
  }
  renderOptions(options) {
    if (Array.isArray(options)) {
      return options.map((opt) => {
        const { value, text } = opt;
        return <Select.Option value={value} key={value}>{text}</Select.Option>
      });
    } else if (typeof (options) == "object") {
      return Object.keys(options).map(o => {
        let opt = options[o]
        return <Select.OptGroup key={opt.value} label={opt.text}>
          {this.renderOptions(opt.children)}
        </Select.OptGroup>
      })
    }
  }
  renderField(field, lockForm) {
    const { formConfig, form } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { layout } = this.props.formConfig;
    let fieldLock = {};
    if (lockForm && field.type != "button")
      fieldLock = { disabled: true };
    // fieldLock = { hasFeedback: true, disabled: true, validateStatus: "validating" };

    if (field.type == "text" && !("maxLength" in field)) {
      field.maxLength = 100;
      console.warn("[BB-UI] Field ", field.name, " has no maxLength defined, assumed the value of 100");
    }
    if (field.type == "hidden") getFieldDecorator(field.name, { initialValue: field.initialValue })

    if (field.type == "checkboxField") {
      if ("initialValue" in field.child && field.child.initialValue != null && field.child.initialValue.length > 0) {
        if (!("extra" in field))
          field.extra = {}
      }
    }

    /* To ignore any disabled field when the form is submitted */
    let hidden = false;
    if ("dependsOn" in field) {
      if ("dependsValue" in field) {
        field.disabled = form.getFieldValue(field.dependsOn) != field.dependsValue;
      } else {
        field.disabled = !form.getFieldValue(field.dependsOn);
      }
      if (field.disabled) {
        hidden = true;
      }
    }

    if ("requiredOn" in field) {
      if (Array.isArray(field.rules)) {
        field.rules.forEach(rule => {
          if ('required' in rule) rule.required = form.getFieldValue(field.requiredOn);
        })
      }
    }

    if ("requiredIfEmptyOn" in field) {
      if (Array.isArray(field.rules)) {
        field.rules.forEach(rule => {
          if ('required' in rule) rule.required = !form.getFieldValue(field.requiredIfEmptyOn);
        })
      }
    }

    return <Fragment key={field.name}>{(field.type != "checkbox" && field.type != "hidden") &&
      <Form.Item
        colon={false}
        {...fieldLock}
        {...layout}
        {...field.formItemExtra}
        key={field.name}
        label={field.type != "checkbox_block" && field.type != "checkboxField" && field.label}>
        {field.type == "text" && !("mask" in field) && getFieldDecorator(field.name, { hidden, initialValue: field.initialValue, rules: field.rules })(<Input {...field.extra} placeholder={field.placeholder} maxLength={field.maxLength} readOnly={field.readOnly} disabled={lockForm || field.disabled} />)}
        {field.type == "text" && ("mask" in field) && getFieldDecorator(field.name, { hidden, initialValue: field.initialValue, rules: field.rules })(<MaskedInput {...field.extra} maxLength={field.maxLength} className="ant-input" mask={field.mask} disabled={lockForm || field.disabled} />)}
        {field.type == "button" && <Tooltip {...field.tooltip}> <Button {...field.extra} disabled={lockForm || field.disabled} type={field.primary ? "primary" : ""} onClick={field.onClick}>{field.content}</Button> </Tooltip>}
        {field.type == "textarea" && getFieldDecorator(field.name, { hidden, initialValue: field.initialValue, rules: field.rules })(<Input.TextArea {...field.extra} maxLength={field.maxLength} disabled={lockForm || field.disabled} rows={field.rows} readOnly={field.readOnly} />)}
        {field.type == "password" && getFieldDecorator(field.name, { hidden, initialValue: field.initialValue, rules: field.rules })(<Input.Password {...field.extra} disabled={lockForm || field.disabled} />)}
        {field.type == "number" && getFieldDecorator(field.name, { hidden, initialValue: field.initialValue, rules: field.rules })(<InputNumber placeholder={field.placeholder} {...field.extra} disabled={lockForm || field.disabled} min={field.min} max={field.max} />)}
        {field.type == "select" && getFieldDecorator(field.name, { hidden, ...( field.initialValue ? { initialValue: field.initialValue } : undefined ), rules: field.rules })(<Select {...field.extra} mode={field.mode} allowClear={field.allowClear} disabled={lockForm || field.disabled} placeholder={field.placeholder} showSearch={field.showSearch} filterOption={field.filterOption} >{this.renderOptions(field.options)}</Select>)}
        {field.type == "rangePicker" && getFieldDecorator(field.name, { hidden, initialValue: field.initialValue, rules: field.rules })(<DatePicker.RangePicker {...field.extra} allowClear={false} format="DD/MM/YYYY" style={{ width: 100 + '%' }} />)}
        {field.type == "addList" && getFieldDecorator(field.name, { hidden, initialValue: field.initialValue, rules: field.rules })(<AddList />)}
        {field.type == "timepicker" && getFieldDecorator(field.name, { hidden, initialValue: field.initialValue, rules: field.rules })(<TimePicker {...field.extra} disabled={lockForm || field.disabled} style={{ width: "100%" }} defaultOpenValue={field.defaultOpenValue} format={field.format} />)}
        {field.type == "checkbox_block" && getFieldDecorator(field.name, { valuePropName: 'checked', initialValue: field.initialValue, rules: field.rules })(<Checkbox {...field.extra}>{field.label}</Checkbox>)}
        {field.type == "upload" && getFieldDecorator(field.name, { hidden, initialValue: field.initialValue, rules: field.rules })(<Upload {...field.props} disabled={lockForm || field.disabled}><Button disabled={lockForm || field.disabled}><Icon type="upload" /> {field.content ? field.content : field.label}</Button></Upload>)}
        {field.type == "fieldModal" && getFieldDecorator(field.name, { hidden, initialValue: field.initialValue, rules: [{ type: "array", ...field.rules }] })(<FieldModal name={field.name} dataSourceAxios={field.dataSourceAxios} disabled={field.disabled} dtKey={field.dtKey} dataSource={field.dataSource} initialValue={field.initialValue} onChange={field.onChange} title={field.title} />)}
        {field.type == "checkboxField" && getFieldDecorator(field.name, { valuePropName: 'checked', initialValue: field.initialValue, rules: field.rules })(<div className="bbui-checkboxField"><Checkbox disabled={field.disabled} defaultChecked={(field.initialValue) ? true : false} {...field.extra}>{field.label}</Checkbox>{this.renderCheckboxField(field, lockForm)}</div>)}
        {field.type == "datePicker" && getFieldDecorator(field.name, { hidden, rules: field.rules, initialValue: field.initialValue })(<DatePicker {...field.extra} style={{ width: 100 + '%' }} />)}
      </Form.Item>}
      {(field.type == "checkbox" && formConfig.layout.wrapperCol) ?
        <Row>
          <Col key={field.name} span={formConfig.layout.wrapperCol.span} offset={formConfig.layout.labelCol.span}>
            {getFieldDecorator(field.name, { valuePropName: 'checked', initialValue: field.initialValue, rules: field.rules })(<Checkbox>{field.label}</Checkbox>)}
          </Col>
        </Row>
        : field.type == "checkbox" &&
        <Form.Item
          colon={false}
          {...fieldLock}
          {...layout}
          {...field.formItemExtra}
          key={field.name}>
          {getFieldDecorator(field.name, { valuePropName: 'checked', initialValue: Boolean(field.initialValue), rules: field.rules })(<Checkbox onChange={field.onChange} {...field.extra} disabled={lockForm || field.disabled}>{field.label}</Checkbox>)}
        </Form.Item>
      }

    </Fragment>;

  }
  renderFields(fields, useCol = false) {
    const { lockForm, loading, visible } = this.props;

    if ((fields == undefined) || (fields.length == 0)) {
      console.error("No fields specified in Form");
      return <div />;
    }

    const { errors, layout } = this.props.formConfig;

    /** The field decorator is repeated multiple times because the fields tags (passed as second argument )
      * cannot be wrapped in any other tags, otherwise will not pass the values to the form object */
    return fields.map((field, idx) => {
      let cssStyle = {};
      const { dataSource } = this.props;
      if (!visible) cssStyle = { display: "none" };

      if (!("type" in field)) {
        console.error(`Field ${field} type not specified, skipping`);
      }

      if ((field.type == "card") && ("children" in field)) {
        if ("span" in field) {
          return <Col span={field.span}>
            <Card key={idx} style={{ marginBottom: 12 }} size="small" {...field.options} title={field.title}>
              {this.renderFields(field.children, false)}
            </Card>
          </Col>
        }
        return <Card key={idx} style={{ marginBottom: 12 }} size="small" {...field.options} title={field.title}>
          {this.renderFields(field.children, false)}
        </Card>
      }

      if ((field.type == "row") && ("children" in field)) {
        return <Row key={idx} gutter={field.gutter || 16} {...field.options}>
          {this.renderFields(field.children, true)}
        </Row>
      }

      if ((field.type == "col") && ("children" in field)) {
        return <Col key={idx} span={field.span || 24}{...field.options}>
          {this.renderFields(field.children, false)}
        </Col>
      }

      if ((field.type == "div") && ("children" in field)) {
        return <div key={idx} {...field.options}>{this.renderFields(field.children, false)} </div>
      }

      if (dataSource != null && (field.name in dataSource)) {
        field.initialValue = dataSource[field.name];
      } else if (field.type == "checkboxField" && field.child.name in dataSource && dataSource[field.child.name]) {
        if ((Array.isArray(dataSource[field.child.name]) && dataSource[field.child.name].length > 0) ||
          !Array.isArray(dataSource[field.child.name]) && dataSource[field.child.name] != null) {
          field.child.initialValue = dataSource[field.child.name];
          field.initialValue = (dataSource[field.child.name]) ? true : false;
        }
      } else if (field.initialValue == undefined) {
        field.initialValue = "";
      }

      if (field.type == "subtitle") {
        return <Form.Item key={idx} colon={false} {...layout} label={<span />}><h4>{field.label}</h4></Form.Item>;
      }

      if (field.type == "label") {
        return <Form.Item key={idx} colon={false} {...layout} label={field.label}></Form.Item>;
      }

      if (field.type == "hidden") {
        return this.renderField(field, (lockForm || loading));
      }

      if (useCol) {
        return <Col key={idx} style={cssStyle} span={field.span || 24}>{this.renderField(field, (lockForm || loading))}</Col>;
      } else {
        return <span key={idx} style={cssStyle}>{this.renderField(field, (lockForm || loading))}</span>;
      }

      // OLD CODE WAITING TO BE REMOVED
      // if (!layout || !("layout" in layout) || layout.layout != "inline") {
      //   if (field.type == "hidden") {
      //     return this.renderField(field, (lockForm || loading));
      //   } else {
      //     return <span key={field.name} style={cssStyle}>
      //       {this.renderField(field, (lockForm || loading))}
      //     </span>;
      //   }
      // } else {
      //   return <Col key={field.name} span={field.span}>{this.renderField(field, (lockForm || loading))}</Col>;
      // }
    });
  }
  renderButtons() {
    const { buttons, visible } = this.props;
    let cssStyle = { float: "right", marginTop: "20px" };
    if (!visible) cssStyle = { display: "none", ...cssStyle };

    return <Button.Group style={cssStyle}>{buttons.map((button, index) => {
      if (!("onClick" in button)) {
        button.onClick = null;
      }

      return <Button
        key={`button_${index}`}
        type={button.type}
        size={button.size}
        onClick={button.onClick}
        htmlType={button.submit ? "submit" : "button"}>{button.content}</Button>
    })}
    </Button.Group>;
  }
  render() {
    const { header, buttons, className, formConfig, noFormWrap } = this.props;

    if (noFormWrap)
      return this.renderFields(formConfig.fields);

    return <Form className={className} onSubmit={this.onSubmit} {...formConfig.layout}>
      {header}
      {this.renderFields(formConfig.fields)}
      {buttons.length > 0 && this.renderButtons()}
    </Form>
  }
}

FormCore.defaultProps = {
  buttons: [],
  className: "",
  dataSource: null,
  header: [],
  formConfig: { fields: [], layout: { colon: false } },
  formLayout: { colon: false },
  loading: false,
  lockForm: false,
  noFormWrap: false,
  onSubmit: null,
  visible: true
}

export default FormCore;