import React, { Component, PureComponent, Fragment } from "react";
import { injectIntl } from "react-intl";
class CustomBrandingPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hover: false
    }

  }

  toggleHover = () => this.setState({ hover: !this.state.hover })

  render() {
    const { color, intl } = this.props

    var linkStyle;
    if (this.state.hover) {
      linkStyle = { color: color.menu_open_hover_color }
    } else {
      linkStyle = { color: color.menu_open_color }
    }

    return <Fragment>
      {this.props.product == "UTM" && <div id="root">
        <div className={"skin-black pace-done"}>
          <header className="header" style={{ position: "relative", background: "url(/img/barra.jpg) no-repeat", height: 84 }}>
            <div className="page_title">
              <img src={this.props.favicon} style={{ width: 16, height: 16, marginRight: 10 }} />{this.props.title}</div>
            <div style={{ height: 50, paddingTop: 15, paddingLeft: 180, fontSize: 16 }}>{window.location.href}</div>
          </header>
          <header className="header" style={{ position: "relative", borderBottom: `15px solid ${color.menu_selected_bg_color}` }}>
            <a href={"#"} className="logo" style={{ marginLeft: 15 }}>
              <img style={{ height: 38, marginBottom: 11 }} src={this.props.logo} />
            </a>
          </header>
          <div className="row-offcanvas row-offcanvas-left" style={{ minHeight: 150 }}>
            <aside className="" style={{ minHeight: 150, width: 220 }}>
              <div className="slimScrollDiv" style={{ position: "relative", overflow: "hidden", width: "auto", height: 232 }}>
                <section className="sidebar" style={{ overflow: "hidden", width: "auto", height: 232, background: color.menu_base_bg_color }}>
                  <div className="sidebar-menu-item-title" title={"Network Security"} style={{ height: 44, background: color.menu_section_bg_color, color: color.menu_section_color, fontWeight: "bold", listStyle: "none", textTransform: "uppercase", padding: "12px 5px 12px 15px" }}>Network Security</div>
                  <ul className="sidebar-menu">
                    <li className="treeview menu-overview active">
                      <a href={"#"} style={{ background: color.menu_selected_bg_color, color: color.menu_selected_color }}>
                        <i className="fa fa-dashboard"></i> <span>Monitor</span>
                        <i className="fa pull-right fa-angle-down"></i>
                      </a>
                      <ul className="treeview-menu" style={{ display: "block", background: color.menu_open_bg_color }}>
                        <li><a href={"#"} onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover} style={{ ...linkStyle }}><i className="fa fa-angle-double-right"></i> Dashboard</a></li>
                        <li><a href={"#"} onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover} style={{ ...linkStyle }}><i className="fa fa-angle-double-right"></i> Live Sessions</a></li>
                      </ul>
                    </li>
                    <li className="treeview menu-overview">
                      <a href={"#"} style={{ background: color.menu_bg_color, color: color.menu_color }}>
                        <i className="fa fa-line-chart"></i> <span>Analyzer</span>
                        <i className="fa pull-right fa-angle-down"></i>
                      </a>
                    </li>
                    {/* <li className="treeview menu-overview">
                      <a href={"#"} style={{ background: color.menu_bg_color, color: color.menu_color }}>
                        <i className="fa fa-tags"></i> <span>{intl.formatMessage({ id: "sidebar.management.policies" })}</span>
                        <i className="fa pull-right fa-angle-down"></i>
                      </a>
                    </li> */}
                  </ul>
                </section>
              </div>
            </aside>
            <aside></aside>
          </div>
        </div>
      </div>}
      {this.props.product == "GSM" && <div className={"skin-black pace-done"}>
        <header style={{ position: "relative", background: "url(/img/barra.jpg) no-repeat", height: 84 }}>
          <div className="page_title">
            <img src={this.props.favicon} style={{ width: 16, height: 16, marginRight: 10 }} />
            {this.props.title}
          </div>
          <div style={{ height: 50, paddingTop: 15, paddingLeft: 180, fontSize: 16, overflow: "hidden", textOverflow: "clip" }}>{window.location.href}</div>
        </header>
        <header style={{ position: "relative", borderBottom: `15px solid ${color.menu_selected_bg_color}` }}>
          <a href={"#"} className="logo" style={{ marginLeft: 15 }}>
            <img style={{ height: 38, marginBottom: 11 }} src={this.props.logo} />
          </a>
        </header>
        <div className="row-offcanvas row-offcanvas-left" style={{ minHeight: 150 }}>
          <aside className="" style={{ minHeight: 170, width: 220, background: color.menu_base_bg_color }}>
            <section className="sidebar" style={{ overflow: "hidden", width: "auto", height: 150 }}>
              <ul className="ant-menu sidebar-menu-sections ant-menu-light ant-menu-root ant-menu-vertical">
                <li className=" ant-menu-item-group">
                  <div className="ant-menu-item-group-title" style={{ background: color.menu_section_bg_color, color: color.menu_section_color }}>
                    {intl.formatMessage({ id: "sidebar.management" })}
                  </div>
                  <ul className="ant-menu-item-group-list">
                    <li style={{ backgroundColor: color.menu_selected_bg_color, color: color.menu_selected_color }} className="ant-menu-item sidebar-menu-item" role="menuitem">
                      <i className="fa fa-server"></i>{intl.formatMessage({ id: "sidebar.management.devices" })}
                    </li>
                    <li className="ant-menu-item sidebar-menu-item" role="menuitem" style={{ background: color.menu_bg_color, color: color.menu_color }}><i className="fa fa-tags"></i>
                      {intl.formatMessage({ id: "sidebar.management.policies" })}
                    </li>
                  </ul>
                </li>
              </ul>
            </section>
          </aside>
          <aside></aside>
        </div>
      </div>}
    </Fragment>
  }
}
CustomBrandingPreview.defaultProps = {
  logo: "/public/branding/default/img/logo.svg",
  favicon: "/public/branding/default/img/favicon.png",
  title: "BLOCKBIT | UTM",
  color: {
    menu_section_bg_color: "#999999",
    menu_section_color: "#FFFFFF",
    menu_selected_bg_color: "#3C7C9F",
    menu_selected_color: "#FFFFFF",
    menu_open_bg_color: "#356B89",
    menu_open_color: "#CCCCCC",
    menu_open_hover_color: "#FFFFFF", // fazzer
    menu_bg_color: "#E9E9E9",
    menu_color: "#4E4E4E",
    menu_base_bg_color: "#E9E9E9",
  }
}
export default injectIntl(CustomBrandingPreview);