import React, { Component, Fragment } from "react";
import { Icon, Tabs } from "antd";

/**
 * Component to render the tabs and its respectives contents
 * 
 * @param {boolean} bottomSpacing If the bottom of the tab content is spaced and has a box-shadow
 * @param {object} history React router dom router used to perform the URL change on click of the tab
 * @param {function} onChange Callback on click the tab
 * @param {array} panes The panes of the tab structure, containing the URL, name and content
 * @param {string} tabPosition Position of the tabs (top, left, bottom and right)
 * @example { url: "/path/to/tab", name: "Tab Name", children: <h1>My Tab Content</h1> }
 */

class Tab extends Component {

  constructor(props) {
    super(props);
    this.state = {
      defaultKey: !!this.props.defaultKey ? this.props.defaultKey : false
    }
  }

  onTabClick = (e, data, key, url) => {
    e.preventDefault();
    const { history } = this.props;
    history.push(url);
    data.onTabClick(key);
  }
  renderTopTabBar = (data, panes) => {
    const { activeKey, panels } = data;
    return <ul className="nav nav-tabs">
      {panels.map((pane, index) => {
        const paneUrl = panes[index].url;
        const error = ("error" in pane) ? "error" : "";
        const active = (this.state.defaultKey == 'VPN' && this.state.defaultKey == pane.props.tab) ? "active" : (pane.props.tab == activeKey) ? "active" : "";
        return <li key={pane.key} className={(active + error)}>
          <a onClick={(e) => this.onTabClick(e, data, pane.key, paneUrl)} href={paneUrl}>{pane.props.tab}</a>
        </li>;
      })}
    </ul>;
  }
  renderTab = (name, error) => {
    if (error)
      return <span className='ui-tab-error'><Icon type='warning' /> {name}</span>
    return <span>{name}</span>
  }
  render() {
    const TabPane = Tabs.TabPane;
    const { bottomSpacing, history, onChange, panes, tabPosition, type, noBox, destroyInactiveTabPane } = this.props;

    let currentPath = "";
    if (history != null)
      currentPath = history.location.pathname;

    /* 
      Error: 
      - URL não abre TAB correspondente.
      
      Observação: 
      - Quando acessado um URL, sempre abria a primeira TAB, independente do URL acessado.
      - Isso fica evidente ao observar que sempre o método FIND retornava sempre o objeto DEFAULT.

      Melhoria: 
      - Verificar a URL acessada e selecionar a TAB correspondete

      Abrangência: 
      - Todo o sistema quando utilizado o componet TAB do BBUI.

      Alteração: 
      - let defaultKey = panes.find((item) => { return (item.default == true) })
      + let defaultKey = panes.find((item) => { return (item.url == currentPath) })

      Data: 12/01/2020
      Autor: Fabio Dias
    */

    // tratamento para titulo de tab quando tem html
    // let defaultKey = panes.find((item) => { return (item.default == true) })
    let defaultKey = panes.find((item) => { return (item.url == currentPath) })
    
    if (typeof defaultKey != "undefined")
      defaultKey = typeof defaultKey.name == "object" ? defaultKey.name.props.children[1] : defaultKey.name;

    if (!defaultKey) {
      let activeKey = panes.find((item) => { return currentPath.startsWith(item.url); });
      if (activeKey)
        defaultKey = activeKey.name;
    }

    let renderCall = null;
    if (tabPosition == "top") {
      renderCall = (data) => this.renderTopTabBar(data, panes)
    }

    return <Tabs
      defaultActiveKey={this.state.defaultKey}
      onChange={onChange}
      type={type}
      tabPosition={tabPosition}
      destroyInactiveTabPane={destroyInactiveTabPane}
      renderTabBar={renderCall}>
      {panes.map((pane) => {
        let classes = "";
        if (!bottomSpacing)
          classes += " no-bottom";
        if (type != "card")
          classes += " no-shadow";
        if (!noBox)
          classes += " box box-solid";

        return <TabPane disabled={pane.disabled} tab={pane.name} key={typeof pane.name == "object" ? pane.name.props.children[1] : pane.name} forceRender={this.props.forceRender}>
          <div className={classes}>{pane.children}</div>
        </TabPane>;
      })}
    </Tabs>;
  }
}

Tab.defaultProps = {
  destroyInactiveTabPane: false,
  bottomSpacing: true,
  disabled: false,
  noBox: false,
  forceRender: false,
  history: null,
  onChange: null,
  panes: [],
  tabPosition: "top",
  type: "card"
};

export default Tab;