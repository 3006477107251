import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import PackageInstallModal from "./packageInstallModal";
import Axios from "axios";
import { Badge, Icon, Menu } from "antd";
import ProfileModal from "/modules/profileModal";
import UserProfile from '/modules/UserProfile';

var intervalID = 0;
class navBar extends Component{
  constructor(props){
    super(props);

    this.state = {
      showPackageModal: false,
      showDeployMenu: false,
      showUserMenu: false,
      deploySubmenuValues: {
        "accept": 0,
        "running":0,
        "pending":0,
        "withError":0,
      },
    };

    this.updateDeployData();
    intervalID = setInterval(this.updateDeployData, 1000*30);
  }
  componentWillUnmount() {
    clearInterval(intervalID);
  }
  toggleUserMenu = () => {
    this.setState({
      showPackageModal: false,
      showUserMenu: !this.state.showUserMenu,
      showDeployMenu: false
    })
  }
  togglePackageModal = () => {
    this.setState({
      showPackageModal: !this.state.showPackageModal,
      showUserMenu: false,
      showDeployMenu: false
    })
  }
  onHideSidebar = () => {
    this.props.onHideSidebar();
  }
  updateDeployData = () => {
    let { deploySubmenuValues } = this.state;
    let formData = new FormData();
    formData.set("act", "getTasks");
    formData.set("page", "0");
    formData.set("task_status", "all");
    formData.set("type", "navBar");
    Axios.post("/ajax/deploys.php", formData).then((res) => {
      // Atualiza valores do submenu deploy
      deploySubmenuValues = res.data[0];
      this.setState({deploySubmenuValues});
    });
  }
  handleDeployMenuClick = (e) => {
    const { history } = this.props;
    history.push("/deploys");
  }
  handleLogout = () => {
    const { history } = this.props;
    let isSaml = UserProfile.getSaml();
    if(isSaml == "true"){
      window.open("/ajax/login.php?act=logout", "_self")
      localStorage.removeItem('username')
      localStorage.removeItem('useremail')
      localStorage.removeItem('saml')
    }else{
      Axios.get("/ajax/login.php?act=logout").then(() => {
        this.props.onLogout();
        localStorage.removeItem('username')
        localStorage.removeItem('useremail')
      });
    }
  }

  renderUserMenu(){
    const MenuItemGroup = Menu.ItemGroup;
    return <MenuItemGroup title={<div className="submenu-title-wrapper" style={{ paddingBottom: "10px" }}>{UserProfile.getName()}<br />&lt;{UserProfile.getEmail()}&gt;</div>}>
      <Menu.Divider style={{ marginBottom: "10px" }} />
      <Menu.Item key="profile" onClick={ this.toggleUserMenu }><Icon type="profile" /> Profile</Menu.Item>
      <Menu.Item key="logout" onClick={ this.handleLogout }><Icon type="logout" /> Logout</Menu.Item>
    </MenuItemGroup>;
  }
  renderDeployMenu(){
    let { deploySubmenuValues } = this.state;
    const typesOfDeploy = [ "accept", "running", "pending", "withError"];
    const MenuItemGroup = Menu.ItemGroup;
    return <MenuItemGroup>
        { typesOfDeploy.map((item) => {
          return <Menu.Item key={item}>
              {deploySubmenuValues[item]} <FormattedMessage id="navbar.deploy" /> <FormattedMessage id={`navbar.deploy.${item}`} />
            </Menu.Item>;
          })
        }
      <Menu.Item key="deploys">
        <FormattedMessage id="navbar.deploy.deploysPanel" />
      </Menu.Item>
    </MenuItemGroup>;
  }
  render(){
    const image = require("/public/branding/img/logo.svg");
    const { showPackageModal, showUserMenu } = this.state;
    const SubMenu = Menu.SubMenu;

    var alt = "BLOCKBIT GSM";
    var title = "Powered by BLOCKBIT";
    const custom = window.localStorage.getItem('customBranding');
    if (custom == 1) {
      alt = "GSM";
      title = "GSM";
    }

    return <header className="header">
      <Link to="/" className="logo" style={{lineHeight: '40px'}}><img style={{ width: 175, height: 38 }} src={image} alt={alt} title={title}/></Link>
      <nav className="navbar navbar-static-top">
        <a href="#" onClick={this.onHideSidebar} className="navbar-btn sidebar-toggle">
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </a>
          <div className="navbar-right">
            <Menu mode="horizontal" selectable={false}>
              <Menu.Item key="installPackage" onClick={ this.togglePackageModal }><i className="fa fa-magic"></i></Menu.Item>
              <SubMenu onClick={ this.handleDeployMenuClick } title={
                <Badge count={this.state.deploySubmenuValues.withError} style={{marginTop: -5, marginRight: -5}}><span className="submenu-title-wrapper"><i className="fa fa-tasks"></i></span></Badge>
              }>
                { this.renderDeployMenu() }
              </SubMenu>
              <SubMenu title={<i className="glyphicon glyphicon-user"></i>}>
                { this.renderUserMenu() }
              </SubMenu>
            </Menu>
            
          </div>
        </nav>
      { showPackageModal && <PackageInstallModal onClose={ this.togglePackageModal } /> }
      <ProfileModal onLogout={ this.handleLogout } visible={ showUserMenu } onClose={ this.toggleUserMenu } />
    </header>
  }
}

export default navBar;
