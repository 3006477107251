import React, { Component, Fragment } from "react";
import Axios from "axios";
import { DraggableModal } from "/bbui/components/";
import { Form, Input, message, Upload, Button, Tooltip } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import { ajaxUrl } from "/bbui/config/constants";
import { product } from "/config/constants";
import "../Objects.css"
var _ = require('lodash');

const package_version = 'app';

class DictionariesModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lockForm: false,
			message: null,
			name: '',
			regex: '',
			list: props.dictionaries.word_match,
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.dictionaries !== prevProps.dictionaries) {
			this.setState({
				list: this.props.dictionaries.word_match,
			})
		}
	}

	clearSelected = () => {
		let selectElement = document.getElementById('obj_word-field[]')
		var elements = selectElement.options;

		for (var i = 0; i < elements.length; i++) {
			elements[i].selected = false;
		}
	}

	handleSave = () => {
		this.setState({ lockForm: true });

		const { intl, form } = this.props;
		let fields = ["obj_word-name", "obj_word-desc"]
		let invalid = false;

		var list = document.getElementById("obj_word-list");
		if (list.options.length == 0)
			invalid = true


		form.validateFields(fields, (err, values) => {
			if (invalid === true) {
				this.setState({ lockForm: false });
				message.error(intl.formatMessage({ id: "objects.dictionaries.modal.form.invalid" }));
				list.classList.add("ui-has-error");
				return
			} else {
				if (!err) {
					this.saveObj(form, values);
				} else {
					this.setState({ lockForm: false });
				}
			}
		});
	}


	handleCancel = () => {
		const { onCancel, form } = this.props;
		form.resetFields()
		this.clearSelected();
		let field_list = document.getElementById("obj_word-list");
		if (field_list) field_list.classList.remove("ui-has-error");
		let field_word = document.getElementById('obj_word-field[]');
		if (field_word) field_word.classList.remove("ui-has-error");
		this.setState({ name: "", regex: "" })
		onCancel();
	}

	addValueList = (value) => {
		let { list } = this.state;
		var object = list.some(p => p.trim() == value.trim())
		if (object === false) {
			list.push(_.cloneDeep(value));
		}

		this.setState({ list });

		var element = document.getElementById("obj_word-list");
		element.classList.remove("ui-has-error")
	}

	delValueList = (value) => {

		let { list } = this.state;
		for (var i = 0; i < list.length; i++) {
			if (list[i].trim() === value.trim()) {
				list.splice(i, 1);
			}
		}
		this.setState({ list });
	}


	moveValueList = (value) => {
		document.getElementById('obj_word-field[]').classList.remove("ui-has-error");
		const { validateFields, resetFields } = this.props.form;
		let fields = ["obj_word-word"]

		validateFields(fields, (errors) => {

			if (!errors) {
				resetFields([
					"obj_word-word",
				]);

				this.addValueList(value);
				this.setState({ name: '', regex: '' });
			}
		});
	}

	moveRegex = () => {
		const { setFieldsValue, getFieldDecorator, getFieldValue } = this.props.form;

		let selectElement = document.getElementById('obj_word-field[]')
		let values = Array.from(selectElement.selectedOptions).map(option => option.value)
		if (values.length > 0) {

			getFieldDecorator('obj_word-word');
			let word = getFieldValue('obj_word-word') + values.join("");
			setFieldsValue({ 'obj_word-word': word })
			this.clearSelected();
			this.setState({ name: word })
			selectElement.classList.remove("ui-has-error")

		} else {
			selectElement.classList.add("ui-has-error");
		}
	}

	removeValueList = () => {
		let selectElement = document.getElementById('obj_word-list')
		let values = Array.from(selectElement.selectedOptions).map(option => option.value)

		values.forEach(function (key, ind) {
			this.delValueList(key);
		}, this);
	}

	renderList(row) {
		return (<option key={row.toString()}>{row}</option>)
	}

	saveObj = (form, values) => {
		const { intl, dictionaries } = this.props;

		const itens = [];
		var element = document.getElementById("obj_word-list");
		for (var i = 0; i < element.options.length; i++) {
			itens.push(element.options[i].value);
		}

		const bodyData = new FormData();
		bodyData.set("act", "saveDictionary");
		bodyData.set("obj_word-name", values["obj_word-name"]);
		bodyData.set("obj_word-desc", values["obj_word-desc"]);
		bodyData.set("obj_word-list", JSON.stringify(itens));

		if (dictionaries.id !== '') {
			bodyData.set("obj_word-id", dictionaries.id);
		}

		Axios.post(ajaxUrl[package_version].objects, bodyData).then((res) => {
			this.setState({ lockForm: false });

			if (product == "GSM" && Array.isArray(res.data) && res.data.length == 2)
				if (res.data[1]['errorcode'] == '001') {
					form.resetFields();
					this.handleCancel();
					element.classList.remove("ui-has-error")
					this.setState({ list: [] })
					return message.error(this.props.intl.formatMessage({ id: "common.dont.permission" }))
				}

			if (res.data === "Seu login não permite acesso a esta area." || res.data === "Your login does not allow access to this area.") {
				form.resetFields();
				this.handleCancel();
				element.classList.remove("ui-has-error")
				this.setState({ list: [] })
				return message.error(this.props.intl.formatMessage({ id: "common.dont.permission.edit" }));
			}

			if (res.data[0].success == 1) {
				message.success(intl.formatMessage({ id: "objects.actions.create.success" }));
				form.resetFields();
				this.handleCancel();
				element.classList.remove("ui-has-error")
				this.setState({ list: [] })
				this.props.onOk();
			} else {
				res.data.forEach(erro => {
					form.setFields({
						[erro.field]: {
							value: values[erro.field],
							errors: [new Error(intl.formatMessage({ id: "common.invalid.value" }))],
						},
					});
					if (erro.field == "obj_word-list[]") {
						element.classList.add("ui-has-error")
					}

					if (res.data[0]['exists']) {
						form.setFields({
							'obj_word-name': {
								value: form.getFieldValue('obj_word-name'),
								errors: [new Error(this.props.intl.formatMessage({ id: "common.alreadyExists" }))]
							}
						})
					}
					message.error(intl.formatMessage({ id: "objects.actions.create.error" }));
				})
			}

		})
	}

	handleUpload = ({ fileList }) => {

		let { intl } = this.props
		let file = fileList[0].originFileObj

		let isLt2M = fileList[0].size / 1024 / 1024 < 2;
		let isTXT = fileList[0].type === 'text/plain';

		let error = 0
		let success = 0

		if (!isLt2M) {
			return message.error(intl.formatMessage({ id: "objects.dictionaries.modal.error.file.bigger" }));
		}
		if (!isTXT) {
			return message.error(intl.formatMessage({ id: "objects.dictionaries.modal.error.file.txt" }));
		}

		if (file) {
			var reader = new FileReader();
			reader.onload = (e) => {
				var contents = e.target.result;
				var items = contents.split("\n").filter(i => i);
				new Promise((resolve, reject) => {
					items.map(item => {
						success++;
						this.addValueList(String.raw`${item}`)
					})
					resolve(success);
				}).then((success) => {
					let total = `${success}/${items.length}`
					message.success(intl.formatMessage({ id: "objects.dictionaries.modal.import.success" }, { number: total }));
				});
			}
			reader.readAsText(file);
		}

	};

	render() {
		let { intl, dictionaries, visible, titlePrefix } = this.props;
		const { getFieldDecorator } = this.props.form;
		const { fileList, lockForm } = this.state;
		const formLayout = { colon: false, style: { paddingBottom: 0 } };
		const formLayoutLast = { colon: false, style: { paddingBottom: 0, marginBottom: 0 } };
		const { TextArea } = Input;

		//Object GSM disabled
		const lockFormGsmUtm = dictionaries.gsmid ? true : lockForm;
		const controleLoadingButton = dictionaries.gsmid ? false : lockForm;

		const footerActions = [
			<Button key="cancel" onClick={this.handleCancel}>
				<FormattedMessage id="common.cancel" />
			</Button>,
			<Upload
				disabled={lockFormGsmUtm}
				fileList={fileList}
				accept={".txt*"}
				onChange={this.handleUpload}
				beforeUpload={() => false}
				listType="text"
				key="upload">
				<Tooltip title={intl.formatMessage({ id: "objects.dictionaries.modal.import.warning" })}>
					<Button disabled={lockFormGsmUtm} key="submit">
						{intl.formatMessage({ id: "common.import" }, { name: intl.formatMessage({ id: "common.object.dictionary" }) })}
					</Button>
				</Tooltip>
			</Upload>,
			<Button loading={controleLoadingButton} disabled={lockFormGsmUtm} key="save" type="primary" onClick={this.handleSave}>
				<FormattedMessage id="common.save" />
			</Button>
		]

		if (titlePrefix == '') {
			titlePrefix = intl.formatMessage({ id: "common.create" });
		}

		let { list: rows } = this.state;

		return <DraggableModal
			onOk={this.handleSave}
			onCancel={this.handleCancel}
			confirmLoading={lockFormGsmUtm}
			visible={visible}
			title={<Fragment>{titlePrefix}<FormattedMessage id="objects.modal.title" values={{ object: "Dictionaries" }} /></Fragment>}
			okText={intl.formatMessage({ id: "common.save" })}
			cancelText={intl.formatMessage({ id: "common.cancel" })}
			footer={footerActions}>
			<Form layout="vertical">
				<Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.dictionaries.modal.form.name" })} >
					{getFieldDecorator('obj_word-name', {
						initialValue: dictionaries.name,
						rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.dictionaries.modal.form.name" }) }) }]
					})(
						<Input disabled={lockFormGsmUtm} />
					)}
				</Form.Item>

				<Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.dictionaries.modal.form.expressions" })} >
					<div style={{ display: 'flex' }}>
						<select disabled={lockFormGsmUtm} id='obj_word-field[]' style={{ marginRight: 8 }} className="ant-input" multiple>
							<option value=".">.</option>
							<option value="\.">\.</option>
							<option value="^">^</option>
							<option value="$">$</option>
							<option value="\w">\w</option>
							<option value="\W">\W</option>
							<option value="\d">\d</option>
							<option value="\D">\D</option>
							<option value="\s">\s</option>
							<option value="\S">\S</option>
							<option value="[abc]">[abc]</option>
							<option value="[çãê][^::alpha::]">[çãê][^::alpha::]</option>
							<option value="[^abc]">[^abc]</option>
							<option value="[a-z]">[a-z]</option>
							<option value="aa|bb">aa|bb</option>
							<option value="?">?</option>
							<option value=".*">.*</option>
							<option value="+">+</option>
							<option value="{n}">n</option>
							<option value="{n,}">n</option>
							<option value="{m,n}">mn</option>
							<option value="(expr)">(expr)</option>
						</select>
						<button disabled={lockFormGsmUtm} onClick={() => this.moveRegex()} style={{ height: 32, marginTop: 20 }} className="btn btn-sm" title={intl.formatMessage({ id: "common.del.to.list" })}><i className="fa fa-plus" /></button>
					</div>
				</Form.Item>

				<Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.dictionaries.modal.form.word" })} >
					<div style={{ display: 'flex' }}>
						{getFieldDecorator('obj_word-word', {
							initialValue: this.state.name,
							rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.dictionaries.modal.form.word" }) }) }]
						})(
							<Input disabled={lockFormGsmUtm} onChange={event => this.setState({ name: event.target.value })} style={{ marginRight: 8 }} />
						)}
						<button disabled={lockFormGsmUtm} onClick={() => this.moveValueList(this.state.name)} className="btn btn-sm" title={intl.formatMessage({ id: "common.add.to.list" })}><i className="fa fa-plus" /></button>
					</div>
				</Form.Item>

				<Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.dictionaries.modal.form.list" })}>
					<div style={{ display: 'flex' }}>
						<select disabled={lockFormGsmUtm} id="obj_word-list" style={{ marginRight: 8 }} className="ant-input" multiple>
							{rows.map(this.renderList)}
						</select>
						<button disabled={lockFormGsmUtm} onClick={() => this.removeValueList()} style={{ height: 32, marginTop: 20 }} className="btn btn-sm" title={intl.formatMessage({ id: "common.del.to.list" })}><i className="fa fa-minus" /></button>
					</div>
				</Form.Item>

				<Form.Item {...formLayoutLast} label={intl.formatMessage({ id: "objects.dictionaries.modal.form.description" })} >
					{getFieldDecorator('obj_word-desc', { initialValue: dictionaries.description })(
						<TextArea disabled={lockFormGsmUtm} />
					)}
				</Form.Item>
			</Form >
		</DraggableModal>
	}
}

DictionariesModal.defaultProps = {
	onCancel: null,
	visible: false,
	titlePrefix: '',
	dictionaries: {
		id: '',
		name: '',
		description: '',
		word_match: [],
		type: '',
		used: 0
	}
}

export default injectIntl(Form.create({})(DictionariesModal));