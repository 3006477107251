import React, { Component, Fragment } from "react";
import { DraggableModal } from "/bbui/components/";
import { Form, Input, Select, Button, message } from "antd";
import AxiosFormData from "/helpers/AxiosFormData";
import Axios from "axios";
import { injectIntl, FormattedMessage } from "react-intl";
import { ajaxUrl } from "/bbui/config/constants";
import { product } from "/config/constants";
const package_version = 'app';

class TimesGroupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lockForm: false,
      message: null,
      name: '',
      timesGroup: props.timesGroup,
      _getSelect2Time: [],
      titlePrefix: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      const { id,intl } = this.props;
      if (id) {
        this.setState({ lockForm: true })
        Axios.post(ajaxUrl[package_version].objects, AxiosFormData({ act: "getObjectGroup", type: "times", id })).then((res) => {
          if (res.data) {
            console.log(res.data);
            let timesGroup = res.data[0];

            this.setState({
              timesGroup: {
                lockForm: false,
								id: timesGroup.id,
								name: timesGroup.name,
								description: timesGroup.desc,
								list: JSON.parse(timesGroup.id_objs),
                type: timesGroup.type,
                gsmid: timesGroup.gsmid
              }
            }, () => {
              this.setState({ lockForm: false })
            })
          }
        })
      }
    
      this.state.titlePrefix = intl.formatMessage({id: 'common.edit'});
      this._getSelect2Time();
    }

    if (this.props.timesGroup !== prevProps.timesGroup) {
      this.setState({
				lockForm: false,
        timesGroup: this.props.timesGroup,
      })
    }
  }

	_getSelect2Time = () => {
		const formData = new FormData();
		formData.set("act", "_getSelect2Time");

		Axios.post(ajaxUrl[package_version].objects_templates, formData).then((res) => {
			this.setState({ _getSelect2Time: res.data.items });
		});
	}

  handleSave = () => {
    const { intl, form } = this.props;
    const { timesGroup } = this.state;
    this.setState({ loading: true, lockForm: true });

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { group } = this.props;
        const bodyData = new FormData();
        bodyData.set("act", "saveObjectsGroups");
        bodyData.set("table", 'time');
        bodyData.set("obj_time_group-type", 'group');
        bodyData.set("obj_time_group-name", values["obj_time_group-name"]);
        bodyData.set("obj_time_group-desc", values["obj_time_group-desc"]);
        bodyData.set("obj_time_group-list", values["obj_time_group-list"]);

        if (group && "id" in group){
          bodyData.set("obj_time_group-id", group.id);
        } else if (timesGroup && timesGroup.id) {
          bodyData.set(`obj_time_group-id`, timesGroup.id);
        }

        Axios.post(ajaxUrl[package_version].objects, bodyData).then((res) => {
          this.setState({ lockForm: false, loading: false });

          if (product == "GSM" && Array.isArray(res.data) && res.data.length == 2)
            if (res.data[1]['errorcode'] == '001') {
              this.props.form.resetFields();
              this.handleCancel();
              return message.error(this.props.intl.formatMessage({ id: "common.dont.permission" }))
            }
          
            if (res.data === "Seu login não permite acesso a esta area." || res.data === "Your login does not allow access to this area.") {
              this.props.form.resetFields();
              this.handleCancel();
              return message.error(this.props.intl.formatMessage({ id: "common.dont.permission.edit" }));
            }

          if (res.data[0].success == 1) {
            message.success(intl.formatMessage({ id: "common.save.success" }));
            this.handleCancel();
            this.props.form.resetFields();
            this.props.onSuccess();
          } else {
            res.data.forEach(erro => {
              form.setFields({
                [erro.field]: {
                  value: values[erro.field],
                  errors: [new Error(intl.formatMessage({ id: "common.invalid.value" }))],
                },
              });

              if (res.data[0]['exists']) {
                form.setFields({
                  'obj_time_group-name': {
                    value: form.getFieldValue('obj_time_group-name'),
                    errors: [new Error(this.props.intl.formatMessage({ id: "common.alreadyExists" }))]
                  }
                })
              }
            })
            message.error(intl.formatMessage({ id: "objects.actions.create.error" }));
          }
        });
      } else {
        this.setState({ lockForm: false });
      }
    });
  }

  handleCancel = () => {
    const { onCancel } = this.props;
    this.props.form.resetFields();
    onCancel();
  }

  renderTime() {
    if (this.props._getSelect2Time){
      const { _getSelect2Time } = this.props;
      if (_getSelect2Time){
        return _getSelect2Time.map((g) => {
          return <Select.Option key={g.id} value={g.id}>{g.text}</Select.Option>;
        });
      }
    } else if (this.state._getSelect2Time){
      const { _getSelect2Time } = this.state;
      if (_getSelect2Time){
        return _getSelect2Time.map((g) => {
          return <Select.Option key={g.id} value={g.id}>{g.text}</Select.Option>;
        });
      }
    }
  }

  render() {
    let { intl, visible, group, titlePrefix } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { lockForm, timesGroup } = this.state;
    const { TextArea } = Input;
    const formLayout = { colon: false, style: { paddingBottom: 0 } };

    let lockFormGsmUtm = lockForm;
    let controleLoadingButton = false;
    
    let rows = [];
    if (group) {
      
      //Object GSM disabled
      lockFormGsmUtm = group.gsmid ? true : lockForm;
      controleLoadingButton = group.gsmid ? false : lockForm;

      if (group.id !== undefined) {
        if (group.week_day.length == 0) {
          rows = group.week_day;
        } else {
          rows = JSON.parse(group.week_day);
        }
      }
    } else {
      group = [];
    }
    
    titlePrefix = titlePrefix ? titlePrefix : this.state.titlePrefix;

    if (timesGroup){
      group = timesGroup;
      rows = group.list.map(i => parseInt(i));
    }

    const footerActions = [
			<Button key="cancel" onClick={this.handleCancel}>
				<FormattedMessage id="common.cancel" />
			</Button>,
			<Button loading={controleLoadingButton} disabled={lockFormGsmUtm} key="save" type="primary" onClick={this.handleSave}>
				<FormattedMessage id="common.save" />
			</Button>
		]

    return <DraggableModal
      onOk={this.handleSave}
      onCancel={this.handleCancel}
      confirmLoading={lockFormGsmUtm}
      visible={visible}
      title={<Fragment>{titlePrefix}<FormattedMessage id="objects.modal.group.title" values={{ object: "Times" }} /></Fragment>}
      okText={intl.formatMessage({ id: "common.save" })}
      cancelText={intl.formatMessage({ id: "common.cancel" })}
      footer={footerActions}>
      <Form id="form-times-group" layout="vertical">
        <Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.times.modal.form.name" })} >
          {getFieldDecorator('obj_time_group-name', {
            initialValue: group.name,
            rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.times.modal.form.name" }) }) }]
          })(
            <Input disabled={lockFormGsmUtm} />
          )}
        </Form.Item>

        <Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.times.modal.form.description" })} >
          {getFieldDecorator('obj_time_group-desc', {
            initialValue: group.description,
          })(
            <TextArea disabled={lockFormGsmUtm} />
          )}
        </Form.Item>

        <Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.addressGroup.modal.object" })}>
          {getFieldDecorator('obj_time_group-list', {
            initialValue: rows,
            rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.addressGroup.modal.object" }) }) }]
          })(
            <Select
              disabled={lockFormGsmUtm}
              style={{ width: "100%" }}
              showSearch
              placeholder="Select"
              optionFilterProp="children"
              mode="multiple"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {this.renderTime()}
            </Select>
          )}
        </Form.Item>
      </Form >
    </DraggableModal>
  }
}

TimesGroupModal.defaultProps = {
  onCancel: null,
  visible: false
}

export default injectIntl(Form.create({})(TimesGroupModal));