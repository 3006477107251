import React, { memo, useState, useContext, PureComponent, Fragment } from "react";
import { BulkActionsMenu, Loading, ActionBack } from "./";
import { Collapse, Checkbox, Icon, Badge, Tooltip, Empty, Switch, Row, Col, Tag, Input } from 'antd';
import { FormattedMessage } from "react-intl";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AddressModal from "/bbui/apps/objects/address/AddressModal";
import AddressGroupModal from "/bbui/apps/objects/address/AddressGroupModal";
import ServicesModal from "/bbui/apps/objects/services/ServicesModal";
import ServicesGroupModal from "/bbui/apps/objects/services/ServicesGroupModal";
import TimesModal from "/bbui/apps/objects/times/TimesModal";
import TimesGroupModal from "/bbui/apps/objects/times/TimesGroupModal";
import SchedulesModal from "/bbui/apps/objects/schedules/SchedulesModal";
import SchedulesGroupModal from "/bbui/apps/objects/schedules/SchedulesGroupModal";
import DictionariesModal from "/bbui/apps/objects/dictionaries/DictionariesModal";
import ContentsModal from "/bbui/apps/objects/contents/ContentsModal";

import './PolicyManagerGrid.css';

const { Panel } = Collapse;
const { Search } = Input;

const ObjectsContext = React.createContext([{}, () => { }]);

/**
 * PolicyManagerGrid component
 * 
 * @param {boolean} loading Action buttons listed at the top right of the grid
 * @param {string} title Grid Title
 * @param {array} badges Badges details - token, name
 * @param {object} dataSource Data to be shown on the grid
 * @param {object} checked checkbox status for items
 * @param {array} bulkActions Action buttons listed at the top right of the grid
 */

const PolicyManagerGrid = memo(props => {
  const [state, setState] = useState({
    addressModal: {
      visible: false,
      id: null,
      type: null,
    },
    addressGroupModal: {
      visible: false,
      id: null,
      type: null,
    },
    servicesModal: {
      visible: false,
      id: null,
    },
    servicesGroupModal: {
      visible: false,
      id: null,
    },
    timesModal: {
      visible: false,
      id: null,
    },
    timesGroupModal: {
      visible: false,
      id: null,
    },
    schedulesModal: {
      visible: false,
      id: null,
    },
    schedulesGroupModal: {
      visible: false,
      id: null,
    },
    dictionariesModal: {
      visible: false,
      id: null,
    },
    contentModal: {
      visible: false,
      id: null,
    },
  });

  const handleClick = (type, clickId, data) => {
    const { dataSource } = props;
    if (type == 'checkbox') {
      let id = clickId.toString().split(':');
      let checked = JSON.parse(JSON.stringify(props.checked));
      if (id[0] == 'G') {
        Object.keys(dataSource).map(pos =>
          !dataSource[pos].disabled && dataSource[pos].groups.map(group => {
            group.rules.map(rule => {
              if (group.id == id[1]) checked['R'][rule.id] = !checked['G'][id[1]]
            })
          })
        )
      }
      checked[id[0]][id[1]] = !checked[id[0]][id[1]]

      if (id[0] == 'R') {
        Object.keys(dataSource).map(pos =>
          !dataSource[pos].disabled && dataSource[pos].groups.map(group => {
            let t = 0; // true
            let f = 0; // false
            group.rules.map(rule => {
              if (checked['R'][rule.id]) t++; else f++;
            });
            switch (true) {
              case (t == 0 && f == 0): // empty
                break;
              case (f == 0): // all checked
                checked['G'][group.id] = true;
                break;
              case (t > 0): // some checked
                checked['G'][group.id] = 'ind';
                break;
              case (t == 0): // none checked
                checked['G'][group.id] = false
                break;
            }
          })
        )
      }
      props.onClick(type, id, checked);
    } else {
      props.onClick(type, clickId, data);
    }
  }

  const { loading, mode, title, dataSource, bulkActions, checked, onDragEnd, badges, onFilter, filter, filterField, activeKeys, policiesStatus, onChange, onSearchChange, showActionBack, version } = props;
  return <ObjectsContext.Provider value={[state, setState]}>
    <div id='policy-grid'>
      <Fragment>
        <Loading loading={loading} />
        <div className="ui-datagrid-controls">
          <div className="title">
            {title}
          </div>
          <div className="policy-search">
            <Search onSearch={value => onFilter(value)} allowClear enterButton value={filterField} onChange={onSearchChange} />
          </div>
          {showActionBack && <ActionBack />}
          <BulkActionsMenu actions={bulkActions} />
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          {Object.keys(dataSource).map(position => (
            !dataSource[position].disabled ?
              <Droppable droppableId={position} type="group" isDropDisabled={dataSource[position].readOnly} key={position}>
                {(provided, snapshot) => (
                  <div id="policy-grid-grouplist" ref={provided.innerRef}>
                    <PositionGrid
                      dataSource={dataSource[position].groups}
                      mode={mode}
                      version={version}
                      filter={filter}
                      disableGrip={filter != ''}
                      activeKeys={activeKeys}
                      policiesStatus={policiesStatus}
                      onChange={onChange}
                      checked={checked}
                      position={position}
                      onClick={handleClick}
                      key={position}
                      readOnly={dataSource[position].readOnly}
                      badges={badges}
                      title={dataSource[position].title}
                    />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              :
              <PositionGrid version={version} position={position} disabled key={position} title={dataSource[position].title} />
          ))}
        </DragDropContext>
      </Fragment>
    </div>
    <AddressModal
      id={state.addressModal.id}
      type={state.addressModal.type}
      visible={state.addressModal.visible}
      onOk={
        () => {
          setState(state => ({ ...state, addressModal: { visible: false }}));
          props.refresh()
        }}
      onCancel={
        () => setState(state => ({ ...state, addressModal: { visible: false } }))
      }
    />
    <AddressGroupModal
      id={state.addressGroupModal.id}
      type={state.addressGroupModal.type}
      visible={state.addressGroupModal.visible}
      onSuccess={
        () => {
          setState(state => ({ ...state, addressGroupModal: { visible: false }}));
          props.refresh()
        }}
      onCancel={
        () => setState(state => ({ ...state, addressGroupModal: { visible: false } }))
      }
    />
    <ServicesModal
      id={state.servicesModal.id}
      visible={state.servicesModal.visible}
      onOk={
        () => {
          setState(state => ({ ...state, servicesModal: { visible: false } }));
          props.refresh()
        }}
      onCancel={
        () => setState(state => ({ ...state, servicesModal: { visible: false } }))
      }
    />
    <ServicesGroupModal
      id={state.servicesGroupModal.id}
      visible={state.servicesGroupModal.visible}
      onSuccess={
        () => {
          setState(state => ({ ...state, servicesGroupModal: { visible: false }}));
          props.refresh()
        }}
      onCancel={
        () => setState(state => ({ ...state, servicesGroupModal: { visible: false } }))
      }
    />
    <TimesModal
      id={state.timesModal.id}
      visible={state.timesModal.visible}
      onOk={
        () => {
          setState(state => ({ ...state, timesModal: { visible: false } }));
          props.refresh()
        }}
      onCancel={
        () => setState(state => ({ ...state, timesModal: { visible: false } }))
      }
    />
    <TimesGroupModal
      id={state.timesGroupModal.id}
      visible={state.timesGroupModal.visible}
      onSuccess={
        () => {
          setState(state => ({ ...state, timesGroupModal: { visible: false } }));
          props.refresh()
        }}
      onCancel={
        () => setState(state => ({ ...state, timesGroupModal: { visible: false } }))
      }
    />
    <SchedulesModal
      id={state.schedulesModal.id}
      visible={state.schedulesModal.visible}
      onOk={
        () => {
          setState(state => ({ ...state, schedulesModal: { visible: false } }));
          props.refresh()
        }}
      onCancel={
        () => setState(state => ({ ...state, schedulesModal: { visible: false } }))
      }
    />
    <SchedulesGroupModal
      id={state.schedulesGroupModal.id}
      visible={state.schedulesGroupModal.visible}
      onSuccess={
        () => {
          setState(state => ({ ...state, schedulesGroupModal: { visible: false } }));
          props.refresh()
        }}
      onCancel={
        () => setState(state => ({ ...state, schedulesGroupModal: { visible: false } }))
      }
    />
    <DictionariesModal
      id={state.dictionariesModal.id}
      visible={state.dictionariesModal.visible}
      onOk={
        () => {
          setState(state => ({ ...state, dictionariesModal: { visible: false } }));
          props.refresh()
        }}
      onCancel={
        () => setState(state => ({ ...state, dictionariesModal: { visible: false } }))
      }
    />
    <ContentsModal
      id={state.contentModal.id}
      visible={state.contentModal.visible}
      onOk={
        () => {
          setState(state => ({ ...state, contentModal: { visible: false } }));
          props.refresh()
        }}
      onCancel={
        () => setState(state => ({ ...state, contentModal: { visible: false } }))
      }
    />
  </ObjectsContext.Provider>
})

const getListStyle = isDraggingOver => ({
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  // opacity: isDraggingOver ? 1 : 0.5,
});

const getItemStyle = (isDragging, draggableStyle) => {
  isDragging && draggableStyle.transform && (draggableStyle.transform = "translate(0px," + draggableStyle.transform.split(',')[1]); // lock horiz axis
  isDragging && (draggableStyle.opacity = 0.5);
  return draggableStyle
};

class ActionButton extends PureComponent {
  render() {
    let { action, onClick } = this.props;
    return (
      <div className={`policy-tag-action ${action}`} onClick={(e) => onClick('action', action, e)}>
        {action == 'allow' ?
          <i className='fa fa-unlock' />
          :
          <i className='fa fa-lock' />
        }
        <FormattedMessage id={`policy.manager.policy.action.${action}`} />
      </div>
    )
  }
}

class ToggleButton extends PureComponent {
  render() {
    let { onClick, status, readOnly } = this.props;
    return <Tooltip mouseEnterDelay={0.3} title={<FormattedMessage id={status == false ? "common.disabled" : "common.enabled"} />}><Switch size="small" style={readOnly && { float: 'left' }} checked={status} onClick={onClick} disabled={readOnly} /></Tooltip>
  }
}
class EditButton extends PureComponent {
  render() {
    let { onClick } = this.props;
    return <Tooltip mouseEnterDelay={0.3} title={<FormattedMessage id="common.edit" />}><a className="action-button"><i className="fa fa-pencil" onClick={onClick} /></a></Tooltip>
  }
}
class ViewButton extends PureComponent {
  render() {
    let { onClick } = this.props;
    return <Tooltip mouseEnterDelay={0.3} title={<FormattedMessage id="common.view" />}><a className="action-button"><i className="fa fa-eye" onClick={onClick} /></a></Tooltip>
  }
}
class TrashButton extends PureComponent {
  render() {
    let { onClick } = this.props;
    return <Tooltip mouseEnterDelay={0.3} title={<FormattedMessage id="common.delete" />}><a className="action-button"><i className="fa fa-trash" onClick={onClick} /></a></Tooltip>
  }
}

class CloneButton extends PureComponent {
  render() {
    let { onClick } = this.props;
    return <Tooltip mouseEnterDelay={0.3} title={<FormattedMessage id="common.clone" />}><a className="action-button"><i className="fa fa-files-o" onClick={onClick} /></a></Tooltip>
  }
}

class GripButton extends PureComponent {
  render() {
    return (
      <a className="grip-button" {...this.props}>
        <Icon type="more" className="grip-icon" /><Icon type="more" className="grip-icon" />
      </a>
    )
  }
}

class BadgeDisplay extends PureComponent {
  render() {
    let { badges, activeBadges, onClick, version, intl } = this.props;
    let activeTags = activeBadges.map(b => b.tag)
    if (badges) return (
      <Row className='policy-panel-extra-badges' gutter={0} type="flex" justify="start">
        {activeBadges && badges[version[0] + '.x'].map((badge, i) => {
          let active = activeTags.includes(badge.token);
          let activeTag = {};
          if (active) activeTag = activeBadges.filter(b => b.tag == badge.token).pop();
          return (
            <Col key={i} span={8}>
              <Tooltip mouseEnterDelay={0.3} style={{ width: 150 }} title={
                <Fragment>
                  <u>{badge.name} <FormattedMessage id={`common.${active == "1" ? 'active' : 'inactive'}`} /></u>
                  {activeTag.label &&
                    <Fragment>
                      <br /><FormattedMessage id='common.profile' />: {activeTag.label}
                    </Fragment>
                  }
                </Fragment>}>
                <div className={`policy-panel-extra-badge ${active == "1" ? "active" : ""}`} onClick={(e) => onClick('module', badge.token, e)}>
                  {badge.token}
                </div>
              </Tooltip>
            </Col>
          )
        })}
      </Row>
    )
  }
}

const ListServicesGroup = memo(props => {
  const { className, name, id, description, clickId, clickIdTip, onClick, list } = props;
  const [state, setState] = useContext(ObjectsContext);
  const [visible, setVisible] = useState(false);

  return Array.isArray(list) && (
    <Tooltip
      visible={visible}
      onVisibleChange={setVisible}
      mouseEnterDelay={0.3}
      title={
        <div className='policy-grid-tooltip-scroll'>
          <b><a style={{ marginRight: '20px' }} className='policy-grid-tooltip-link' onClick={(e) => onClick(clickId, name, e)}>{ name ? name : description }</a></b> {id && <a onClick={() => { setVisible(false); setState(state => ({ ...state, servicesGroupModal: { visible: true, id } })) }}><i className="fa fa-pencil" style={{ position: 'absolute', top: '8px', right: '8px', color: '#fff' }} /></a>}<br />
          <hr style={{ marginTop: 0, marginBottom: 0 }} />
          {list.map((item, i) => (
            <Fragment key={i}>
              <a className='policy-grid-tooltip-link' onClick={(e) => onClick(clickIdTip, item, e)}>
                {item}
              </a>
              <br />
            </Fragment>)
          )}
        </div>
      }
    >
      <a className={className} onClick={(e) =>  onClick(clickId, name, e)}>
        {name}
      </a>
    </Tooltip>
  )
})

const ServicesDisplay = memo(props => {
  const [state, setState] = useContext(ObjectsContext);
  const [visible, setVisible] = useState([false]);

  const serviceDetail = (service, onClick, hide) => {
    let { ports, name, description } = service;
    if (Array.isArray(ports)) {
      return (
        <Fragment>
          <b><a className='policy-grid-tooltip-link' onClick={(e) => onClick(clickId, name, e)}>{description ? description : name}</a></b>
          <a style={{ marginLeft: '20px' }} onClick={() => { hide(); setState(state => ({ ...state, servicesModal: { visible: true, id: service.id } })) }}><i className="fa fa-pencil" style={{ position: 'absolute', top: '8px', right: '8px', color: '#fff' }} /></a>
          <br />
          <hr style={{ marginTop: 0, marginBottom: 0 }} />
          {ports.map((port, pi) => {
            let sportInterval = false;
            let dportInterval = false;
            const {
              'obj_services-proto_type': type,
              'obj_services-port_src_ini': sport_ini, 'obj_services-port_src_end': sport_end,
              'obj_services-port_dst_ini': dport_ini, 'obj_services-port_dst_end': dport_end,
            } = port;
            if ((sport_ini != '') && (sport_end != '')) sportInterval = true;
            if ((dport_ini != '') && (dport_end != '')) dportInterval = true;
            let sport = (sport_ini + sport_end != '' ? 'src_port: ' : '') + sport_ini + (sportInterval ? '-' : '') + sport_end;
            let dport = (dport_ini + dport_end != '' ? 'dst_port: ' : '') + dport_ini + (dportInterval ? '-' : '') + dport_end;
            let lport = (sport_ini != '' ? sport_ini : sport_end != '' ? sport_end : dport_ini != '' ? dport_ini : dport_end != '' ? dport_end : '')
            return (
              <Fragment key={pi}>
                <a className='policy-grid-tooltip-link' onClick={(e) => onClick('port', lport, e)}>{`${type} ${sport} ${dport}`}</a><br />
              </Fragment>
            );
          })}
        </Fragment>
      );
    } else {
      return '';
    }
  }

  const { services, services_group, onClick } = props; //voltar problema com onClick
  return (
    <div className='policy-services-container'>
      <div className='policy-services'>
        {services == null && services_group == null ? <a className='black-link' onClick={(e) => onClick('service', 'any', e)}><FormattedMessage id="common.any" /></a>
          : 
          <Fragment>
            {services && services.sort((a, b) => (a.name > b.name) ? 1 : -1).map((s, i) => {
                let name = s.name.replace('-', "\u2011");
                return (
                  <Tooltip
                    key={i}
                    visible={visible[i]}
                    onVisibleChange={show => {
                      let newlist = visible.slice()
                      newlist[i] = show
                      setVisible(newlist)
                    }}
                    title={
                      serviceDetail(s, onClick, () => {
                        let newlist = visible.slice()
                        newlist[i] = false
                        setVisible(newlist)
                      })
                    }
                  >
                    <a className='black-link' onClick={(e) => onClick('service', s.name, e)}>{name}</a>
                    {i < services.length - 1 ? ', ' : ''}
                  </Tooltip>
                );
              })}
            {services_group && services_group.sort((a, b) => (a.name > b.name) ? 1 : -1).map((s, i) => {
                return (
                  <li key={i}>
                    <ListServicesGroup className='black-link' clickId='services_group' clickIdTip='services_group' onClick={onClick} name={s.name.replace('-', "\u2011")} description={s.description} list={s.name_objs} id={s.id} />
                  </li>
                );
            })}
          </Fragment>
          }
      </div>
    </div>
  )
})

const DateTimeDisplay = memo(props => {
  const { date, time, onClick } = props;
  const [state, setState] = useContext(ObjectsContext);
  const [dateVisible, setDateVisible] = useState(false);
  const [timeVisible, setTimeVisible] = useState(false);
  return (
    <div className='policy-datetime-container'>
      <div className='policy-datetime'>
        {(date || time) ?
          <Fragment>
            <div className='policy-date'>
              <Icon type="calendar" />
              {date && date.id ?
                <Tooltip
                  visible={dateVisible}
                  onVisibleChange={setDateVisible}
                  mouseEnterDelay={0.3}
                  title={
                    <Fragment>
                      <span style={{ marginRight: '20px' }}>{date['name']}</span> <a onClick={() => { setDateVisible(false); setState(state => ({ ...state, schedulesModal: { visible: true, id: date.id } })) }}><i className="fa fa-pencil" style={{ position: 'absolute', top: '8px', right: '8px', color: '#fff' }} /></a>
                    </Fragment>
                  }
                >
                  <a className='black-link' onClick={(e) => onClick('schedule', date['name'], e)}>
                    {date['name']}
                  </a>
                </Tooltip>
                : date && date.group_id ? 
                  <Tooltip
                    visible={dateVisible}
                    onVisibleChange={setDateVisible}
                    mouseEnterDelay={0.3}
                    title={
                      <div className='policy-grid-tooltip-scroll'>
                        <b><a style={{ marginRight: '20px' }} className='policy-grid-tooltip-link' onClick={(e) => onClick('schedule', date.name, e)}>{ date.name ? date.name : date.description }</a></b> {date.group_id && <a onClick={() => { setTimeVisible(false); setState(state => ({ ...state, schedulesGroupModal: { visible: true, id: date.group_id } })) }}><i className="fa fa-pencil" style={{ position: 'absolute', top: '8px', right: '8px', color: '#fff' }} /></a>}<br />
                        <hr style={{ marginTop: 0, marginBottom: 0 }} />
                        {date.name_objs.map((item, i) => (
                          <Fragment key={i}>
                            <a className='policy-grid-tooltip-link' onClick={(e) => onClick('schedule', item, e)}>
                              {item}
                            </a>
                            <br />
                          </Fragment>)
                        )}
                      </div>
                    }
                  >
                    <a className='black-link' onClick={(e) => onClick('schedule', date.name, e)}>
                      {date.name}
                    </a>
                  </Tooltip>
                :
                <a className='black-link' onClick={(e) => onClick('schedule', 'any', e)}>any</a>
              }
            </div>
            <div>
              <Icon type="clock-circle" />
              {time && time.id ?
                <Tooltip
                  visible={timeVisible}
                  onVisibleChange={setTimeVisible}
                  mouseEnterDelay={0.3}
                  title={
                    <Fragment>
                      <span style={{ marginRight: '20px' }}>{time['name']}</span> <a onClick={() => { setTimeVisible(false); setState(state => ({ ...state, timesModal: { visible: true, id: time.id } })) }}><i className="fa fa-pencil" style={{ position: 'absolute', top: '8px', right: '8px', color: '#fff' }} /></a>
                    </Fragment>
                  }
                >
                  <a className='black-link' onClick={(e) => onClick('schedule', time['name'], e)}>
                    {time['name']}
                  </a>
                </Tooltip>
                : time && time.group_id ?
                  <Tooltip
                    visible={timeVisible}
                    onVisibleChange={setTimeVisible}
                    mouseEnterDelay={0.3}
                    title={
                      <div className='policy-grid-tooltip-scroll'>
                        <b><a style={{ marginRight: '20px' }} className='policy-grid-tooltip-link' onClick={(e) => onClick('schedule', time.name, e)}>{ time.name ? time.name : time.description }</a></b> {time.group_id && <a onClick={() => { setTimeVisible(false); setState(state => ({ ...state, timesGroupModal: { visible: true, id: time.group_id } })) }}><i className="fa fa-pencil" style={{ position: 'absolute', top: '8px', right: '8px', color: '#fff' }} /></a>}<br />
                        <hr style={{ marginTop: 0, marginBottom: 0 }} />
                        {time.name_objs.map((item, i) => (
                          <Fragment key={i}>
                            <a className='policy-grid-tooltip-link' onClick={(e) => onClick('schedule', item, e)}>
                              {item}
                            </a>
                            <br />
                          </Fragment>)
                        )}
                      </div>
                    }
                  >
                    <a className='black-link' onClick={(e) => onClick('schedule', time.name, e)}>
                      {time.name}
                    </a>
                  </Tooltip>
                : <a className='black-link' onClick={(e) => onClick('schedule', 'any', e)}>any</a>
              }
            </div>
          </Fragment>
          :
          <div className='policy-always-container'>
            <div className='policy-always-icon'>
              <Icon type="calendar" />
              <Icon type="clock-circle" style={{ paddingTop: 12 }} />
            </div>
            <div className='policy-always-text'>
              <a className='black-link' onClick={(e) => onClick('schedule', 'always', e)}>
                <FormattedMessage id="common.always" />
              </a>
            </div>
          </div>
        }
      </div>
    </div>
  )
})

class TagsDisplay extends PureComponent {
  render() {
    const { tags, onClick } = this.props;
    return (
      <div className='policy-tags-container'>
        <div className='policy-tags'>
          {((tags == null) || (tags.length == 0) || (tags[0] == '')) ? <FormattedMessage id="policy.manager.notags" /> : tags.map((t, i) => t && <Tag color='#3c7c9f' key={i} onClick={(e) => onClick('tag', t, e)}>{t}</Tag>)}
        </div>
      </div>
    )
  }
}

const ListDisplay = memo(props => {
  const { className, name, id, type, description, list, clickId, clickIdTip, onClick, objType } = props;
  const [state, setState] = useContext(ObjectsContext);
  const [visible, setVisible] = useState(false);
  return Array.isArray(list) && (
    <Tooltip
      visible={visible}
      onVisibleChange={setVisible}
      mouseEnterDelay={0.3}
      title={
        <div className='policy-grid-tooltip-scroll'>
          <b><a style={{ marginRight: '20px' }} className='policy-grid-tooltip-link' onClick={(e) => onClick(clickId, name, e)}>{ name ? name : description }</a></b> {id && <a onClick={() => { setVisible(false); setState(state => ({ ...state, addressModal: { visible: true, id, type } })) }}><i className="fa fa-pencil" style={{ position: 'absolute', top: '8px', right: '8px', color: '#fff' }} /></a>}<br />
          <hr style={{ marginTop: 0, marginBottom: 0 }} />
          {list.map((item, i) => (
            <Fragment key={i}>
              <a className='policy-grid-tooltip-link' onClick={(e) => onClick(clickIdTip, item, e)}>
                {item}
              </a>
              <br />
            </Fragment>)
          )}
        </div>
      }
    >
      <a className={className} onClick={(e) => onClick(clickId, name, e)}>
        {name}
      </a>
    </Tooltip>
  )
})

const ListDisplayGroup = memo(props => {
  const { className, name, id, type, description, list, clickId, clickIdTip, onClick } = props;
  const [state, setState] = useContext(ObjectsContext);
  const [visible, setVisible] = useState(false);
  return Array.isArray(list) && (
    <Tooltip
      visible={visible}
      onVisibleChange={setVisible}
      mouseEnterDelay={0.3}
      title={
        <div className='policy-grid-tooltip-scroll'>
          <b><a style={{ marginRight: '20px' }} className='policy-grid-tooltip-link' onClick={(e) => onClick(clickId, name, e)}>{ name ? name : description }</a></b> {id && <a onClick={() => { setVisible(false); setState(state => ({ ...state, addressGroupModal: { visible: true, id, type } })) }}><i className="fa fa-pencil" style={{ position: 'absolute', top: '8px', right: '8px', color: '#fff' }} /></a>}<br />
          <hr style={{ marginTop: 0, marginBottom: 0 }} />
          {list.map((item, i) => (
            <Fragment key={i}>
              <a className='policy-grid-tooltip-link' onClick={(e) => onClick(clickIdTip, item, e)}>
                {item}
              </a>
              <br />
            </Fragment>)
          )}
        </div>
      }
    >
      <a className={className} onClick={(e) => onClick(clickId, name, e)}>
        {name}
      </a>
    </Tooltip>
  )
})

class DestinationDisplay extends PureComponent {
  render() {
    const { destination, destination_group, onClick } = this.props;
    return (
      <div className='policy-destination-container'>
        <div className='policy-destination'>
          <ul className='policy-destinations'>
            {destination == null && destination_group == null ?
              <a className='black-link' onClick={(e) => onClick('destination', 'any', e)}>
                <FormattedMessage id="common.any" />
              </a>
              : <Fragment>
              {destination && destination.sort((a, b) => (a.name > b.name) ? 1 : -1).map((d, i) => (
                <li key={i}>
                  <ListDisplay className='black-link' clickId='destination' clickIdTip='destination_ip' onClick={onClick} name={d.name.replace('-', "\u2011")} description={d.description} list={d.type == 'fqdn' ? d.fqdn_ip : d.ip_mask } id={d.id} type='ip' />
                </li>

              ))}
              {destination_group && destination_group.sort((a, b) => (a.name > b.name) ? 1 : -1).map((s, i) => (
                <li key={i}>
                  <ListDisplayGroup className='black-link' clickId='destination_group' clickIdTip='destination_ip_group' onClick={onClick} name={s.name.replace('-', "\u2011")} description={s.description} list={s.name_objs} id={s.id} type='ip' />
                </li>
              ))}
              </Fragment>
            }
          </ul>
        </div>
      </div>
    )
  }
}

class UserGroupDisplay extends PureComponent {
  render() {
    const { users, groups, onClick } = this.props;
    return (
      <div className='policy-user-container'>
        <div className='policy-user'>
          <ul className='policy-users'>
            {((users == null) && (groups == null)) ?
              <a className='black-link' onClick={(e) => onClick('user', 'any', e)}>
                <FormattedMessage id="common.any" />
              </a>
              : users && users.map((u, i) => (
                <li key={i}>
                  <a className='black-link' onClick={(e) => onClick('user', u, e)}>
                    <Tooltip mouseEnterDelay={0.3} title={u}>{u}</Tooltip>
                  </a>
                </li>
              ))}
            {groups && groups.map((g, i) => (
              <li key={i}>
                <ListDisplay className='black-link' clickId='group' clickIdTip='user' onClick={onClick} name={g.group_name} description={g.group_desc} list={g.group_users ? g.group_users.map(u => u.login) : []} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
}

class SourceDisplay extends PureComponent {
  render() {
    const { source, source_group, mac, mac_group, zone, device, onClick } = this.props;
    // let { source_group } = this.props;
    // source_group = JSON.parse(source_group);
    return (
      <div className='policy-source-container'>
        <div className='policy-source'>
          <ul className='policy-sources'>
            {(zone != null || device != null) &&
              <li className='policy-sources-zoneiface'>
                {zone &&
                  <a className='black-link' onClick={(e) => onClick('zone', zone, e)}>
                    <Tooltip mouseEnterDelay={0.3} title={<FormattedMessage id="policy.manager.network_zone" values={{ zone }} />}>
                      {zone}
                    </Tooltip>
                  </a>
                }
                {device &&
                  <a className='black-link' onClick={(e) => onClick('device', device, e)}>
                    <Tooltip mouseEnterDelay={0.3} title={<FormattedMessage id="policy.manager.network_interface" values={{ interface: device }} />}>{device}</Tooltip>
                  </a>
                }
              </li>
            }
            {(source == null && source_group == null && mac == null) ?
              <a className='black-link' onClick={(e) => onClick('source', 'any', e)}>
                <FormattedMessage id="common.any" />
              </a>
              :
              <Fragment>
                {source && source.sort((a, b) => (a.name > b.name) ? 1 : -1).map((s, i) => (
                  <li key={i}>
                    <ListDisplay className='black-link' clickId='source' clickIdTip='source_ip' onClick={onClick} name={s.name.replace('-', "\u2011")} description={s.description} list={s.type == 'fqdn' ? s.fqdn_ip : s.ip_mask} id={s.id} type='ip' />
                  </li>
                ))}
                {source_group && source_group.sort((a, b) => (a.name > b.name) ? 1 : -1).map((s, i) => (
                  <li key={i}>
                    <ListDisplayGroup className='black-link' clickId='source_group' clickIdTip='source_ip_group' onClick={onClick} name={s.name.replace('-', "\u2011")} description={s.description} list={s.name_objs} id={s.id} type='ip' />
                  </li>
                ))}
                {mac && mac.sort((a, b) => (a.name > b.name) ? 1 : -1).map((m, i) => (
                  <li key={i}>
                    <ListDisplay className='black-link' clickId='mac' clickIdTip='mac_addr' onClick={onClick} name={m.name.replace('-', "\u2011")} description={m.description} list={m.ip_mask} id={m.id} type='mac' />
                  </li>
                ))}
                {mac_group && mac_group.sort((a, b) => (a.name > b.name) ? 1 : -1).map((s, i) => (
                  <li key={i}>
                    <ListDisplayGroup className='black-link' clickId='mac_group' clickIdTip='mac_address_group' onClick={onClick} name={s.name.replace('-', "\u2011")} description={s.description} list={s.name_objs} id={s.id} type='mac' />
                  </li>
                ))}
              </Fragment>
            }
          </ul>
        </div>
      </div>
    )
  }
}

class PanelExtra extends PureComponent {
  render() {
    let { mode, readOnly, checked, onClick, badges, version, disableCheckbox, status,
      data: {
        id: data_id,
        action,
        // status,
        activeBadges,
        services,
        services_group,
        date,
        time,
        source_ip,
        source_ip_group,
        destination_ip,
        destination_ip_group,
        mac_address,
        mac_address_group,
        rules = [],
        zone,
        device,
        tags,
        users,
        groups,
      } = {},
    } = this.props;

    let indeterminate = null;
    let isGroup = mode == 'group';
    let isRule = mode == 'rule';
    let id = (isGroup ? 'G:' : 'R:') + (data_id ? data_id.toString() : '');
    let total = rules.length;

    if (checked == 'ind') {
      checked = null;
      indeterminate = true;
    }

    const handleClick = (type, id, data) => (e, ev) => {
      if (ev) e = ev;
      e.preventDefault();
      e.stopPropagation();
      onClick(type, id, data);
    }

    return (
      <Fragment>
        {isRule &&
          <Fragment>
            <UserGroupDisplay users={users} groups={groups} onClick={onClick} />
            <SourceDisplay source={source_ip} source_group={source_ip_group} zone={zone} device={device} mac={mac_address} mac_group={mac_address_group} onClick={onClick} />
            <DestinationDisplay destination={destination_ip} destination_group={destination_ip_group} onClick={onClick} />
            <DateTimeDisplay date={date} time={time} onClick={onClick} />
            <ServicesDisplay services={services} services_group={services_group} onClick={onClick} />
            <TagsDisplay tags={tags} onClick={onClick} />
            <BadgeDisplay badges={badges} activeBadges={activeBadges} onClick={onClick} version={version} />
          </Fragment>
        }
        <div id="policy-panel-extra">
          {isGroup && <Tooltip mouseEnterDelay={0.3} title={<FormattedMessage id="common.total" />}><Badge showZero overflowCount={9999} count={total} style={{ backgroundColor: '#fff', color: '#000', boxShadow: '0 0 0 1px #777 inset' }} /></Tooltip>}
          {!readOnly &&
            <Fragment>
              {isRule && <ToggleButton onClick={handleClick('toggle', id)} status={status} />}
              <EditButton onClick={handleClick('edit', id)} />
              {isRule && <CloneButton onClick={handleClick('clone', id)} />}
              <TrashButton onClick={handleClick('delete', id)} />
              <Checkbox onClick={handleClick('checkbox', id)} checked={checked} disabled={disableCheckbox} indeterminate={indeterminate} />
            </Fragment>
          }
          {isRule &&
            <Fragment>
              {readOnly &&
                <Fragment>
                  <ToggleButton onClick={handleClick('toggle', id)} status={status} readOnly />
                  <ViewButton onClick={handleClick('view', id)} />
                </Fragment>
              }
              <div id="policy-panel-extra-action">
                <ActionButton action={action} onClick={onClick} />
              </div>
            </Fragment>
          }
        </div>
      </Fragment>
    )
  }
}

class PanelHeader extends PureComponent {
  render() {
    const { tooltip = '', header, disableGrip, dragHandle, className } = this.props;
    return (
      <div className='panel-header'>
        <div className='panel-header-header'>
          {disableGrip ? <div style={{ display: 'none' }} {...dragHandle} /> : <GripButton {...dragHandle} />}
          <Tooltip mouseEnterDelay={0.3} title={tooltip.title} placement={tooltip.position ? tooltip.position : 'topLeft'}>
            <div className={className}>{header}</div>
          </Tooltip>
        </div>
      </div>
    )
  }
}

class PositionGrid extends PureComponent {
  render() {
    return this.props.mode == 'templates' ?
      <PositionGridTemplate {...this.props} />
      :
      <PositionGridPackage {...this.props} />
  }
}

class PositionGridTemplate extends PureComponent {
  render() {
    const { dataSource, position, disabled, readOnly, disableGrip, checked, onClick, title, badges, filter, policiesStatus, onChange, version } = this.props;

    return !disabled ?
      dataSource && dataSource.length ?
        dataSource.map((group, index) =>
          ((filter == '') || (group.rules.length > 0)) &&
          <div key={index} className="policy-grid-grouplist-item">
            <Collapse key={group.id} className='position-group' activeKey={[group.id.toString()]} onChange={onChange} expandIcon={() => { }}>
              <Panel key={group.id.toString()}
                className='readonly'
                forceRender
                header={
                  <PanelHeader
                    className='position-group-header'
                    header={group.name}
                    disableGrip={true}
                  />
                }
                extra={
                  <PanelExtra
                    mode='group'
                    data={group}
                    version={version}
                    readOnly={true}
                    checked={checked['G'][group.id]}
                    onClick={onClick}
                  />
                }
              >
                <RuleRow version={version} rules={group.rules} readOnly={readOnly} disableGrip={disableGrip} checked={checked.R} onClick={onClick} badges={badges} group={group.id} policiesStatus={policiesStatus} />
              </Panel>
            </Collapse>
          </div>
        )
        :
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      :
      <Collapse className='position-disabled' activeKey={''}>
        <Panel
          key={position}
          header={title ? title : <FormattedMessage id={`policy.manager.position.${position}`} />}
          disabled
          showArrow={false}
        />
      </Collapse>
  }
}

class PositionGridPackage extends PureComponent {
  render() {
    const { dataSource, position, disabled, readOnly, disableGrip, checked, onClick, title, badges, filter, activeKeys, policiesStatus, onChange, version } = this.props;

    return !disabled ?
      <Collapse className={readOnly ? 'position-readonly' : 'position-enabled'} defaultActiveKey={position}>
        <Panel key={position} header={title ? title : <Fragment><FormattedMessage id={`policy.manager.position.${position}`} /> {readOnly && <FormattedMessage id="policy.manager.readonly" />}</Fragment>}>
          {dataSource && dataSource.length ?
            dataSource.map((group, index) =>
              ((filter == '') || (group.rules.length > 0)) && <Draggable key={group.id} draggableId={'G:' + group.id} index={index} type="group">
                {(provided, snapshot) => (
                  <div
                    className="policy-grid-grouplist-item"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}>
                    <Collapse key={group.id} className='position-group' activeKey={activeKeys} onChange={onChange}>
                      <Panel key={group.id.toString()}
                        className={(readOnly || disableGrip) ? 'readonly' : ''}
                        header={
                          <PanelHeader
                            className='position-group-header'
                            tooltip={{ title: `Group ID: ${group.id}` }}
                            header={group.name}
                            disableGrip={readOnly || disableGrip}
                            dragHandle={provided.dragHandleProps}
                          />
                        }
                        extra={
                          <PanelExtra
                            mode='group'
                            data={group}
                            version={version}
                            readOnly={readOnly}
                            checked={checked['G'][group.id]}
                            onClick={onClick}
                          />
                        }
                      >
                        <RuleRow version={version} rules={group.rules} readOnly={readOnly} disableGrip={disableGrip} checked={checked.R} onClick={onClick} badges={badges} group={group.id} policiesStatus={policiesStatus} />
                      </Panel>
                    </Collapse>
                  </div>
                )}
              </Draggable>
            )
            :
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          }
        </Panel>
      </Collapse>
      :
      <Collapse className='position-disabled' activeKey={''}>
        <Panel
          key={position}
          header={title ? title : <FormattedMessage id={`policy.manager.position.${position}`} />}
          disabled
          showArrow={false}
        />
      </Collapse>
  }
}


class RuleRow extends PureComponent {
  render() {
    let { rules, readOnly, checked, onClick, badges, group, disableGrip, policiesStatus, version } = this.props;
    const dropId = `group/${group}`;
    return (
      <Fragment>
        <div className='position-group-rule-header'>
          <div className='policy-rule-title-rule'><FormattedMessage id="policy.manager.group.header.rule" /></div>
          <div className='policy-rule-title-user'><FormattedMessage id="policy.manager.group.header.user" /></div>
          <div className='policy-rule-title-source'><FormattedMessage id="policy.manager.group.header.source" /></div>
          <div className='policy-rule-title-destination'><FormattedMessage id="policy.manager.group.header.destination" /></div>
          <div className='policy-rule-title-schedule'><FormattedMessage id="policy.manager.group.header.schedule" /></div>
          <div className='policy-rule-title-services'><FormattedMessage id="policy.manager.group.header.services" /></div>
          <div className='policy-rule-title-tags'><FormattedMessage id="policy.manager.group.header.tags" /></div>
          <div className='policy-rule-title-modules'><FormattedMessage id="policy.manager.group.header.modules" /></div>
          <div className='policy-rule-title-action'><FormattedMessage id="policy.manager.group.header.action" /></div>
        </div>

        <Droppable droppableId={dropId} type="rule" isDropDisabled={readOnly} key={group}>
          {(provided, snapshot) => (
            <div
              className='position-rule-item'
              ref={provided.innerRef}
            >
              {rules.length > 0 ?
                <Fragment>
                  <Collapse key={`collapse_${group}`} className='position-rule' activeKey={''}>
                    {rules.map((data, index) =>
                      <Draggable key={`collapse_drag_${data.id}`} draggableId={'R:' + data.id} index={index} type="rule">
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <Panel
                              key={data.id}
                              headerClass='position-rule-header-panel'
                              showArrow={false}
                              style={{ opacity: data.status ? 1 : 0.5 }}
                              header={
                                <PanelHeader
                                  className='position-rule-header'
                                  tooltip={{ title: `Description: ${data.full_description}` }}
                                  header={
                                    <Fragment>
                                      <a className='black-link' onClick={(e) => onClick('id', data.id, e)}><span className='policy-rule-id'>#{data.id}</span></a>
                                      {data.description}
                                    </Fragment>
                                  }
                                  disableGrip={readOnly || disableGrip}
                                  dragHandle={provided.dragHandleProps}
                                />
                              }
                              extra={
                                <PanelExtra
                                  mode='rule'
                                  data={data}
                                  version={version}
                                  readOnly={readOnly}
                                  checked={checked[data.id]}
                                  status={policiesStatus[data.id]}
                                  onClick={onClick} badges={badges}
                                />
                              }
                            />
                          </div>
                        )}
                      </Draggable>
                    )}
                  </Collapse>
                </Fragment>
                :
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              }
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </Fragment>
    )
  }
}

PolicyManagerGrid.defaultProps = {
  showActionBack: true
}

export default PolicyManagerGrid;
