import React, { Component, Fragment } from "react";
import Axios from "axios";
import AxiosFormData from "/helpers/AxiosFormData";
import { DraggableModal } from "/bbui/components/";
import { Form, Input, Select, message, Checkbox, Upload, Button, Tooltip } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import { validIPMask, validIPV6Mask, convertMAC } from "/bbui/helpers/utils";
import { ajaxUrl, masks } from "/bbui/config/constants";
import { product } from "/config/constants";
import validator from 'validator';
var _ = require('lodash');
import "../Objects.css"

const package_version = 'app';

class AddressModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lockForm: false,
			message: null,
			name: '',
			address: props.address,
			obj_addr_list_id: 'obj_addr-list-' + Date.now(),
			fileList: [],
			editTitlePrefix: '',
		};
	}

	componentDidUpdate(prevProps) {

		if (this.props.id !== prevProps.id) {
			const { intl, id, type } = this.props;
			if (id) {
				this.setState({ editTitlePrefix: intl.formatMessage({ id: "common.edit" }) })
				this.setState({ lockForm: true });
				Axios.post(ajaxUrl[package_version].objects, AxiosFormData({ act: "getObject", type, id })).then((res) => {
					if (res.data) {
						let address = res.data[0]
						this.setState({
							address: {
								lockForm: false,
								id: address.id,
								name: address.name,
								description: address.desc,
								list: JSON.parse(address.type == 'fqdn' ? address.fqdn : address.ip_mask),
								type: address.type,
								unique: Boolean(address.unique == "0" || address.unique ==  "false" ? 0 : address.unique),
								gsmid: address.gsmid
							}
						}, () => {
							this.editMaskAddress(this.state.address);
							this.setState({ lockForm: false });
						});
					}
				});
			}
		}
		if (this.props.address !== prevProps.address) {
			this.setState({
				lockForm: false,
				address: this.props.address,
			})
		}
	}

	editMaskAddress = (item) => {
		let editItem = _.cloneDeep(item)
		let { list } = editItem;

		editItem = list.map(row => {
			if (row.indexOf('/')) {
				let ip = row.split('/');
				let mask = masks.find(x => x.id == ip[1]);
				if (mask) mask = '/' + mask.mask; else mask = '/' + ip[1];
				if (ip[1] == '32') mask = '';
				if (ip[1] == 'undefined' || ip[1] == undefined) mask = '';
				return ip[0] + mask;
			}
			return row;
		});

		this.setState({
			address: {
				...this.state.address,
				list: editItem
			}
		});

		return true;
	}

	handleSave = () => {
		this.setState({ lockForm: true });

		const { intl, form } = this.props;
		let fields = ["obj_addr-name", "obj_addr-type", "obj_addr-desc", "obj_addr-unique"]
		let addr_type = form.getFieldValue("obj_addr-type");
		let invalid = false;

		let list = document.getElementById(this.state.obj_addr_list_id);

		if (list.options) {

			if (addr_type == 'ip') {
				invalid = Object.values(list.options).some(p => !validIPMask(p.value))
			}
			else if (addr_type == 'ipv6') {
				invalid = Object.values(list.options).some(p => !validIPV6Mask(p.value))
			}
			else if (addr_type == 'mac') {
				invalid = Object.values(list.options).some(p => !validator.isMACAddress(p.value))
			}
			else if (addr_type == 'fqdn') {
				invalid = Object.values(list.options).some(p => !validator.isFQDN(p.value.split(' ')[0]))
			}
			if (form.getFieldValue("obj_addr-unique") == true && list.options.length > 1) {
				invalid = true
			}

			if (list.options.length == 0) {
				invalid = true
			}

		} else {
			invalid = true;
		}

		form.validateFields(fields, (err, values) => {

			if (invalid === true) {
				message.error(intl.formatMessage({ id: "objects.address.modal.form.invalid" }));
				list.classList.add("ui-has-error");
				this.setState({ lockForm: false });
				return
			} else {
				if (!err) {
					this.saveObj(form, values);
				} else {
					this.setState({ lockForm: false });
				}

			}

		});
	}

	handleCancel = () => {
		const { onCancel, form } = this.props;
		form.resetFields();
		let field = document.getElementById("obj_time-list");
		if (field) field.classList.remove("ui-has-error");
		onCancel();
	}

	addValueList = (value, mask) => {
		let { list } = this.state.address;
		if (typeof mask != "undefined" && mask != "255.255.255.255" && mask != "128") {
			var val = value + "/" + mask
		} else {
			var val = value
		}

		var object = list.some(p => p == val)

		if (object === false) {
			list.push(val);
		}

		this.setState({
			address: {
				...this.state.address,
				list
			}
		});

		var element = document.getElementById(this.state.obj_addr_list_id);
		element.classList.remove("ui-has-error")
	}

	moveValueList = (value, mask) => {
		const { intl } = this.props;
		const { validateFields, resetFields, getFieldValue } = this.props.form;

		var element = document.getElementById(this.state.obj_addr_list_id);

		if (getFieldValue('obj_addr-unique') == true && element.options.length >= 1) {
			message.error(intl.formatMessage({ id: "objects.address.modal.form.error.unique" }));
			element.classList.add("ui-has-error");
			return
		}

		let fields = ["obj_addr-address"]

		validateFields(fields, (errors) => {
			if (!errors) {

				if (getFieldValue('obj_addr-type') == 'mac')
					value = convertMAC(value)

				resetFields(["obj_addr-address"]);
				this.addValueList(value, mask);
			}
		});
	}

	delValueList = (value) => {
		let { list } = this.state.address;
		for (var i = 0; i < list.length; i++) {
			if (list[i] === value) {
				list.splice(i, 1);
			}
		}
		this.setState({
			address: {
				...this.state.address,
				list
			}
		});
	}

	removeValueList = () => {
		let selectElement = document.getElementById(this.state.obj_addr_list_id)
		let values = Array.from(selectElement.selectedOptions).map(option => option.value)
		values.forEach(function (key, ind) {
			this.delValueList(key);
		}, this);
	}

	validateAddr = (rule, value, cb) => {
		const { getFieldValue } = this.props.form;
		const { intl } = this.props;


		if (getFieldValue('obj_addr-type') == 'ip' && !validator.isIP(value, [4])) {
			return cb(intl.formatMessage({ id: "common.error.invalid" }, { name: intl.formatMessage({ id: "objects.address.modal.form.ip" }) }));
		}
		else if (getFieldValue('obj_addr-type') == 'ipv6' && !validator.isIP(value, [6])) {
			return cb(intl.formatMessage({ id: "common.error.invalid" }, { name: intl.formatMessage({ id: "objects.address.modal.form.ipv6" }) }));
		}
		else if (getFieldValue('obj_addr-type') == 'mac' && !validator.isMACAddress(convertMAC(value))) {
			return cb(intl.formatMessage({ id: "common.error.invalid" }, { name: intl.formatMessage({ id: "objects.address.modal.form.mac" }) }));
		}
		else if (getFieldValue('obj_addr-type') == 'fqdn' && !validator.isFQDN(value)) {
			return cb(intl.formatMessage({ id: "common.error.invalid" }, { name: intl.formatMessage({ id: "objects.address.modal.form.fqdn" }) }));
		}
		else {
			return cb()
		}
	}

	renderPrefix = () => {
		let children = []
		for (let i = 0; i <= 128; i++) {
			children.push(<Select.Option key={i}>{i}</Select.Option>)
		}
		return children
	}

	renderList(row) {
		return (<option key={row.toString()}>{row}</option>)
	}

	resetFields = () => {
		const { resetFields } = this.props.form;

		resetFields([
			"obj_addr-mask",
		]);
	}

	saveObj = (form, values) => {
		const { intl } = this.props;
		const { address } = this.state;

		let item = [];
		const itens = [];
		var element = document.getElementById(this.state.obj_addr_list_id);
		for (var i = 0; i < element.options.length; i++) {
			if (element.options[i].value.indexOf(' - ')) {

				item.push(element.options[i].value.split(' ')[0]);

				if (item[i] != item[i - 1]) {
					itens.push(item[i]);
				}

			} else {
				itens.push(element.options[i].value);
			}
		}

		const bodyData = new FormData();
		bodyData.set("act", "saveObject");
		bodyData.set("obj_addr-type", values["obj_addr-type"]);
		bodyData.set("obj_addr-name", values["obj_addr-name"]);
		bodyData.set("obj_addr-desc", values["obj_addr-desc"] || '');
		bodyData.set("obj_addr-unique", values["obj_addr-unique"]);
		bodyData.set("obj_addr-list[]", itens);

		if (address.id !== '') {
			bodyData.set("obj_addr-id", address.id);
		}

		Axios.post(ajaxUrl[package_version].objects, bodyData).then((res) => {
			this.setState({ lockForm: false });
			if (product == "GSM" && Array.isArray(res.data) && res.data.length == 2)
				if (res.data[1]['errorcode'] == '001') {
					form.resetFields();
					element.classList.remove("ui-has-error");
					this.setState({ address: { list: [], id: "" } });
					this.props.onCancel();
					return message.error(this.props.intl.formatMessage({ id: "common.dont.permission" }))
				}
			
				if (res.data === "Seu login não permite acesso a esta area." || res.data === "Your login does not allow access to this area.") {
					form.resetFields();
					element.classList.remove("ui-has-error");
					this.setState({ address: { list: [], id: "" } });
					this.props.onCancel();
					return message.error(this.props.intl.formatMessage({ id: "common.dont.permission.edit" }));
				}

			if (res.data[0].success == 1) {
				message.success(intl.formatMessage({ id: "objects.actions.create.success" }));
				form.resetFields();
				element.classList.remove("ui-has-error");
				this.setState({ address: { list: [], id: "" } });
				this.props.onOk();
			} else {
				res.data.forEach(erro => {
					form.setFields({
						[erro.field]: {
							value: values[erro.field],
							errors: [new Error(intl.formatMessage({ id: "common.invalid.value" }))],
						},
					});
					if (erro.field == "obj_addr-list[]") {
						element.classList.add("ui-has-error")
					}

					if (res.data[0]['exists']) {
						form.setFields({
							'obj_addr-name': {
								value: form.getFieldValue('obj_addr-name'),
								errors: [new Error(this.props.intl.formatMessage({ id: "common.alreadyExists" }))]
							}
						})
					}

					if (erro.field == "FQDN invalid") {
						element.classList.add("ui-has-error");
						message.error(intl.formatMessage({ id: "objects.actions.create.error.dns" }));
					} else {
						message.error(intl.formatMessage({ id: "objects.actions.create.error" }));
					}
				})
			}

		}).catch((error) => {
			this.setState({ lockForm: false });
			message.error(this.props.intl.formatMessage({ id: 'common.error.network' }));
		})
	}

	handleUpload = ({ fileList }) => {

		let { intl } = this.props;
		const { getFieldValue } = this.props.form;
		let file = fileList[0].originFileObj;
		let isLt2M = fileList[0].size / 1024 / 1024 < 2;
		let isTXT = fileList[0].type === 'text/plain';
		let error = 0
		let success = 0

		if (!isLt2M) {
			return message.error(intl.formatMessage({ id: "objects.address.modal.error.file.bigger" }));
		}
		if (!isTXT) {
			return message.error(intl.formatMessage({ id: "objects.address.modal.error.file.txt" }));
		}

		this.setState({ lockForm: true })

		if (file) {
			var reader = new FileReader();
			reader.onload = (e) => {
				var contents = e.target.result;
				var ips = contents.split("\n").filter(i => i);

				new Promise((resolve, reject) => {

					ips.map(ip => {
						if ((getFieldValue('obj_addr-type') == 'ip' && validIPMask(ip.trim())) ||
							(getFieldValue('obj_addr-type') == 'ipv6' && validIPV6Mask(ip.trim())) ||
							(getFieldValue('obj_addr-type') == 'mac' && validator.isMACAddress(convertMAC(ip.trim()))) ||
							(getFieldValue('obj_addr-type') == 'fqdn' && validator.isFQDN(ip.trim()))) {
							success++
							let i = ip.trim().split("/");
							(i[1] != undefined) ? this.addValueList(i[0], i[1]) : this.addValueList(i[0])

						}
					})
					resolve(success)
					reject(error)

				}).then((success) => {
					let total = `${success}/${ips.length}`
					message.success(intl.formatMessage({ id: "objects.address.modal.import.success" }, { number: total }));
					this.setState({ lockForm: false })
				});
			}
			reader.readAsText(file);
		}

	};

	render() {
		let { intl, visible, titlePrefix } = this.props;
		const { getFieldDecorator, getFieldValue } = this.props.form;
		const { fileList, address, lockForm, editTitlePrefix } = this.state;
		const { TextArea } = Input;
		const Option = Select.Option;
		const formLayout = { colon: false, style: { paddingBottom: 0 } };
		const formLayoutLast = { colon: false, style: { paddingBottom: 0, marginBottom: 0 } };

		let controleLoadingButton = lockForm
		let lockFormGsmUtm = false

		//Object GSM disabled
		if(this.props.address.gsmid || this.state.address.gsmid){
			lockFormGsmUtm = true;
			controleLoadingButton = true;
		}

		const footerActions = [
			<Button key="cancel" onClick={this.handleCancel}>
				<FormattedMessage id="common.cancel" />
			</Button>,
			<Upload
				disabled={lockFormGsmUtm}
				fileList={fileList}
				accept={".txt*"}
				onChange={this.handleUpload}
				beforeUpload={() => false}
				listType="text"
				key="upload">
				<Tooltip title={intl.formatMessage({ id: "objects.address.modal.import.warning" })}>
					<Button key="submit" disabled={lockFormGsmUtm}>
						{intl.formatMessage({ id: "common.import" }, { name: intl.formatMessage({ id: "common.object.address" }) })}
					</Button>
				</Tooltip>
			</Upload>,
			<Button loading={controleLoadingButton} disabled={lockFormGsmUtm} key="save" type="primary" onClick={this.handleSave}>
				<FormattedMessage id="common.save" />
			</Button>
		]

		if (titlePrefix == '' && editTitlePrefix == '') {
			titlePrefix = intl.formatMessage({ id: "common.create" });
		}

		let noType = true;
		// let rows = address ? address.list : [];
		let { list: rows } = this.state.address;

		if (rows.length == 0) {
			noType = false;
		}
		if (address) {
			if (address.unique != undefined && address.unique != "undefined") {
				address.unique = JSON.parse(address.unique)
			} else {
				address.unique = false
			}
			if (address.id !== '') {
				noType = true;
			}
		} else {
			address = [];
		}

		return <DraggableModal
			onOk={this.handleSave}
			onCancel={this.handleCancel}
			confirmLoading={lockFormGsmUtm}
			visible={visible}
			title={<Fragment>{titlePrefix || editTitlePrefix}<FormattedMessage id="objects.modal.title" values={{ object: "Addresses" }} /></Fragment>}
			okText={intl.formatMessage({ id: "common.save" })}
			cancelText={intl.formatMessage({ id: "common.cancel" })}
			footer={footerActions}>
			<Form id="form-address" layout="vertical">

				<Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.address.modal.form.name" })}>
					{getFieldDecorator('obj_addr-name', {
						initialValue: address.name,
						rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.address.modal.form.name" }) }) }]
					})(
						<Input disabled={lockFormGsmUtm} />
					)}
				</Form.Item>

				<Form.Item {...formLayout} className="check-unique" label="" style={{ marginBottom: 8, paddingBottom: 0 }}>
					<div style={{ display: 'flex' }}>

						<Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.address.modal.form.type" })}>
							{getFieldDecorator('obj_addr-type', {
								initialValue: address.type ? address.type : "ip",
								rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.address.modal.form.type" }) }) }]
							})(
								<Select
									showSearch
									placeholder="Select a type"
									optionFilterProp="children"
									onChange={this.resetFields}
									disabled={noType || lockFormGsmUtm}
									filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								>
									<Option value="ip">{intl.formatMessage({ id: "objects.address.modal.form.ip" })}</Option>
									<Option value="ipv6">{intl.formatMessage({ id: "objects.address.modal.form.ipv6" })}</Option>
									<Option value="mac">{intl.formatMessage({ id: "objects.address.modal.form.mac" })}</Option>
									<Option value="fqdn">{intl.formatMessage({ id: "objects.address.modal.form.fqdn" })}</Option>
								</Select>
							)}
						</Form.Item>

						<Form.Item {...formLayout} label="" style={{ paddingBottom: 0, marginBottom: 0 }}>
							{getFieldDecorator('obj_addr-unique', { initialValue: address.unique, valuePropName: "checked" })(
								<Checkbox onChange={this.resetFields} disabled={(lockFormGsmUtm ) || (address.unique == 1)}>
									{intl.formatMessage({ id: "objects.address.modal.form.unique.ip" })}
								</Checkbox>
							)}
						</Form.Item>

					</div>
				</Form.Item>

				<Form.Item {...formLayout}>
					<div style={{ display: 'flex' }}>

						<Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.address.modal.form.item" })}>
							{getFieldDecorator('obj_addr-address', {
								validateTrigger: false,
								rules: [
									{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.address.modal.form.item" }) }) },
									{ validator: this.validateAddr }
								]
							})(
								<Input style={{ marginRight: '8px' }} disabled={lockFormGsmUtm} />
							)}
						</Form.Item>

						{getFieldValue('obj_addr-type') == "ip" && <Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.address.modal.form.mask" })}>
							{getFieldDecorator('obj_addr-mask', {
								initialValue: "255.255.255.255",
								rules: [
									{ message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.address.modal.form.mask" }) }) },
								]
							})(
								<Select disabled={getFieldValue('obj_addr-unique') == 0 ? false : true || lockFormGsmUtm}>
									{masks.map((p, index) => (
										<Option key={index} value={p.mask}>{p.mask}</Option>
									))}
								</Select>
							)}
						</Form.Item>}

						{getFieldValue('obj_addr-type') == "ipv6" && <Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.address.modal.form.prefix" })}>
							{getFieldDecorator('obj_addr-mask', {
								initialValue: "128",
								rules: [
									{ message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.address.modal.form.prefix" }) }) },
								]
							})(
								<Select disabled={getFieldValue('obj_addr-unique') || lockFormGsmUtm}>
									{this.renderPrefix()}
								</Select>
							)}
						</Form.Item>}

						<button disabled={lockFormGsmUtm} style={{ height: "32px", marginTop: "24px" }} onClick={() => this.moveValueList(getFieldValue('obj_addr-address'), getFieldValue('obj_addr-mask'))} className="btn btn-sm" title={intl.formatMessage({ id: "common.add.to.list" })}><i className="fa fa-plus" /></button>

					</div>
				</Form.Item>

				<Form.Item {...formLayout} label='' >
					<div style={{ display: 'flex' }}>
						<select disabled={lockFormGsmUtm} id={this.state.obj_addr_list_id} style={{ marginRight: '8px' }} className="ant-input" multiple>
							{Array.isArray(rows) && rows.map(this.renderList)}
						</select>
						<button disabled={lockFormGsmUtm} onClick={() => this.removeValueList()} style={{ height: 32, marginTop: '20px' }} className="btn btn-sm" title={intl.formatMessage({ id: "common.del.to.list" })}><i className="fa fa-minus" /></button>
					</div>
				</Form.Item>

				<Form.Item {...formLayoutLast} label={intl.formatMessage({ id: "objects.address.modal.form.description" })} >
					{getFieldDecorator('obj_addr-desc', {
						initialValue: address.description,
					})(
						<TextArea disabled={lockFormGsmUtm} />
					)}
				</Form.Item>
			</Form >
		</DraggableModal>
	}
}

AddressModal.defaultProps = {
	onCancel: null,
	visible: false,
	titlePrefix: '',
	id: null,
	type: null,
	address: {
		id: '',
		name: '',
		description: '',
		list: [],
		type: '',
		used: 0,
		unique: false
	}
}

export default injectIntl(Form.create({})(AddressModal));