import React, { Component, Fragment } from "react";
import { Button, Form, Icon, Tag, Progress, Tooltip, Modal, message, PageHeader } from "antd";
import { injectIntl } from "react-intl";
import { Datagrid, DraggableModal, ConfirmDelete } from "/bbui/components/";
import { formatBytes } from "/bbui/helpers/utils";
import Axios from "axios";
import { ajaxUrl } from "/config/constants";
const package_version = '2.1';
import Moment from "moment";
class BackupsDevices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination:
      {
        current: 1,
        pageSize: 10,
        total: 0
      },
      pagBackups:
      {
        current: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
      filters: '',
      filtersBackup: '',
      dataSourceDevices: [],
      dataSourceBackups: [],
      showModalBackupQueue: false,
      selectedDevice: [],
      openModalConfirm: false,
      messageConfirm: "",
      itemConfirm: [],
      actionConfirm: "",
      titleConfirm: ""
    };
  }

  componentDidMount() {
    this._getDeviceStatus();
    this.interval = setInterval(this._getDeviceStatus, 5000);
    this.interval_queue = setInterval(this._getBackupQueue, 10000);

  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.interval_queue);
  }

  openModalConfirm = (message, title, action, data) => {
    this.setState({ messageConfirm: message, itemConfirm: data, actionConfirm: action, titleConfirm: title, openModalConfirm: true })
  }

  hideModalConfirm = () => {
    this.setState({ openModalConfirm: false }, () => this.setState({ messageConfirm: "", itemConfirm: [], actionConfirm: "", titleConfirm: "" }))
  }

  handleConfirmAction = () => {

    const { actionConfirm, itemConfirm } = this.state;

    if (actionConfirm == "delete") {
      this._deleteBackupQueue(itemConfirm)
    } else if (actionConfirm == "restore") {
      this._restoreBackupQueue(itemConfirm)
    } /*else if (actionConfirm == "stop") {
      this._stopBackupDevice(itemConfirm)
    }*/

    this.hideModalConfirm()

  }

  _getDeviceStatus = (page = this.state.pagination.current, pageSize = this.state.pagination.pageSize, filters = this.state.filters) => {
    const { dataSource } = this.props

    const formData = new FormData();
    formData.set("act", "_getDeviceStatus");
    formData.set("id", dataSource.id);
    formData.set("limit", pageSize);
    formData.set("page", page);
    formData.set("filter", filters);

    Axios.post(ajaxUrl[package_version].devices_backups, formData).then((res) => {
      if (Array.isArray(res.data) && res.data.length == 2)
        if (res.data[1]['errorcode'] == '001') {
          this.setState({ loading: false })
          return message.error(this.props.intl.formatMessage({ id: "common.dont.permission" }))
        }

      this.setState({ dataSourceDevices: res.data.data });

      const pag = this.state.pagination;
      if (res.data.total) {
        pag.total = res.data.total;
      }

      this.setState({ pagination: { ...pag }, loading: false });

    })
  };

  _getBackupQueue = (page = this.state.pagBackups.current, pageSize = this.state.pagBackups.pageSize, filtersBackup = this.state.filtersBackup) => {
    const { dataSource } = this.props
    const { selectedDevice } = this.state

    const formData = new FormData();
    formData.set("act", "_getBackupsQueue");
    formData.set("backup_id", dataSource.id);
    formData.set("device_id", selectedDevice.id);
    formData.set("limit", pageSize);
    formData.set("page", page);
    formData.set("filters", filtersBackup);

    Axios.post(ajaxUrl[package_version].devices_backups, formData).then((res) => {
      if (Array.isArray(res.data) && res.data.length == 2)
        if (res.data[1]['errorcode'] == '001') {
          this.setState({ loading: false })
          return message.error(this.props.intl.formatMessage({ id: "common.dont.permission" }))
        }

      const pag = this.state.pagBackups;
      if (res.data.total) {
        pag.total = res.data.total;
      }

      this.setState({ pagBackups: { ...pag }, loading: false, dataSourceBackups: res.data.data });

    })
  };

  _openBackupQueue = (data) => {
    this.setState({ selectedDevice: data, showModalBackupQueue: true }, () => this._getBackupQueue())
  }

  renderStatus(item, intl) {
    item.status == "done";

    if (item.status === "new")
      return <Tooltip title={intl.formatMessage({ id: "devices.backups.status.waiting.schedule" })}><Tag color="gold">{intl.formatMessage({ id: "devices.backups.status.waiting" })}</Tag></Tooltip>

    if (item.status === "done")
      return <Tag color="green">{intl.formatMessage({ id: "devices.backups.status.success" })}</Tag>

    if (item.status === "waiting") {
      return <Tooltip title={Moment(item.last_status).format("DD/MM/YYYY HH:mm")}><Tag color="gold">{intl.formatMessage({ id: "devices.backups.status.waiting" })}</Tag></Tooltip>
    }

    if (item.status === "error")
      return <Tag color="red">{intl.formatMessage({ id: "devices.backups.status.error" })}</Tag>

    if (item.status === "running")
      return <Tag color="geekblue"><Icon type="sync" spin /> {intl.formatMessage({ id: "devices.backups.status.running" })}</Tag>

    if (item.status === "downloading")
      return <Tag color="geekblue"><Icon type="sync" spin /> {intl.formatMessage({ id: "devices.backups.status.downloading" })}</Tag>
    
    if (item.status === "restore" || item.status === "restoring")
      return <Tag color="geekblue"><Icon type="sync" spin /> {intl.formatMessage({ id: "devices.backups.status.restoring" })}</Tag>

    return <div />
  }

  renderProgress(item) {
    return <Fragment>
      {item.status == "new" && <Progress strokeColor={"gold"} percent={0} />}
      {item.status == "waiting" && <Progress strokeColor={"gold"} percent={0} />}
      {item.status == "done" && <Progress strokeColor={"green"} percent={100} />}
      {(item.status == "downloading" || item.status == "restoring") && <Progress strokeColor={"geekblue"} percent={66} />}
      {(item.status == "running" || item.status == "restore") && <Progress strokeColor={"geekblue"} percent={33} />}
      {item.status == "error" && <Progress strokeColor={"red"} percent={100} status="exception" />}
    </Fragment>
  }

  renderActionsDevices = (data) => {
    return <div className="ui-datagrid-actions">
      {/*<Tooltip title={this.props.intl.formatMessage({ id: "devices.backups.backup.stop" })}>
        <Button disabled={data.status != 'running' ? true : false} style={{ display: 'inline', padding: 0, width: 24 }}
          onClick={() => this.openModalConfirm(
            this.props.intl.formatMessage({ id: "settings.backups.confirm.stop" }),
            this.props.intl.formatMessage({ id: "common.stop" }), "stop", data
          )} size="small" className="ant-btn-icon-only">
          <i className="fa fa-stop" /></Button></Tooltip>*/}
      <Tooltip title={this.props.intl.formatMessage({ id: "devices.backups.backup.history" })}><Button disabled={data.count == 0 ? true : false} style={{ display: 'inline', padding: 0, width: 24 }} onClick={() => this._openBackupQueue(data)} size="small" className="ant-btn-icon-only"><i className="fa fa-clock-o" /></Button></Tooltip>
    </div>;
  }

  renderActionsBackupsQueue = (data) => {
    return <div className="ui-datagrid-actions">
      <Tooltip title={this.props.intl.formatMessage({ id: "devices.backups.backup.restore" })}>
        <Button disabled={data.file_exists == 0 || data.status == 'error' || data.status == 'restore' || data.status == 'restoring' ? true : false} style={{ display: 'inline', padding: 0, width: 24 }}
          onClick={() => this.openModalConfirm(
            this.props.intl.formatMessage({ id: "settings.backups.confirm.restore" }),
            this.props.intl.formatMessage({ id: "common.restore" }), "restore", data
          )}
          size="small" className="ant-btn-icon-only"><i className="fa fa-refresh" />
        </Button>
      </Tooltip>
      { data.type == 'snapshot' ? 
        <Tooltip title={this.props.intl.formatMessage({ id: "devices.backups.backup.download" })}>
          <Button 
            onClick={() => this._downloadBackupQueue(data)} 
            disabled={data.file_exists == 0 || data.status == 'error' ? true : false} 
            style={{ display: 'inline', padding: 0, width: 24 }} 
            size="small" 
            className="ant-btn-icon-only"><i 
            className="fa fa-download" />
          </Button>
        </Tooltip> : ""
      }
      <Tooltip title={this.props.intl.formatMessage({ id: "common.delete" })}>
        <Button onClick={() => this.openModalConfirm(
          this.props.intl.formatMessage({ id: "settings.backups.confirm.delete" }),
          this.props.intl.formatMessage({ id: "common.delete" }), "delete", data
        )} size="small" className="ant-btn-icon-only"><i className="fa fa-trash" /></Button></Tooltip>
    </div>;
  }

  renderSize = (item) => {
    const mbCkeck = 1000000000; // 1000MB
    const mbSize  = parseInt(item.size);
    const formatBytesStyle = formatBytes(mbSize, 2, (mbSize > mbCkeck ? "GB" : "MB"));
    return <span> {formatBytesStyle} </span>
  }

  renderLastStatus = (item) => {
    return <Fragment>{Moment(item).format("DD/MM/YYYY HH:mm")}</Fragment>
  }

  handleChange = (pagination, filters) => {
    this.setState({ pagination, filters }, () => this._getDeviceStatus());
  }

  handleChangeQueue = (pagBackups, filtersBackup) => {
    this.setState({ pagBackups, filtersBackup }, () => this._getBackupQueue());
  }

  _restoreBackupQueue = (item) => {
    const { intl } = this.props;
    this.setState({ loading: true })

    const bodyData = new FormData();
    bodyData.set("act", "_restoreBackupQueue");
    bodyData.set("id", item.id);
    bodyData.set("device_id", item.device_id);
    bodyData.set("backup_id", item.backup_id);
    bodyData.set("type", this.props.dataSource.data.type);

    Axios.post(ajaxUrl[package_version].devices_backups, bodyData).then((res) => {
      if (Array.isArray(res.data) && res.data.length == 2)
        if (res.data[1]['errorcode'] == '001') {
          this.setState({ loading: false })
          return message.error(this.props.intl.formatMessage({ id: "common.dont.permission" }))
        }

      if (res.data.success == "1") {
        this.setState({ loading: false })
        this._getBackupQueue()
        return message.success(intl.formatMessage({ id: "devices.backups.action.restore.success" }));
      } else {
        this.setState({ loading: false })
        return message.error(intl.formatMessage({ id: "devices.backups.action.restore.error" }))
      }
    });
  }

  _downloadBackupQueue = (item) => {
    message.success(this.props.intl.formatMessage({ id: "devices.backups.backup.download.start" }));
    window.open(ajaxUrl[package_version].devices_backups + '?act=_downloadBackupQueue&id=' + item.id);
  }

  /*_stopBackupDevice = (item) => {
    const { intl } = this.props;
    this.setState({ loading: true })

    const bodyData = new FormData();
    bodyData.set("act", "_stopBackupDevice");
    bodyData.set("dev_id", item.id);
    bodyData.set("backup_id", this.props.dataSource.id);
    bodyData.set("type", this.props.dataSource.data.type);

    Axios.post(ajaxUrl[package_version].devices_backups, bodyData).then((res) => {
      if (Array.isArray(res.data) && res.data.length == 2)
        if (res.data[1]['errorcode'] == '001') {
          this.setState({ loading: false })
          return message.error(this.props.intl.formatMessage({ id: "common.dont.permission" }))
        }

      if (res.data.success == "1") {
        this._getDeviceStatus()
        this.setState({ loading: false })
        return message.success(intl.formatMessage({ id: "devices.backups.action.stop.success" }));
      } else {
        this.setState({ loading: false })
        return message.error(intl.formatMessage({ id: "devices.backups.action.stop.error" }))
      }
    });
  }*/

  _deleteBackupQueue = (item) => {
    const { intl } = this.props;
    this.setState({ loading: true })

    const bodyData = new FormData();
    bodyData.set("act", "_deleteBackupQueue");
    bodyData.set("id", item.id);

    Axios.post(ajaxUrl[package_version].devices_backups, bodyData).then((res) => {
      if (Array.isArray(res.data) && res.data.length == 2)
        if (res.data[1]['errorcode'] == '001') {
          this.setState({ loading: false })
          return message.error(this.props.intl.formatMessage({ id: "common.dont.permission" }))
        }

      if (res.data.success == "1") {
        this.setState({ loading: false })
        this._getBackupQueue()
        return message.success(intl.formatMessage({ id: "devices.backups.action.delete.success" }));
      } else {
        this.setState({ loading: false })
        return message.error(intl.formatMessage({ id: "devices.backups.action.delete.error" }))
      }
    });
  }

  render() {
    const { intl, visible, onCancel } = this.props;
    const { pagination, pagBackups, loading, dataSourceDevices, dataSourceBackups, showModalBackupQueue,
      openModalConfirm, messageConfirm, titleConfirm, selectedDevice } = this.state;

    const columnsDevices = [
      { title: intl.formatMessage({ id: "common.device" }), dataIndex: "name", width: '200px', align: "left" },
      { title: intl.formatMessage({ id: "common.progress" }), render: this.renderProgress, key: "progress" },
      { title: intl.formatMessage({ id: "common.status" }), width: '120px', align: "center", render: (item) => this.renderStatus(item, intl), key: "status" },
      { title: intl.formatMessage({ id: "common.actions" }), align: "right", width: '90px', render: (item) => this.renderActionsDevices(item) },
    ];

    const columnsBackups = [
      { title: intl.formatMessage({ id: "common.name" }), dataIndex: "name", width: '360px', align: "left" },
      { title: intl.formatMessage({ id: "common.size" }), width: '90px', render: (item) => this.renderSize(item), key: "size", align: "center" },
      { title: intl.formatMessage({ id: "devices.backups.last.status" }), width: '140px', render: (item) => this.renderLastStatus(item), dataIndex: "last_status" },
      { title: intl.formatMessage({ id: "common.status" }), width: '65px', align: "center", render: (item) => this.renderStatus(item, intl), key: "status" },
      { title: intl.formatMessage({ id: "common.actions" }), align: "right", width: '100px', render: (item) => this.renderActionsBackupsQueue(item) },
    ];
    
    return <Fragment>
      <ConfirmDelete
        visible={openModalConfirm}
        onCancel={() => this.hideModalConfirm()}
        title={titleConfirm + " backup"}
        message={messageConfirm}
        onConfirm={() => { this.handleConfirmAction() }}
        cancelText={intl.formatMessage({ id: "common.cancel" })}
        okText={intl.formatMessage({ id: "common.confirm.ok" })}
      />
      <DraggableModal
        width={720}
        height={520}
        visible={visible}
        onCancel={onCancel}
        title={[<Fragment>{intl.formatMessage({ id: "devices.backups.backup.details" })} <Tag style={{ marginLeft: 10 }}>{this.props.dataSource.data.name.substr(0, 74)}</Tag></Fragment>]}
        footer={[
          <Button key="back" onClick={onCancel}>{intl.formatMessage({ id: "common.close" })}</Button>
        ]}>
        <Form>
          <Datagrid
            id='devices'
            columns={columnsDevices}
            dataSource={dataSourceDevices}
            loading={loading}
            filter={true}
            onChange={this.handleChange}
            pagination={pagination}
            total={pagination.total} />
        </Form>
      </DraggableModal>
      <DraggableModal
        width={820}
        height={575}
        visible={showModalBackupQueue}
        onCancel={() => this.setState({ showModalBackupQueue: false, selectedDevice: [] })}
        title={[<Fragment>{intl.formatMessage({ id: "devices.backups.backup.history" })}
          {Object.keys(selectedDevice).length > 0 &&<Tag style={{ marginLeft: 10 }}>{selectedDevice.name.substr(0, 74)}</Tag>}
        </Fragment>]}
        footer={[
          <Button key="toback" onClick={() => this.setState({ showModalBackupQueue: false, selectedDevice: [] })}>{intl.formatMessage({ id: "common.close" })}</Button>
        ]}>
        <Form>
          <Datagrid
            id='backups-to-devices'
            columns={columnsBackups}
            dataSource={dataSourceBackups}
            loading={loading}
            filter={true}
            onChange={this.handleChangeQueue}
            pagination={pagBackups}
            total={pagBackups.total} />
        </Form>
      </DraggableModal>
    </Fragment>
  }
}

export default injectIntl(Form.create({})(BackupsDevices));