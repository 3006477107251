export function getGsmPrefix() {
  let regex = /(\/device\/\d+\/)/;
  let url = window.location.pathname;
  let match = regex.exec(url);

  if (Array.isArray(match)) {
    return "../.."
  } else {
    return "";
  }
}

export const ajaxUrl = {
  "1.5": {
    groups: "/backend/1.x/policy-groups.php",
    rules: "/backend/1.x/policy-rules.php",
    templatesBase: "/ajax/policy-templates.php",
    templates: "/ajax/policy-templates-v1.5.php",
    add: "/ajax/policy-manager-rules-add-v1.5.php",
    devices_templates: "/ajax/device-templates-v1.5.php"
  },
  "2.0": {
    groups: getGsmPrefix() + "/backend/2.x/policy-groups.php",
    rules: getGsmPrefix() + "/backend/2.x/policy-rules.php",
    templatesBase: "/ajax/policy-templates.php",
    templates: "/ajax/policy-templates-v2.0.php",
    profileWebfilter: getGsmPrefix() + "/backend/2.x/profile-webfilter.php",
    ips: getGsmPrefix() + "/backend/2.x/profile-ips.php",
    sdwan: getGsmPrefix() + "/backend/2.x/profile-sdwan.php",
    ssl: getGsmPrefix() + "/backend/2.x/profile-ssl.php",
    threatProtection: getGsmPrefix() + '/backend/2.x/profile-atp.php',
    objects: getGsmPrefix() + '/backend/objects.php',
    appControl: getGsmPrefix() + '/backend/2.x/profile-appcontrol.php',
    analyzerEvents: getGsmPrefix() + '/ajax/analyzer-events.php',
    analyzerAuth: getGsmPrefix() + '/ajax/analyzer-auth.php',
    analyzerVPN: getGsmPrefix() + '/ajax/events-vpn.php',
    settingsAdmin: "/ajax/settings-admin.php",
    authServers: "/ajax/auth-servers.php",
    system: getGsmPrefix() + "/ajax/system.php",
    firewall: getGsmPrefix() + "/ajax/firewall.php",
  },
  "2.1": {
    system: getGsmPrefix() + "/ajax/system.php",
    backup: getGsmPrefix() + "/ajax/backup.php",
    objects: getGsmPrefix() + "/ajax/objects.php",
    storages: getGsmPrefix() + "/ajax/storages.php",
    profileWebfilter: getGsmPrefix() + "/backend/2.1.x/profile-webfilter.php",
    groups: getGsmPrefix() + "/backend/2.1.x/policy-groups.php",
    rules: getGsmPrefix() + "/backend/2.1.x/policy-rules.php",
    templatesBase: "/ajax/policy-templates.php",
    templates: "/ajax/policy-templates-v2.1.php",
    settings_system: '/ajax/settings-system.php',
    settings_network: '/ajax/settings-network.php',
  },
  app: {
    objects: getGsmPrefix() + "/backend/objects.php",
    objects_templates: getGsmPrefix() + "/backend/objects-templates.php"
  }
};

export const protocolsOptions = ["HTTPS", "SMTPS", "POP3S"];

export const masks = [
  { id: "0", mask: "0.0.0.0" },
  { id: "1", mask: "128.0.0.0" },
  { id: "2", mask: "192.0.0.0" },
  { id: "3", mask: "224.0.0.0" },
  { id: "4", mask: "240.0.0.0" },
  { id: "5", mask: "248.0.0.0" },
  { id: "6", mask: "252.0.0.0" },
  { id: "7", mask: "254.0.0.0" },
  { id: "8", mask: "255.0.0.0" },
  { id: "9", mask: "255.128.0.0" },
  { id: "10", mask: "255.192.0.0" },
  { id: "11", mask: "255.224.0.0" },
  { id: "12", mask: "255.240.0.0" },
  { id: "13", mask: "255.248.0.0" },
  { id: "14", mask: "255.252.0.0" },
  { id: "15", mask: "255.254.0.0" },
  { id: "16", mask: "255.255.0.0" },
  { id: "17", mask: "255.255.128.0" },
  { id: "18", mask: "255.255.192.0" },
  { id: "19", mask: "255.255.224.0" },
  { id: "20", mask: "255.255.240.0" },
  { id: "21", mask: "255.255.248.0" },
  { id: "22", mask: "255.255.252.0" },
  { id: "23", mask: "255.255.254.0" },
  { id: "24", mask: "255.255.255.0" },
  { id: "25", mask: "255.255.255.128" },
  { id: "26", mask: "255.255.255.192" },
  { id: "27", mask: "255.255.255.224" },
  { id: "28", mask: "255.255.255.240" },
  { id: "29", mask: "255.255.255.248" },
  { id: "30", mask: "255.255.255.252" },
  { id: "31", mask: "255.255.255.254" },
  { id: "32", mask: "255.255.255.255" }
];

export const badges = {
  "1.x": [
    { name: "SSL Inspection", token: "SSL" },
    { name: "Proxy", token: "PRX" },
    { name: "Web Filter", token: "WEB" },
    { name: "Deep Packet Inspection", token: "DPI" },
    { name: "Malware Scanning", token: "AV" },
    { name: "Network Address Translation", token: "NAT" },
    { name: "SD-WAN", token: "SDW" },
    { name: "Quality of Service", token: "QOS" },
    { name: "Traffic Log", token: "LOG" }
  ],
  "2.x": [
    { name: "SSL Inspection", token: "SSL" },
    { name: "Web Filter", token: "WEB" },
    { name: "Application Control", token: "APP" },
    { name: "Intrusion Prevention System", token: "IPS" },
    { name: "Advanced Threat Protection", token: "ATP" },
    { name: "Network Address Translation", token: "NAT" },
    { name: "SD-WAN", token: "SDW" },
    { name: "Quality of Service", token: "QOS" },
    { name: "Traffic Log", token: "LOG" }
  ]
};

export const compAddresses = [
  { id: "abuse", name: "Abuse" },
  { id: "anonymizers", name: "Anonymizers" },
  { id: "attacks", name: "Attacks" },
  { id: "malware", name: "Malware" },
  { id: "reputation", name: "Reputation" },
  { id: "spam", name: "Spam" }
];

export const ttl = [
  { id: "ttl-eq", name: "Equal" },
  { id: "ttl-lt", name: "Less than" },
  { id: "ttl-gt", name: "Greater than" }
];

export const packageType = [
  { id: "unicast", name: "Unicast" },
  { id: "broadcast", name: "Broadcast" },
  { id: "multicast", name: "Multicast" }
];

export const stringKey = [
  { id: "string", name: "String" },
  { id: "hex", name: "Hex" }
];

export const trafficShapping = [
  { id: "vlow", name: "Very Low" },
  { id: "low", name: "Low" },
  { id: "medium", name: "Medium" },
  { id: "high", name: "High" },
  { id: "vhigh", name: "Very High" }
];

export const flagPacketsDSCP = [
  { id: "BE", name: "BE (Best Effort)" },
  { id: "EF", name: "EF (Expedited Forwarding)" },
  { id: "AF11", name: "AF11 (Assured Forwarding) Priority Low" },
  { id: "AF12", name: "AF12 (Assured Forwarding) Priority Medium" },
  { id: "AF13", name: "AF13 (Assured Forwarding) Priority High" },

  { id: "AF21", name: "AF21 (Assured Forwarding) Immediate Low" },
  { id: "AF22", name: "AF22 (Assured Forwarding) Immediate  Medium" },
  { id: "AF23", name: "AF23 (Assured Forwarding) Immediate High" },

  { id: "AF31", name: "AF31 (Assured Forwarding) Flash Low" },
  { id: "AF32", name: "AF32 (Assured Forwarding) Flash Medium" },
  { id: "AF33", name: "AF33 (Assured Forwarding) Flash High" },

  { id: "AF41", name: "AF41 (Assured Forwarding) Flash Override Low" },
  { id: "AF42", name: "AF42 (Assured Forwarding) Flash Override Medium" },
  { id: "AF43", name: "AF43 (Assured Forwarding) Flash Override High" },

  { id: "CS1", name: "CS1 (Class Selector) Priority" },
  { id: "CS2", name: "CS2 (Class Selector) Immediate" },
  {
    id: "CS3",
    name:
      "CS3 (Class Selector) Flash - mainly used for Voice Signaling or for Video."
  },
  { id: "CS4", name: "CS4 (Class Selector) Flash Override" },
  {
    id: "CS5",
    name: "CS5 (Class Selector) Critical -mainly used for Voice RTP."
  },
  { id: "CS6", name: "CS6 (Class Selector) Internet" },
  { id: "CS7", name: "CS7 (Class Selector) Network" }
];

export const flagPacketsTOS = [
  { id: "16", name: "Minimum wait" },
  { id: "8", name: "Maximum processing" },
  { id: "4", name: "Maximum trust" },
  { id: "2", name: "Minimum Cost" },
  { id: "0", name: "Normal priority" }
];

export const webCategories = [
  { id: "0", name: "uncategorized" },
  { id: "1", name: "abortion" },
  { id: "1.1", name: "pro-life" },
  { id: "1.2", name: "pro-choice" },
  { id: "2", name: "activism-groups" },
  { id: "3", name: "adult-material" },
  { id: "3.1", name: "adult-content" },
  { id: "3.2", name: "nudity" },
  { id: "3.3", name: "sex" },
  { id: "3.4", name: "sex-education" },
  { id: "3.5", name: "lingerie-and-swimsuit" },
  { id: "4", name: "business-and-economy" },
  { id: "4.1", name: "financial-data-and-services" },
  { id: "5", name: "drugs" },
  { id: "5.1", name: "abused-drugs" },
  { id: "5.2", name: "prescribed-medications" },
  { id: "5.3", name: "supplements-and-unregulated-compounds" },
  { id: "5.4", name: "marijuana" },
  { id: "6", name: "education" },
  { id: "6.1", name: "educational-institutions" },
  { id: "6.2", name: "cultural-institutions" },
  { id: "6.3", name: "educational-materials" },
  { id: "6.4", name: "reference-materials" },
  { id: "7", name: "entertainment" },
  { id: "7.1", name: "mp3-and-audio-download-services" },
  { id: "8", name: "gambling" },
  { id: "9", name: "games" },
  { id: "10", name: "government" },
  { id: "10.1", name: "military" },
  { id: "10.2", name: "political-organizations" },
  { id: "11", name: "health" },
  { id: "12", name: "illegal-or-questionable" },
  { id: "12.1", name: "pedophilia" },
  { id: "13", name: "information-technology" },
  { id: "13.1", name: "hacking" },
  { id: "13.2", name: "proxy-avoidance" },
  { id: "13.3", name: "search-engines-and-portals" },
  { id: "13.4", name: "web-hosting" },
  { id: "13.5", name: "url-translation-sites" },
  { id: "13.6", name: "computer-security" },
  { id: "14", name: "internet-communication" },
  { id: "14.1", name: "web-chat" },
  { id: "14.2", name: "web-mail" },
  { id: "15", name: "job-search" },
  { id: "16", name: "militancy-and-extremist" },
  { id: "17", name: "news-and-media" },
  { id: "17.1", name: "alternative-journals" },
  { id: "18", name: "productivity-categories" },
  { id: "18.1", name: "advertisements" },
  { id: "18.2", name: "freeware-and-software-download" },
  { id: "18.3", name: "instant-messaging" },
  { id: "18.4", name: "message-boards-and-forums" },
  { id: "18.5", name: "online brokerage and trading" },
  { id: "18.6", name: "pay-to-surf" },
  { id: "19", name: "bandwidth-categories" },
  { id: "19.1", name: "internet-radio-and-tv" },
  { id: "19.2", name: "streaming-media" },
  { id: "19.3", name: "peer-to-peer-file-sharing" },
  { id: "19.4", name: "personal-network-storage-and-backup" },
  { id: "19.5", name: "internet-telephony" },
  { id: "20", name: "potentially-unwanted-software" },
  { id: "20.1", name: "malicious-web-sites" },
  { id: "20.2", name: "spyware" },
  { id: "21", name: "racism-and-hate" },
  { id: "22", name: "religion" },
  { id: "22.1", name: "non-traditional-religions-and-occult-and-folklore" },
  { id: "22.2", name: "traditional-religions" },
  { id: "23", name: "shopping" },
  { id: "23.1", name: "internet-auctions" },
  { id: "23.2", name: "real-estate" },
  { id: "24", name: "social-organizations" },
  { id: "24.1", name: "professional-and-worker-organizations" },
  { id: "24.2", name: "service-and-philanthropic-organizations" },
  { id: "24.3", name: "social-and-affiliation-organizations" },
  { id: "25", name: "society-and-lifestyles" },
  { id: "25.1", name: "alcohol-and-tobacco" },
  { id: "25.2", name: "gay-or-lesbian-or-bisexual-interest" },
  { id: "25.3", name: "personals-and-dating" },
  { id: "25.4", name: "restaurants-and-dining" },
  { id: "25.5", name: "hobbies" },
  { id: "25.6", name: "social-networking-and-personal-sites" },
  { id: "26", name: "special-events" },
  { id: "27", name: "sports" },
  { id: "27.1", name: "sport-hunting-and-gun-clubs" },
  { id: "28", name: "tasteless" },
  { id: "29", name: "travel" },
  { id: "30", name: "vehicles" },
  { id: "31", name: "violence" },
  { id: "32", name: "weapons" }
];

export const portType = [
  { id: "srcdst", type: "Source/Destination" },
  { id: "range", type: "Range" }
];

export const proto_id = [];
proto_id["icmp"] = "1";
proto_id["icmpv6"] = "13";
proto_id["tcp"] = "2";
proto_id["udp"] = "3";
proto_id["ip"] = "ip";

export const protoID = [];
protoID["icmp"] = "1";
protoID["tcp"] = "6";
protoID["udp"] = "17";
protoID["gre"] = "47";
protoID["esp"] = "50";
protoID["ah"] = "51";
protoID["igmp"] = "2";
protoID["egp"] = "8";
protoID["sctp"] = "132";
protoID["rsvp"] = "46";
protoID["ospf"] = "89";
protoID["ipv4"] = "4";
protoID["ipv6"] = "41";
protoID["ipcomp"] = "108";
protoID["ipip"] = "94";
protoID["igp"] = "9";
protoID["1"] = "1";
protoID["2"] = "6";
protoID["3"] = "17";
protoID["5"] = "47";
protoID["6"] = "50";
protoID["7"] = "51";
protoID["8"] = "2";
protoID["9"] = "8";
protoID["10"] = "132";
protoID["11"] = "46";
protoID["12"] = "89";
protoID["14"] = "4";
protoID["15"] = "41";
protoID["16"] = "108";
protoID["17"] = "94";
protoID["18"] = "9";

export const protoIP = {
  0: { id: 7, name: "ah" },
  1: { id: 9, name: "egp" },
  2: { id: 6, name: "esp" },
  3: { id: 5, name: "gre" },
  4: { id: 1, name: "icmp" },
  5: { id: 8, name: "igmp" },
  6: { id: 11, name: "rsvp" },
  7: { id: 10, name: "sctp" },
  8: { id: 2, name: "tcp" },
  9: { id: 3, name: "udp" },
  10: { id: 12, name: "ospf" },
  11: { id: 14, name: "ipv4" },
  12: { id: 15, name: "ipv6" },
  13: { id: 16, name: "ipcomp" },
  14: { id: 17, name: "ipip" },
  15: { id: 18, name: "igp" },
};

export const icmpV4 = {
  0: { id: "3/7", name: "Destination Host Unknown" },
  1: { id: "3/6", name: "Destination Network Unknown" },
  2: { id: "3", name: "Destination Unreachable" },
  3: { id: "0", name: "Echo Reply" },
  4: { id: "8", name: "Echo Request" },
  5: { id: "3/1", name: "Host Unreachable" },
  6: { id: "3/0", name: "Net Unreachable" },
  7: { id: "5", name: "Redirect" },
  8: { id: "11", name: "Time Exceeded" }
};

export const icmpV6 = {
  "0": { id: 149, name: "Certification Path Advertisement" },
  "1": { id: 148, name: "Certification Path Solicitation Message" },
  "2": { id: 1, name: "Destination Unreachable" },
  "3": { id: 158, name: "Duplicate Address Confirmation" },
  "4": { id: 157, name: "Duplicate Address Request" },
  "5": { id: 129, name: "Echo Reply" },
  "6": { id: 128, name: "Echo Request" },
  "7": { id: 154, name: "FMIPv6" },
  "8": { id: 145, name: "Ha Address Discovery Reply Message" },
  "9": { id: 144, name: "Ha Address Discovery Request Message" },
  "10": { id: 139, name: "ICMP Node Information Query" },
  "11": { id: 140, name: "ICMP Node Information Response" },
  "12": { id: 142, name: "Inverse ND Advertisement Message" },
  "13": { id: 141, name: "Inverse ND Solicitation Message" },
  "14": { id: 147, name: "Mobile Prefix Advertisement" },
  "15": { id: 146, name: "Mobile Prefix Solicitation" },
  "16": { id: 132, name: "Multicast Listener Done" },
  "17": { id: 130, name: "Multicast Listener Query" },
  "18": { id: 131, name: "Multicast Listener Report" },
  "19": { id: 151, name: "Multicast Router Advertisement" },
  "20": { id: 152, name: "Multicast Router Solicitation" },
  "21": { id: 153, name: "Multicast Router Termination" },
  "22": { id: 136, name: "Neighbor Advertisement" },
  "23": { id: 135, name: "Neighbor Solicitation" },
  "24": { id: 2, name: "Packet Too Big" },
  "25": { id: 4, name: "Parameter Problem" },
  "26": { id: 137, name: "Redirect Message" },
  "27": { id: 134, name: "Router Advertisement" },
  "28": { id: 138, name: "Router Renumbering" },
  "29": { id: 133, name: "Router Solicitation" },
  "30": { id: 3, name: "Time Exceeded" },
  "31": { id: 143, name: "Version2 Multicast Listener Report" }
};

export const filterReports = [
  { id: 1, name: "logtype" },
  { id: 2, name: "src" },
  { id: 3, name: "dst" },
  { id: 4, name: "sport" },
  { id: 5, name: "dport" },
  { id: 6, name: "protocol" },
  { id: 7, name: "service" },
  { id: 8, name: "devin" },
  { id: 9, name: "devout" },
  { id: 10, name: "zonein" },
  { id: 11, name: "zoneout" },
  { id: 12, name: "client_mac" },
  { id: 13, name: "client_user" },
  { id: 14, name: "client_ip" },
  { id: 15, name: "geoip_src" },
  { id: 16, name: "geoip_dst" },
  { id: 17, name: "rule_name" },
  { id: 36, name: "rule_action" },
  { id: 18, name: "web_category" },
  { id: 19, name: "web_site" },
  { id: 20, name: "web_method" },
  { id: 21, name: "web_mime" },
  // { id: 22, name: "web_browser" },
  { id: 22, name: "ips_profile" },
  { id: 23, name: "app_name" },
  { id: 24, name: "app_category" },
  { id: 25, name: "malware_file" },
  { id: 26, name: "malware_md5" },
  { id: 27, name: "malware_status" },
  { id: 28, name: "malware_name" },
  { id: 29, name: "threat_class" },
  { id: 30, name: "threat_category" },
  { id: 31, name: "threat_sid" },
  { id: 32, name: "threat_name" },
  { id: 33, name: "threat_impact" },
  { id: 34, name: "threat_dump" },
  { id: 35, name: "threat_payload" },
  { id: 36, name: "domain" },
];

export const filterAuth = [
  { id: 0, name: "src" },
  { id: 1, name: "mac" },
  { id: 2, name: "login" },
  { id: 3, name: "user_agent" },
  { id: 4, name: "rule" },
  { id: 5, name: "reason" },
  { id: 6, name: "event" },
  { id: 7, name: "status" },
]

export const filterVPN = [
    { id: 0, name: "dst" },
    { id: 1, name: "event" },
    { id: 2, name: "protocol" },
    { id: 3, name: "src" },
    { id: 4, name: "user" },
    { id: 5, name: "virtual_adress" },
    { id: 6, name: "type" },
]

export const filterAnalysis = [
  { id: 1, value: "network", name: "Firewall" },
  { id: 2, value: "web", name: "Web Filter" },
  { id: 3, value: "apps", name: "Application Control" },
  { id: 4, value: "intrusion", name: "Intrusion Prevention" },
  { id: 5, value: "threat", name: "Threat Protection" },
  { id: 6, value: "user", name: "User Behavior" }
];

export const defaultOptionsChart = () => ({
  theme: {
    mode: "light",
    palette: "palette6"
  },
  stroke: {
    show: true,
    curve: "smooth",
    lineCap: "butt",
    colors: undefined,
    width: 0.5,
    dashArray: 0
  },
  chart: {
    width: "100%",
    toolbar: {
      show: true,
      tools: {
        download: true,
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false
      }
    }
  },
  dataLabels: {
    enabled: false
  }
});

export const datepicker_locale = {
  "en-US": {
    "lang": {
      "locale": "en_US",
      "placeholder": "Select date",
      "rangePlaceholder": ["Start date", "End date"],
      "today": "Today",
      "now": "Now",
      "backToToday": "Back to today",
      "ok": "Ok",
      "clear": "Clear",
      "month": "Month",
      "year": "Year",
      "timeSelect": "Select time",
      "dateSelect": "Select date",
      "monthSelect": "Choose a month",
      "yearSelect": "Choose a year",
      "decadeSelect": "Choose a decade",
      "yearFormat": "YYYY",
      "dateFormat": "M/D/YYYY",
      "dayFormat": "D",
      "dateTimeFormat": "M/D/YYYY HH:mm:ss",
      "monthFormat": "MMMM",
      "monthBeforeYear": true,
      "previousMonth": "Previous month (PageUp)",
      "nextMonth": "Next month (PageDown)",
      "previousYear": "Last year (Control + left)",
      "nextYear": "Next year (Control + right)",
      "previousDecade": "Last decade",
      "nextDecade": "Next decade",
      "previousCentury": "Last century",
      "nextCentury": "Next century"
    },
    "timePickerLocale": {
      "placeholder": "Select time"
    },
    "dateFormat": "YYYY-MM-DD",
    "dateTimeFormat": "YYYY-MM-DD HH:mm:ss",
    "weekFormat": "YYYY-wo",
    "monthFormat": "YYYY-MM"
  },
  "pt-BR": {
    "lang": {
      "locale": "pt_BR",
      "placeholder": "Selecionar data",
      "rangePlaceholder": ['Data inicial', 'Data final'],
      "today": "Hoje",
      "now": "Agora",
      "backToToday": "Voltar para hoje",
      "ok": "Ok",
      "clear": "Limpar",
      "month": "Mês",
      "year": "Ano",
      "timeSelect": "Selecione a hora",
      "dateSelect": "Selecione a data",
      "monthSelect": "Escolha um mês",
      "yearSelect": "Escolha um ano",
      "decadeSelect": "Escolha uma década",
      "yearFormat": "YYYY",
      "dateFormat": "M/D/YYYY",
      "dayFormat": "D",
      "dateTimeFormat": "M/D/YYYY HH:mm:ss",
      "monthFormat": "MMMM",
      "monthBeforeYear": true,
      "previousMonth": "Mês anterior (PageUp)",
      "nextMonth": "Próximo mês (PageDown)",
      "previousYear": "Último ano (Control + left)",
      "nextYear": "Próximo ano (Control + right)",
      "previousDecade": "Última década",
      "nextDecade": "Próxima década",
      "previousCentury": "Último século",
      "nextCentury": "Próximo século"
    },
    "timePickerLocale": {
      "placeholder": "Selecione a hora"
    },
    "dateFormat": "YYYY-MM-DD",
    "dateTimeFormat": "YYYY-MM-DD HH:mm:ss",
    "weekFormat": "YYYY-wo",
    "monthFormat": "YYYY-MM"
  }
}

export const regexValidString = new RegExp(/^[a-zA-Z-0-9]+$/);

export const zone_list = [{ "value": "1", "text": "LAN" }, { "value": "2", "text": "DMZ" }, { "value": "3", "text": "WAN" }];