import React, { Component, PureComponent, Fragment } from "react";
import { injectIntl } from "react-intl";
import { CustomBrandingPreview, ConfirmDelete } from "/bbui/components/";
import '/bbui/components/custom.css';
import { Form, Input, Row, Col, Checkbox, Upload, Modal, Icon, Button, Tooltip, message } from "antd";
import ColorPicker from 'rc-color-picker';
import 'rc-color-picker/assets/index.css';
import validator from 'validator';

const defaultImages = {
  favicon: "/public/branding/default/img/favicon.png",
  logo: "/public/branding/default/img/logo.svg",
  background: "/public/branding/default/img/background.jpg",
}

var defaultBranding = {
  "UTM": {
    fileList: {
      favicon: [{
        uid: '-1',
        name: "favicon",
        status: 'done',
        url: defaultImages.favicon,
        thumbUrl: defaultImages.favicon,
      }],
      logo: [{
        uid: '-1',
        name: "logo",
        status: 'done',
        url: defaultImages.logo,
        thumbUrl: defaultImages.logo,
      }
      ],
      background: [{
        uid: '-1',
        name: "background",
        status: 'done',
        url: defaultImages.background,
        thumbUrl: defaultImages.background,
      }
      ],
    },
    previewImage: {
      favicon: defaultImages.favicon,
      logo: defaultImages.logo,
      background: defaultImages.background,
    },
    color: {
      menu_section_bg_color: "#999999",
      menu_section_color: "#FFFFFF",
      menu_selected_bg_color: "#3C7C9F",
      menu_selected_color: "#FFFFFF",
      menu_open_bg_color: "#356B89",
      menu_open_color: "#CCCCCC",
      menu_open_hover_color: "#FFFFFF",
      menu_bg_color: "#E9E9E9",
      menu_color: "#4E4E4E",
      menu_base_bg_color: "#E9E9E9",

    },
    title: "BLOCKBIT | UTM"
  },
  "GSM": {
    fileList: {
      favicon: [],
      background: [],
      logo: []
    },
    color: {
      menu_section_bg_color: "",
      menu_section_color: "",
      menu_selected_bg_color: "",
      menu_selected_color: "",
      menu_bg_color: "",
      menu_color: "",
      menu_base_bg_color: "",
    },
    previewImage: {
      favicon: "",
      logo: "",
      background: "",
    },
    title: ""
  }
}

class CustomBranding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...JSON.parse(JSON.stringify(defaultBranding[this.props.product])),
      accept: {
        favicon: ".png, .ico",
        logo: ".svg",
        background: ".jpg, .jpeg, .png"
      },
      imageExtension: {
        favicon: ["image/png", "image/x-icon"],
        logo: ["image/svg+xml"],
        background: ["image/jpeg", "image/png"]
      },
      size: {
        favicon: { width: 32, height: 32 },
        logo: { width: 300, height: 65 },
        background: { width: 1920, height: 1080 },
      },
      showConfirmRestore: false,
      previewVisible: {
        favicon: false,
        logo: false,
        background: false
      },
      branding_default: true
    }
  }

  initValues = this.props.getData.initValues();

  componentDidMount() {
    this.initValues(this.props.form).then(() => this.setStateValues());
  }

  colorsName = [
    "menu_section_bg_color",
    "menu_section_color",
    "menu_selected_bg_color",
    "menu_selected_color",
    "menu_open_bg_color",
    "menu_open_color",
    "menu_open_hover_color",
    "menu_bg_color",
    "menu_color",
    "menu_base_bg_color"
  ]

  setStateValues = () => {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    ["favicon", "logo", "background"].map(type => {

      if (getFieldValue(type) != "" && getFieldValue(type) != undefined) {
        const { fileList, previewImage } = this.state;
        fileList[type] = [{
          uid: '-1',
          name: type,
          status: 'done',
          url: getFieldValue(type),
          thumbUrl: getFieldValue(type),
        }];
        previewImage[type] = getFieldValue(type)
        this.setState({ ...fileList, ...previewImage })
      }
    });

    this.colorsName.map(item => {
      if (getFieldValue(item) != "" && getFieldValue(item) != undefined) {
        const { color } = this.state;
        this.setState({ color: { ...color, [item]: getFieldValue(item) } })
      }
    })

  }

  handleUpload = (type, { fileList }) => {
    const { intl } = this.props;
    if (fileList.length > 0) {
      this.validateImage(type, fileList).then((value) => {
        if (value == true) {
          this.getBase64(type, fileList[0].originFileObj).then((value) => {
            fileList[0]["thumbUrl"] = value
            this.setState({ fileList: { ...this.state.fileList, [type]: fileList }, previewImage: { ...this.state.previewImage, [type]: value } })
            this.props.form.setFieldsValue({ [type]: value })
            this.props.form.setFieldsValue({ [type + "_file"]: fileList[0].originFileObj })
            this.setBrandingDefault(false)
          })
        } else {
          this.props.form.setFields({
            [type]: {
              value: "",
              errors: [new Error(intl.formatMessage({ id: "common.error.invalid" }, { name: intl.formatMessage({ id: `devices.template.custom.${type}` }) }))]
            }
          })
        }
      })
    }
  };

  validateImage = (type, fileList) => {
    const { intl } = this.props;
    return new Promise((resolve, reject) => {
      if (this.state.imageExtension[type].find(i => i == fileList[0]["type"]) == undefined) {
        message.error(intl.formatMessage({ id: `devices.template.custom.${type}.recommended` }))
        resolve(false)
      }
      let isLt2M = fileList[0].size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error(intl.formatMessage({ id: "devices.template.custom.upload.error.size" }))
        resolve(false)
      }
      const { size } = this.state
      var file, img;
      var _URL = window.URL || window.webkitURL;
      img = new Image();
      var objectUrl = _URL.createObjectURL(fileList[0].originFileObj);
      img.onload = function () {
        if (type == "logo") {
          var ratio = Math.round((parseInt(this.height) / parseInt(this.width)) * 100) / 100
          if (ratio > 0.25 && ratio < 0.5) {
            resolve(true);
          } else {
            message.error(intl.formatMessage({ id: "devices.template.custom.upload.error.dimension" }))
            resolve(false)
          }
        }
        
        if (type == "favicon") {
          var ratio = parseInt(this.height) / parseInt(this.width)
          if (ratio != 1) {
            message.error(intl.formatMessage({ id: "devices.template.custom.upload.error.dimension" }))
            resolve(false)
          }
        }

        if (parseInt(this.width) < size[type].width || parseInt(this.height) < size[type].height) {
          message.error(intl.formatMessage({ id: "devices.template.custom.upload.error.dimension" }))
          resolve(false)
        } else {
          resolve(true)
        }

        _URL.revokeObjectURL(objectUrl);
      };
      img.src = objectUrl;

    }).then((result) => {
      return result
    });
  }

  onRemove = (type, file) => {
    const { fileList } = this.state;
    const index = fileList[type].indexOf(file);
    const newFileList = fileList[type].slice();
    newFileList.splice(index, 1);

    this.setState({ fileList: { ...this.state.fileList, [type]: newFileList }, previewImage: { ...this.state.previewImage, [type]: defaultImages[type] } });
    //this.props.form.setFieldsValue({ [type]: defaultImages[type] })
    this.props.form.setFieldsValue({ [type]: undefined })
  }

  handlePreview = (type) => {
    this.setState({ previewVisible: { ...this.state.previewVisible, [type]: true } });
  };

  handleCancel = (type) => {
    this.setState({ previewVisible: { ...this.state.previewVisible, [type]: false } });
  }

  getBase64 = (type, file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    }).then((result) => {
      return result
    });
  }

  changeColor = (color, field) => {
    const { setFieldsValue } = this.props.form
    setFieldsValue({ [field]: color.color })
    this.setState({
      color: { ...this.state.color, [field]: color.color },
    });
    this.setBrandingDefault(false)
  }

  restoreBranding = () => {
    this.props.form.resetFields([...this.colorsName, "title", "favicon", "background", "logo"]);

    if (this.props.product == "UTM") {
      ["favicon", "logo", "background"].map(type => {
        this.props.form.setFieldsValue({ [type]: defaultImages[type] })
      })
    }

    this.setState({
      color: defaultBranding[this.props.product].color,
      fileList: defaultBranding[this.props.product].fileList,
      previewImage: defaultBranding[this.props.product].previewImage,
      title: defaultBranding[this.props.product].title,
      showConfirmRestore: false,
    })

    this.setBrandingDefault(true)

    if (typeof (this.props.restoreBranding) == 'function') this.props.restoreBranding();

  }

  renderUpload = (type) => {
    const { intl, lockForm } = this.props;
    const { getFieldValue } = this.props.form;
    const { fileList, previewImage, previewVisible, accept } = this.state

    const uploadButton = (
      <Button disabled={lockForm || !getFieldValue("custom_enabled")}>
        <Icon type="upload" />{intl.formatMessage({ id: "devices.template.custom.upload" })}
      </Button>
    );

    return <div className="clearfix">
      <Upload
        className={"custom_" + type}
        disabled={lockForm || !getFieldValue("custom_enabled")}
        fileList={fileList[type]}
        accept={accept[type]}
        onChange={(file) => this.handleUpload(type, file)}
        beforeUpload={() => false}
        onPreview={() => this.handlePreview(type)}
        listType="picture-card"
        onRemove={(file) => this.onRemove(type, file)}>
        {fileList[type].length == 0 && uploadButton}
      </Upload>
      {fileList[type].length > 0 && <Modal
        disabled={lockForm || !getFieldValue("custom_enabled")}
        className={"modal_custom_" + type}
        visible={previewVisible[type]}
        footer={null}
        onCancel={() => this.handleCancel(type)}
      >
        <img src={previewImage[type]} />
      </Modal>}
    </div>
  }

  setColor = (value, field) => {
    if (value && validator.isHexColor(value)) {
      this.setState({ color: { ...this.state.color, [field]: value } }, () => {
        if (typeof (this.props.getData) == 'function') this.props.getData(this.props.form);
      })
      this.setBrandingDefault(false)
    }
  }

  setTitle = (value) => {
    this.setState({ title: value }, () => { if (typeof (this.props.getData) == 'function') this.props.getData(this.props.form) })
    this.setBrandingDefault(false)
  }

  setBrandingDefault = (value = false) => {
    this.setState({ branding_default: value })
    this.props.form.setFieldsValue({ "branding-default": value })
  }

  renderColor = (field) => {
    const { intl, lockForm } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formLayout = { colon: false, style: { paddingBottom: 0 } };
    const { color } = this.state

    return <Row gutter={24} style={{ height: 63, marginBottom: 8, paddingRight: this.props.product == "UTM" ? 6 : 12 }}>
      {field != "" && <Fragment><span className="label_colors"><span className="required">*</span>{intl.formatMessage({ id: "devices.template.custom.color." + field })}</span>
        <Col span={this.props.product == "UTM" ? 16 : 20} style={{ paddingLeft: 0, paddingRight: 6 }}>
          {<Form.Item {...formLayout}>
            {getFieldDecorator(field, {
              initialValue: color[field],
              rules: [
                { required: true, message: intl.formatMessage({ id: "common.required.field" }) },
                {
                  validator: (rule, value, cb) => {
                    if (value && !validator.isHexColor(value)) {
                      cb(intl.formatMessage({ id: "common.invalid.value" }))
                    } else {
                      cb()
                    }
                  }
                }]
            })(
              <Input disabled={lockForm || !getFieldValue("custom_enabled")} onChange={(e) => this.setColor(e.target.value, field)} maxLength={7} />
            )}
          </Form.Item>}
        </Col>
        <Col span={4} style={{ paddingLeft: 0, paddingRight: 6 }}>
          <Form.Item {...formLayout}>
            <ColorPicker color={color[field] || "#999999"} onChange={(color) => this.changeColor(color, field)} enableAlpha={false}>
              <Input className="rc-color-picker-trigger" disabled={lockForm || !getFieldValue("custom_enabled")} />
            </ColorPicker>
          </Form.Item>
        </Col></Fragment>}
    </Row>
  }

  render() {
    const { intl, lockForm } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { previewImage, color, title, showConfirmRestore } = this.state
    const formLayout = { colon: false, style: { paddingBottom: 0 } };

    { this.props.product == "GSM" && getFieldDecorator('custom_enabled', { initialValue: true }) }

    getFieldDecorator('favicon_file')
    getFieldDecorator('logo_file')
    getFieldDecorator('background_file')
    getFieldDecorator('branding-default', { initialValue: true })

    return <Fragment>
      <Row gutter={24}>
        <Col span={16}>
          {this.props.product == "UTM" && <Form.Item {...formLayout}>
            {getFieldDecorator('custom_enabled', {
              valuePropName: "checked",
              rules: [{ required: false, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "common.enabled" }) }) }]
            })(
              <Checkbox disabled={lockForm}>
                {intl.formatMessage({ id: "common.enabled" })}
              </Checkbox>
            )}
          </Form.Item>}
        </Col>
        <Col span={8} style={{ textAlign: "right" }}>
          <div className="ui-datagrid-controls">
            <Button disabled={lockForm || !getFieldValue("custom_enabled")} onClick={() => this.setState({ showConfirmRestore: true })}>{intl.formatMessage({ id: "devices.template.custom.restore" })}</Button>
            {this.props.actions}
          </div>
        </Col>
      </Row>
      <Row gutter={24} className={"row-title"}>
        <Col span={24}>
          <Form.Item {...formLayout} label={intl.formatMessage({ id: "devices.template.custom.title" })}>
            {getFieldDecorator("title", {
              initialValue: title,
              rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "devices.template.custom.title" }) }) }]
            })(
              <Input maxLength={100} onChange={(e) => this.setTitle(e.target.value)} disabled={lockForm || !getFieldValue("custom_enabled")} placeholder={intl.formatMessage({ id: "devices.template.custom.title" })} />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} className={"row-images"}>
        <Col span={8}>
          <Form.Item {...formLayout} label={<Fragment>{intl.formatMessage({ id: "devices.template.custom.favicon" })} <Tooltip title={intl.formatMessage({ id: "devices.template.custom.favicon.recommended" })}><Icon type="info-circle" /></Tooltip></Fragment>}>
            {getFieldDecorator("favicon", {
              initialValue: this.props.product == "UTM" ? defaultImages["favicon"] : "",
              rules: [
                { required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "devices.template.custom.favicon" }) }) }]
            })(
              this.renderUpload("favicon")
            )}
          </Form.Item>
        </Col>
        <Col span={8} style={{ paddingLeft: 0 }}>
          <Form.Item {...formLayout} label={<Fragment>{intl.formatMessage({ id: "devices.template.custom.logo" })} <Tooltip title={intl.formatMessage({ id: "devices.template.custom.logo.recommended" })}><Icon type="info-circle" /></Tooltip></Fragment>}>
            {getFieldDecorator("logo", {
              initialValue: this.props.product == "UTM" ? defaultImages["logo"] : "",
              rules: [
                { required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "devices.template.custom.logo" }) }) }]
            })(
              this.renderUpload("logo")
            )}
          </Form.Item>
        </Col>
        <Col span={8} style={{ paddingLeft: 0 }}>
          <Form.Item {...formLayout} label={<Fragment>{intl.formatMessage({ id: "devices.template.custom.background" })} <Tooltip title={intl.formatMessage({ id: "devices.template.custom.background.recommended" })}><Icon type="info-circle" /></Tooltip></Fragment>}>
            {getFieldDecorator("background", {
              initialValue: this.props.product == "UTM" ? defaultImages["background"] : "",
              rules: [
                { required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "devices.template.custom.background" }) }) }]
            })(
              this.renderUpload("background")
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginTop: 0 }}>
        <Col span={16}>
          <Form.Item {...formLayout} label={intl.formatMessage({ id: "devices.template.custom.preview" })}>

            <div style={{ border: "1px solid #d9d9d9", borderRadius: 4, width: "100%", height: 389 }} className={"preview"}>
              {this.props.showPreview == true && <CustomBrandingPreview
                product={this.props.product}
                color={color}
                logo={previewImage.logo}
                favicon={previewImage.favicon}
                title={getFieldValue("title")} />}
            </div>

          </Form.Item>
        </Col>
        <Col span={4}>{/* COLORS COL 1 */}
          {this.renderColor("menu_section_bg_color")}
          {this.renderColor("menu_selected_bg_color")}
          {this.props.product == "UTM" && this.renderColor("menu_open_bg_color")}
          {this.props.product == "UTM" && this.renderColor("menu_open_color")}
          {this.renderColor("menu_bg_color")}
          {this.renderColor("menu_base_bg_color")}
        </Col>
        <Col span={4}> {/* COLORS COL 2 */}
          {this.renderColor("menu_section_color")}
          {this.renderColor("menu_selected_color")}
          {this.props.product == "UTM" && this.renderColor("")}
          {this.props.product == "UTM" && this.renderColor("menu_open_hover_color")}
          {this.renderColor("menu_color")}
          {this.props.product == "UTM" && this.renderColor("")}
        </Col>
      </Row>
      <ConfirmDelete
        visible={showConfirmRestore}
        onCancel={() => this.setState({ showConfirmRestore: false })}
        title={intl.formatMessage({ id: "devices.template.custom.restore" })}
        message={intl.formatMessage({ id: "devices.template.custom.restore.confirm" })}
        onConfirm={() => this.restoreBranding()}
        okText={intl.formatMessage({ id: "common.confirm.ok" })}
      />
    </Fragment>
  }
}

CustomBranding.defaultProps = {
  product: "UTM",
  showPreview: true
};

export default injectIntl(Form.create({ name: 'config_branding' })(CustomBranding));