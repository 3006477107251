// Verifica se é domínio, IP, subdomínio ou subdomínio com asterisco
export const sortSize = (value) => {
  let val = JSON.parse(JSON.stringify(value))
  let arrayValue = [];
  let highestValue = 0;
  if (val.length > 1) {
    arrayValue = val.sort((a, b) => b - a);
    highestValue = arrayValue[0];
  } else {
    highestValue = val;
  }
  var k = 1024,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(highestValue) / Math.log(k));
  return sizes[i];
}

export const valueSortSize = (bytes) => {
  var topBytes = [];
  var sizeBytes = '';

  topBytes.push(bytes);
  sizeBytes = sortSize(topBytes);

  return sizeBytes;
}

export const formatHits = (hits) => {
  return decimal(hits.toFixed())
}

function decimal(val) {
  var value = val + '';
  value = value.replace(/\s+/, '');

  if (!/^[\d|\.]+$/.test(value))
    return 0;

  value = value.replace(/\.0$/, '');

  if (value.indexOf('.') != -1) {
    return value;
  }

  var tam = value.length;
  var temp = '';
  for (var i = (tam - 1); i >= 0; i--) {
    temp = value.charAt(i) + temp;
    if ((value.substr(i).length % 3 == 0) && (i > 0)) {
      temp = '.' + temp;
    }
  }
  return temp;
}

export const formatBytes = (bytes, decimals, size) => {
  if (typeof size == "undefined") return bytes
  let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let a
  sizes.map((i, idx) => i == size ? a = idx : '')
  let k = 1024,
    dm = decimals <= 0 ? 0 : decimals || 2;
  size == 'Bytes' ? dm = 0 : dm = 2;
  return (bytes / Math.pow(k, a)).toFixed(dm) + ' ' + size;
}

export const formatHour = (time) => {
  let formatedTime;
  formatedTime = time.map(i => i.split(":").length == 3 ? i.split(":")[0]+':'+i.split(":")[1] : i.split(":").length == 2 ? i.split(":")[0] + 'h' : i);
  return formatedTime;
}

function formatDateToEvents(props) {
  let data = props.filters
  const { product } = props;
  const { type } = props;
  if (type == "yesterday" || type == "today" || type == "last_7" || type == "last_30" || type == "this_month" || type == "last_month" || type == "last_10m" || type == "last_6h" || type == "last_12h" || type == "last_18h") {
    data = type;
  } else {
    let data_ini;
    let data_end;
    data = props.filters
    let delimiter = '';
    if (product == 'GSM') {
      delimiter = '/'
      data_ini = data.date_ini.split(delimiter)
      if(Array.isArray(data_ini) && data_ini.length > 1){
        data_ini = data_ini[2] + "/" + data_ini[1] + "/" + data_ini[0]
        data_end = data.date_end.split(delimiter)
        data_end = data_end[2] + "/" + data_end[1] + "/" + data_end[0]
        data = (data_ini) + '~' + (data_end)
        data = data.replace(/\//g, "-")
      }else{
        data = (data.date_ini) + '~' + (data.date_end)
      } 
    } else {
      data = data.date_ini + '~' + data.date_end
      // data_ini = data_ini[2] + "/" + data_ini[1] + "/" + data_ini[0]
      // data_end = data.date_end.split(delimiter)
      // data_end = data_end[2] + "/" + data_end[1] + "/" + data_end[0]
      // data = (data_ini) + '~' + (data_end)
      // data = data.replace(/\//g, "-")
    }
    //   date:"2020-03-01 17:43:43~2020-03-14 17:43:43"
    //   date:"2020-02-01 17:44:23" date_end: "2020-03-28 17:44:23"}

  }
  return data
}

function formatDevicesToEvents(props) {
  let device = ''
  let data = props.filters
  device = data.device_type + "^" + data.device_id
  return device;
}

export const redirectUsers = (item, type, logtype, props, vpn = null) => {
  const { filters } = props;
  const { product } = props;
  let device = formatDevicesToEvents(props);
  let name, data, query, uri = '', url = ''
  if (product == "UTM") {
    device = 'local'
    data = formatDateToEvents(props);
    name = type == 'web_site' ? "%" + item.name + "%" : item.name 
    query = `${type}:"${name}" logtype:"${logtype}" date:"${data}"`
    query = logtype == 'vpn' ? `${type}:"${name}" date:"${data}"` : query
    query = window.encodeURIComponent(query);
    url = getGsmPrefix()+`/admin/apps/dashboard-events.php?q=${query}`;
    if (vpn != null) {
      url += `&v=1`;
    }
    uri = encodeURI(url.replace('//admin', '/admin'));
  } else {
    data = formatDateToEvents(props);
    name = type == 'web_category' ? item.name_cat : item.name
    query = `${type}:"${name}" logtype:"${logtype}" date:"${data}"`
    query = window.encodeURIComponent(query);
    uri = encodeURI(`/events/${device}/${query}`)
  }
  return uri
}

export const redirectUsers2 = (item, type, item2, type2, logtype, props) => {

  // console.log("redirectUsers2", item, type, logtype, item2, type2, props)

  const { filters } = props;
  const { product } = props;
  let query = ''
  let uri = ''
  let data = ''
  let url = ''
  let device = formatDevicesToEvents(props);
  if (product == "UTM") {

    item = type == 'web_site' ? "%" + item + "%" : item;
    item2 = type2 == 'web_site' ? "%" + item2 + "%" : item2;

    data = formatDateToEvents(props);
    if (!!item && !!item2 && !!type && !!type2 && (type == 'client_user' || type2 == 'client_user')) {
      query = `${type}:"${item}" ${type2}:"${item2}" logtype:"${logtype}" date:"${data}"`
    } else 
    if (item != null && item != '') {
      query = `${type}:"${item}" logtype:"${logtype}" date:"${data}"`
    } else if (item2 != null && item2 != '') {
      query = `${type2}:"${item2}" logtype:"${logtype}" date:"${data}"`
    }
    query = window.encodeURIComponent(query);
    url = getGsmPrefix()+`/admin/apps/dashboard-events.php?q=${query}`;
    uri = encodeURI(url.replace('//admin', '/admin'));
  }else{
    data = formatDateToEvents(props);
    if (!!item && !!item2 && !!type && !!type2) {
      query = `${type}:"${item}" ${type2}:"${item2}" logtype:"${logtype}" date:"${data}"`
    } else if (item != null && item != '') {
      query = `${type}:"${item}" logtype:"${logtype}" date:"${data}"`
    } else if (item2 != null && item2 != '') {
      query = `${type2}:"${item2}" logtype:"${logtype}" date:"${data}"`
    }
    query = window.encodeURIComponent(query);
    uri = encodeURI(`/events/${device}/${query}`)
  }
  return uri
}

export const dateType = (type, product) => {
  let allowedDateType;
  
  if(product == "GSM") {
    allowedDateType = true; 
  } else {
    allowedDateType = ["last_10m", "last_6h", "last_12h", "last_18h", "today", "yesterday", "date"].includes(type);  
  }

  return allowedDateType;
}

export const isURL = (rule, values, callback) => {
  //const domainRegex = /^(\*\.)?([\w-]+\.)+[\w-]+$/;
  const domainRegex = /([a-z0-9]+\.)*[a-z0-9]+[a-z]+/;

  let invalidInputs = values.filter((value) => {
    return !value.match(domainRegex)
  });

  if (invalidInputs.length === 0) {
    callback()
  } else if (invalidInputs.length === 1) {
    callback(invalidInputs.join('') + ' is not a valid');
  } else {
    callback(invalidInputs.slice(0, -1).join(', ') + ' and ' + invalidInputs.slice(-1) + ' are not valid');
  }
}

export const convertMAC = (mac) => {
  return mac.replace(/[-;\.\s:\*#%,+_]/g, ':').toUpperCase();
}

// Coloca a primeira letra em maiúscula
export const firstUpper = str => {
  return (str != undefined) ? str.toLowerCase().replace(/(?:^|\s)(?!da|de|do)\S/g, l => l.toUpperCase()) : str;
};

// valida uma máscara de IP
export const validIPMask = (ipmask) => {
  var array = ipmask.split("/");

  if (array.length < 2) {
    return validIP(ipmask);
  }

  if (!validIP(array[0])) {
    return false;
  }

  if (!convertMask(array[1], 'decimal')) {
    return false;
  }

  return true;
}

// retorna uma máscara de IP
export const returnIpMask = (ipmask) => {
  var array = ipmask.split("/");
  var ip = '';
  
  array[0] ? ip = array[0] : ip = ipmask;

  if(array[1])
  {
    var resultMask = convertMask(array[1], 'other');
    resultMask != undefined ? ip += '/'+resultMask : ip += '/'+array[1];
  }

  return ip;
}

export const validIPV6Mask = (ipmask) => {
  var array = ipmask.split("/");
  if (array.length < 2)
    return validIPV6(ipmask);

  if (!validIPV6(array[0]))
    return false;

  if (!validIPV6Prefix(array[1]))
    return false;

  return true;
}


function validIPV6Prefix(prefixo) {
  if (isNaN(prefixo) || prefixo < 0)
    return false;

  if (prefixo < 0 || prefixo > 128)
    return false;

  return true;
}


export function convertMask(val, type) {
  var hash = new Object();
  if (type == 'decimal') {
    hash["0.0.0.0"] = "0";
    hash["128.0.0.0"] = "1";
    hash["192.0.0.0"] = "2";
    hash["224.0.0.0"] = "3";
    hash["240.0.0.0"] = "4";
    hash["248.0.0.0"] = "5";
    hash["252.0.0.0"] = "6";
    hash["254.0.0.0"] = "7";
    hash["255.0.0.0"] = "8";
    hash["255.128.0.0"] = "9";
    hash["255.192.0.0"] = "10";
    hash["255.224.0.0"] = "11";
    hash["255.240.0.0"] = "12";
    hash["255.248.0.0"] = "13";
    hash["255.252.0.0"] = "14";
    hash["255.254.0.0"] = "15";
    hash["255.255.0.0"] = "16";
    hash["255.255.128.0"] = "17";
    hash["255.255.192.0"] = "18";
    hash["255.255.224.0"] = "19";
    hash["255.255.240.0"] = "20";
    hash["255.255.248.0"] = "21";
    hash["255.255.252.0"] = "22";
    hash["255.255.254.0"] = "23";
    hash["255.255.255.0"] = "24";
    hash["255.255.255.128"] = "25";
    hash["255.255.255.192"] = "26";
    hash["255.255.255.224"] = "27";
    hash["255.255.255.240"] = "28";
    hash["255.255.255.248"] = "29";
    hash["255.255.255.252"] = "30";
    hash["255.255.255.254"] = "31";
    hash["255.255.255.255"] = "32";
  } else {
    hash["0"] = "0.0.0.0";
    hash["1"] = "128.0.0.0";
    hash["2"] = "192.0.0.0";
    hash["3"] = "224.0.0.0";
    hash["4"] = "240.0.0.0";
    hash["5"] = "248.0.0.0";
    hash["6"] = "252.0.0.0";
    hash["7"] = "254.0.0.0";
    hash["8"] = "255.0.0.0";
    hash["9"] = "255.128.0.0";
    hash["10"] = "255.192.0.0";
    hash["11"] = "255.224.0.0";
    hash["12"] = "255.240.0.0";
    hash["13"] = "255.248.0.0";
    hash["14"] = "255.252.0.0";
    hash["15"] = "255.254.0.0";
    hash["16"] = "255.255.0.0";
    hash["17"] = "255.255.128.0";
    hash["18"] = "255.255.192.0";
    hash["19"] = "255.255.224.0";
    hash["20"] = "255.255.240.0";
    hash["21"] = "255.255.248.0";
    hash["22"] = "255.255.252.0";
    hash["23"] = "255.255.254.0";
    hash["24"] = "255.255.255.0";
    hash["25"] = "255.255.255.128";
    hash["26"] = "255.255.255.192";
    hash["27"] = "255.255.255.224";
    hash["28"] = "255.255.255.240";
    hash["29"] = "255.255.255.248";
    hash["30"] = "255.255.255.252";
    hash["31"] = "255.255.255.254";
    hash["32"] = "255.255.255.255";
  }

  return hash[val.trim()];
}

function validIP(ipaddr) {
  if (!ipaddr)
    return false;
  var regex = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/;
  if (ipaddr == "255.255.255.255")
    return false;
  var iparray = ipaddr.match(regex);
  if (iparray == null) {
    return false;
  }
  for (var j = 1; j < 6; j++) {
    var octet = iparray[j];

    if (octet > 255 || octet < 0) {
      return false;
    }

    if ((j == 0) && (octet > 255)) {
      return false;
    }

  }

  return true;
}

function validIPV6(ipaddr) {
  if (!ipaddr)
    return false;
  var regex = /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/;
  var iparray = ipaddr.match(regex);
  if (iparray == null)
    return false;
  return true;
}

/**
 * Recebe um valor em bytes, transforma em bits e retorna uma string que representa o
 * valor submetido
 *
 * Ex: bytesToHuman(10) retorna "80 b/s"
 * @param bytes
 * @returns {string|boolean}
 */
export function bytesToHuman(bytes) {
  if (bytes == null) return "0 bps";

  const bits = bytes * 8;

  if (bits >= 1000000000000) {
    var val = parseFloat(bits / 1000000000000);
    val = Math.round(val * Math.pow(10, 2)) / Math.pow(10, 2);
    return val + " Tb/s";
  } else if (bits >= 1000000000) {
    var val = parseFloat(bits / 1000000000);
    val = Math.round(val * Math.pow(10, 2)) / Math.pow(10, 2);
    return val + " Gb/s";
  } else if (bits >= 1000000) {
    var val = parseFloat(bits / 1000000);
    val = Math.round(val * Math.pow(10, 2)) / Math.pow(10, 2);
    return val + " Mb/s";
  } else if (bits >= 1000) {
    var val = parseFloat(bits / 1000);
    val = Math.round(val * Math.pow(10, 2)) / Math.pow(10, 2);
    return val + " Kb/s";
  } else {
    var val = parseFloat(bits);
    val = Math.round(val * Math.pow(10, 2)) / Math.pow(10, 2);
    return val + " b/s";
  }
  return false;
}

export function getGsmPrefix() {
  let regex = /(\/device\/\d+\/)/;
  let url = window.location.pathname;
  let match = regex.exec(url);

  if (Array.isArray(match)) {
    return match[0]
  } else {
    return "";
  }
}

export const removeEmpty = obj => {
  const newObj = {};

  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === "object") {
      newObj[key] = removeEmpty(obj[key]); // recurse
    } else if (obj[key] != null) {
      newObj[key] = obj[key]; // copy value
    }
  });

  return newObj;
};


export const getCookie = name => {
  let cookie = {};
  document.cookie.split(';').forEach(function (el) {
    let [k, v] = el.split('=');
    cookie[k.trim()] = v;
  })
  return cookie[name];
}