import React, { Component, Fragment } from "react";
import { Card, Col, Row, notification } from "antd";
import LoginForm from "../modules/login/loginForm";
import RetrieveForm from "../modules/login/retrieveForm";
import { product } from "/config/constants";
import UserProfile from '/modules/UserProfile';
import Axios from "axios";
import { ajaxUrl } from "/config/constants";
import AxiosFormData from "/helpers/AxiosFormData";
import { withRouter } from "react-router-dom";
import { message } from 'antd';

import Disclaimer from "/bbui/components/Disclaimer"

import "/bbui/public/css/ui.css";

import { FormattedMessage, injectIntl } from "react-intl";
const package_version = product == 'GSM' ? 'gsm' : '2.1'

class LoginLayout extends Component {
  constructor(props) {
    super(props);
    this.state = { loginShow: true, customBranding: true };
  }
  handleToggle = () => {
    const { loginShow } = this.state;
    this.setState({ loginShow: !loginShow })
  }

  handleSso = () => {
    window.open("/saml/login.php?act=login", "_self")
  }

  checkAuthSaml = () => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    let act = url.searchParams.get("act") || null;
    if (act == 'saml') {
      let email = url.searchParams.get("mail") || null;
      let uid = url.searchParams.get("name") || null;
      if(email == null || uid == null){
        message.error(this.props.intl.formatMessage({ id: "common.server.error"}))
        return;
      }
      const bodyData = new FormData();
      bodyData.set("act", "loginSaml");
      bodyData.set("adm_users-name", uid);
      bodyData.set("adm_users-email", email);
      bodyData.set("adm_users-type", 'local');
      Axios.post("/saml/auth-local.php", bodyData)
        .then((res) => {
          const { history } = this.props
          if (res.data.msg == 1) {
            UserProfile.set(res.data.user.name, res.data.user.email, true);
            this.props.checkIntervalFunc()
            history.push("/");
          }
        })
    }
  }


  componentDidMount() {
    this.checkCustomBranding()
    if (product == "GSM") {
      if(typeof this.props.checkDisclaimer == "function") {
        this.props.checkDisclaimer()
        this.props.checkSaml()
      }
      this.checkAuthSaml();
      let paramsURL = window.location.search.substring(1);
      if(paramsURL != "" &&  paramsURL.split("=")[1] == 'saml_error'){
        message.error(this.props.intl.formatMessage({ id: 'administration.identity.login.error' }),5)
      }
    }

  }

  showWarning = () => {
    const { intl } = this.props;

    notification.destroy();
    notification.warning({
      duration: 30,
      message: intl.formatMessage({ id: 'common.warning' }),
      description: intl.formatMessage({ id: 'highAvailability.warning' })
    })
  }

  checkCustomBranding = () => {
    Axios.post(ajaxUrl[package_version].login, AxiosFormData({ act: "checkCustomBranding" }))
      .then(res => {
        let customBranding = true;
        if (res.data != null) {
          customBranding = res.data
        }
        this.setState({
          customBranding
        })
        window.localStorage.setItem('customBranding', customBranding);
      })
  }


  render() {
    const { loginShow, customBranding } = this.state;
    const { intl, disclaimerObj, sso_enabled } = this.props;
    const { domainString, handleLogin, imageAlt, imageTitle, image, languages, lockForm, loginPlaceholder, passwordPlaceholder, emailRequired, passwordRequired, slave, serverName } = this.props;

    let name = customBranding == 1 ? '' : "BLOCKBIT";
    let imgAlt = customBranding == 1 ? product : imageAlt;
    let imgTitle = customBranding == 1 ? 'Powered by ' + product : imageTitle;


    if (product == 'UTM' && slave) {
      this.showWarning();
    }
    return <div className="login-bg">
      <Row type="flex" justify="space-around" align="middle" style={{ height: "100%", textAlign: "center" }}>
        <Col style={{ width: "380px", top: "-10%" }}>
          <Card className="login-card">
            <div>
              <img className="logo-login" src={image} alt={imgAlt} title={imgTitle} />
              {domainString && <p>{domainString}</p>}
            </div>
            {this.props.children ?
              this.props.children :
              <Fragment>
                {product == 'UTM' && <div style={{ fontWeight: "bold" }}>{serverName}</div>}
                <h3 style={{ fontWeight: "bold" }}><FormattedMessage id="common.login.form" /></h3>

                {loginShow &&
                  <LoginForm
                    sso_enabled={sso_enabled}
                    forgotPassword={product == 'GSM'}
                    lockForm={lockForm}
                    languages={languages}
                    loginPlaceholder={loginPlaceholder}
                    emailRequired={emailRequired}
                    passwordRequired={passwordRequired}
                    passwordPlaceholder={passwordPlaceholder}
                    handleChangeLang={this.props.handleChangeLang}
                    language={this.props.language}
                    handleLogin={handleLogin}
                    onToggle={this.handleToggle}
                    onSSO={this.handleSso} />}
                {!loginShow && <RetrieveForm onToggle={this.handleToggle} handleRetrieve={this.props.handleRetrieve} emailRequired={emailRequired} />}


                <Disclaimer
                  disclaimerObj={disclaimerObj}
                  type="pre"
                  handleDisclaimer={() => this.props.handleDisclaimer()}
                />
                <p style={{ fontWeight: "bold" }}>{name} {!customBranding && <i className="fa fa-copyright"></i>} {!customBranding && new Date().getFullYear()}</p>


              </Fragment>
            }
          </Card>
        </Col>
      </Row>
    </div>;
  }
}

LoginLayout.defaultProps = {
  languages: [],
  loginPlaceholder: "E-mail address"
}

export default withRouter(injectIntl(LoginLayout));
