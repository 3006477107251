import React, { Component } from "react";
import LoginLayout from "/bbui/layouts/loginLayout";
import Axios from "axios";
import { message } from 'antd';
import { withRouter } from "react-router-dom";
import UserProfile from '/modules/UserProfile';
import { FormattedMessage, injectIntl } from "react-intl";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = { lockForm: false };
  }
  handleLogin = (values) => {
    this.setState({ lockForm: true });
    const { history } = this.props;
    const bodyData = new FormData();
    Object.keys(values).map((key) => bodyData.append(key, values[key]));
    bodyData.append("act", "setSessionLogin");
    Axios({ method: "post", url: "/ajax/login.php", data: bodyData }).then((res) => {
      if (res.data.msg == "1") {
        this.props.checkIntervalFunc();
        UserProfile.set(res.data.user.name, res.data.user.email);
        history.push("/devices");
      } else {
        let response = res.data[0].msg_err;
        response = response.charAt(0).toUpperCase() + response.slice(1).toLowerCase();
        message.error(response);
        this.setState({ lockForm: false });
      }
    }).catch((err) => {
      message.error("Server error, please contact the system administrator");
      this.setState({ lockForm: false });
    });
  }
  handleRetrieve = (values) => {
    this.setState({ lockForm: true });
    const bodyData = new FormData();
    bodyData.append("login", values["login"]);
    bodyData.append("act", "resetPassword");
    Axios({ method: "post", url: "/ajax/login.php", data: bodyData }).then((res) => { 
      if(!!res.data.error && !!res.data.error[0].field) 
      {
        message.error("Invalid Email");
      } 
      else 
      {
        if(res.data == '1') {
          message.success("A link was sent to the e-mail account with further instructions to reset your password");
        } else if(res.data == '-3') {
          message.error("SMTP authentication failed");
        } else  if(res.data == '-2') {
          message.error(`User ${values["login"]} does not exist`);
        } else  if(res.data == '-1') {
          message.error("Sending failed");
        } else {
          message.error(res.data);
        }
      }
      this.setState({ lockForm: false });
    }).catch((err) => {
      message.error("Server error, please contact the system administrator");
      this.setState({ lockForm: false });
    });
  }
  render() {
    const logo = require("/public/branding/img/logo.svg");
    const { intl } = this.props;
    return <LoginLayout
      checkSaml={this.props.checkSaml}
      sso_enabled={this.props.sso_enabled}
      checkIntervalFunc={this.props.checkIntervalFunc}
      disclaimerObj={this.props.disclaimerObj}
      handleDisclaimer={this.props.handleDisclaimer}
      checkDisclaimer={this.props.checkDisclaimer}
      lockForm={this.state.lockForm}
      forgotPassword={true}
      handleLogin={this.handleLogin}
      handleRetrieve={this.handleRetrieve}
      handleChangeLang={this.props.handleChangeLang}
      loginPlaceholder={intl.formatMessage({ id: "common.login.form.email" })}
      emailRequired={intl.formatMessage({ id: "common.login.form.email.required" })}
      passwordRequired={intl.formatMessage({ id: "common.login.form.password.required" })}
      passwordPlaceholder={intl.formatMessage({ id: "common.login.form.password" })}
      language={this.props.language}
      image={logo}
      imageAlt="Blockbit GSM"
      imageTitle="Powered by Blockbit"
      languages={[
        { value: "en_US", text: "English", default: true },
        { value: "pt_BR", text: "Português Brasileiro" },
      ]} />
  }
}

export default withRouter(injectIntl(Login));