import React, { Component } from "react";
import { Alert, Button, Card, Form, Icon, Input, Select } from "antd";
import { product } from "/config/constants";

class LoginForm extends Component{
  handleLogin = (e) => {
    e.preventDefault();
    
    this.props.form.validateFields((err, values) => {
      //console.log("Ok");
      if (!err) {
        this.props.handleLogin(values);
      }
    });
  }

  handleChange = (lang)=>{
    if(lang){
      this.props.handleChangeLang(lang);
    }
  }
  render(){
    const { getFieldDecorator } = this.props.form;
    const { forgotPassword, languages, lockForm, loginPlaceholder, onToggle, passwordPlaceholder, emailRequired, passwordRequired, onSSO, sso_enabled } = this.props;
    let fieldLock = {};
    if(lockForm)
      fieldLock = { hasFeedback: true, disabled: true, validateStatus: "validating" };

    return <Form onSubmit={ this.handleLogin } style={{ textAlign: "left" }}>
      <Card className="login-card-content">
        <Form.Item { ...fieldLock }>
          {getFieldDecorator('login', {rules: [
            { required: true, message: emailRequired }
          ]})(
            <Input size="large" disabled={ lockForm } prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={ loginPlaceholder } />
          )}
        </Form.Item>
        <Form.Item { ...fieldLock }>
          {getFieldDecorator('password', {rules: [{ required: true, message: passwordRequired}] })(
            <Input size="large" disabled={ lockForm } prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={ passwordPlaceholder } type="password" />
          )}
        </Form.Item>
        { languages.length > 1 && <Form.Item { ...fieldLock }>
          {getFieldDecorator('lang', { initialValue: this.props.language.replace('-','_') })(
            <Select size="large" disabled={ lockForm } placeholder="Language" onChange={this.handleChange}>
              { languages.map((item) => {
                return <Select.Option key={item.value} value={item.value}>{item.text}</Select.Option>
              })}
            </Select>
          )}
        </Form.Item> }
        <Button disabled={ lockForm } size="large" block type="primary" htmlType="submit">Login</Button>
      </Card>
          { product == "GSM" && sso_enabled && <p><Button disabled={ lockForm } onClick={onSSO} size="large" ghost type="primary" block>Single-Sign-On</Button></p> }
          { forgotPassword && <p><Button disabled={ lockForm } onClick={onToggle} size="large" ghost type="primary" block>Forgot Password?</Button></p> }
    </Form>;
  }
}

export default Form.create({ name: 'loginForm' })(LoginForm);